import React from 'react';
import ResetButton from './FormSimple/ResetButton';

const MarketplaceCondition = ({ marketplaceCondition, conditionArray = [], setMarketplaceData, marketplaceName }) => {

    const flexPercent = (100 - (conditionArray.length * 2)) / conditionArray.length; //spacing for margins
    
    return (
        <>
            <label htmlFor={`${marketplaceName}ConditionTitle`} className="form-label mb-0" style={{ fontSize: '1.2em' }}>Condition</label>
            <ResetButton onReset={() => setMarketplaceData(prevData => {
                const updatedData = { ...prevData };
                delete updatedData.condition;
                return updatedData;
            })} />
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>
                {conditionArray.map((condition, index) => (
                    <button
                        key={`${marketplaceName}Condition-${index}`}
                        className={`btn btn-outline-primary me-2 mb-1 ${marketplaceCondition && (marketplaceCondition === condition.value) ? 'active' : ''}`}
                        style={{
                            flex: `1 1 ${flexPercent}%`,
                            height: '90px',
                            maxWidth: '100px'
                        }}
                        onClick={() => setMarketplaceData(prevData => ({
                            ...prevData,
                            condition: condition.value
                        }))}
                    >
                        {condition.name}
                    </button>
                ))}
            </div>
        </>
    );
};

export default MarketplaceCondition;