import React from "react";

const Pagination = ({handlePageChange, currentPage, nextPage, totalResults}) => {

    const totalPages = Math.ceil(totalResults / 10);
  
    // Generate an array of page numbers to display (e.g., [1, 2, 3, 4, 5])
    const getPageNumbers = () => {
      const pageNumbers = [];
      const maxPageNumbersToShow = 5; // Adjust as needed
  
      let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
      let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
  
      if (endPage - startPage < maxPageNumbersToShow - 1) {
        startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
      }
  
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
  
      return pageNumbers;
    };
  
  
  
    return (
      <nav className="mt-2">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(1)}>First</button>
          </li>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
          </li>
          {getPageNumbers().map((number) => (
            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(number)}>{number}</button>
            </li>
          ))}
          <li className={`page-item ${!nextPage ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(totalPages)}>Last</button>
          </li>
        </ul>
      </nav>
    )
}

export default Pagination;