import React from 'react';
import { useUserAuth } from '../../../context/UserAuthContext';
import Footer from '../../Helper/Footer';
import SettingsNav from './SettingsNav';
import SettingsProfileCard from './SettingsProfileCard';



const Settings = () => {

  const { user } = useUserAuth();
  const [firstName, lastName] = user.displayName ? user.displayName.split(' ') : ['',''];

  return (
    <>
    <main>
      <div className="container-xl px-4 mt-4">
        <SettingsNav />
        <div className="row">
          <div className="col-lg-4">
            <SettingsProfileCard />
          </div>
          <div className="col-lg-8">
            <div className="card mb-4">
              <div className="card-header">Account Details</div>
              <div className="card-body">
                <form>
                  <div className="row gx-3 mb-3">
                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputFirstName">First name</label>
                      <input className="form-control" id="inputFirstName" type="text" placeholder="Enter your first name" value={firstName} readOnly disabled />
                    </div>
                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputLastName">Last name</label>
                      <input className="form-control" id="inputLastName" type="text" placeholder="Enter your last name" value={lastName} readOnly disabled />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="small mb-1" htmlFor="inputEmailAddress">Email address</label>
                    <input className="form-control" id="inputEmailAddress" type="email" placeholder="Enter your email address" value={user.email} readOnly disabled />
                  </div>
                  <button className="btn btn-primary" type="button" disabled>Save changes</button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
    </>
  );
};

export default Settings;

