import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';
import AuthFooter from './AuthFooter';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { logIn } = useUserAuth();
  const navigate = useNavigate(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      await logIn(email, password);
      navigate('/dashboard');
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div id="layoutAuthentication" className="bg-primary">
        <div id="layoutAuthentication_content">
        <main>
            <div className="container-xl px-4">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header justify-content-center"><h3 className="fw-light my-4">Login</h3></div>
                    <div className="card-body">
                    {error && <div className="alert alert-danger" role="alert">
                        {error}
                    </div>}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                        <label className="small mb-1" htmlFor="inputEmailAddress">Email</label>
                        <input className="form-control" id="inputEmailAddress" type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter email address" />
                        </div>
                        <div className="mb-3">
                        <label className="small mb-1" htmlFor="inputPassword">Password</label>
                        <input className="form-control" id="inputPassword" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Enter password" />
                        </div>
                        <div className="mb-3">
                        <div className="form-check">
                            <input className="form-check-input" id="rememberPasswordCheck" type="checkbox" value="" />
                            <label className="form-check-label" htmlFor="rememberPasswordCheck">Remember password</label>
                        </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                          <Link className="small" to="/forgot-password">Forgot Password?</Link>
                          <button className="btn btn-primary" type="submit">
                            {isLoading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                "Login"
                            )}
                          </button>
                        </div>

                    </form>
                    </div>
                    <div className="card-footer text-center">
                      <div className="small">
                        <Link to="/signup">Need an account? Sign up!</Link>
                      </div>
                    </div>

                </div>
                </div>
            </div>
            </div>
        </main>
        </div>

      <AuthFooter />



    </div>

  );
  
}

export default Login;
