import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Trash, StarFill, GripHorizontal } from 'react-bootstrap-icons';


//TODO: implement this and use within imageSimple
// const handleUpload = async (file) => {
//     const blobURL = URL.createObjectURL(file);
//     // Update state to display image using blobURL
//     setImageData(prev => [...prev, { _id: generateUniqueId(), blobURL }]);

//     try {
//         const firebaseURL = await uploadImageToFirebase(file); // Your upload function
//         // Upload successful, update state with firebaseURL and revoke blobURL
//         setImageData(prev => prev.map(img => img.blobURL === blobURL ? { ...img, firebaseURL, blobURL: undefined } : img));
//         URL.revokeObjectURL(blobURL);
//     } catch (error) {
//         console.error("Upload failed", error);
//         // Handle upload failure (e.g., retry upload, display error message)
//     }
// };


const SortableImage = ({ id, url }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        isDragging,
        transform,
        transition,
    } = useSortable({ id: id });


    // Separate the drag listeners from the click handler
    const dragAttributes = { ...attributes, ...listeners };

    const containerStyle = {
        position: 'relative',
        width: '150px',
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid gray',
        boxSizing: 'border-box',
        margin: '5px',
        overflow: 'hidden',
        cursor: 'grab',
        borderRadius: '8px',
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const imageStyle = {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        backgroundColor: 'white',
        borderRadius: '8px',
    };

    return (
        <>
            <div 
                ref={setNodeRef} 
                style={containerStyle} 
                {...dragAttributes} 
            >
                <img 
                    src={url} 
                    alt={`Image ${id}`} 
                    style={imageStyle} 
                />
                
            </div>
        </>
    );
};




const ModalChangeOrder = ({ imageData, setImageData, modalId = "imageOrderModal" }) => {

    useEffect(() => {
        const needsUpdate = imageData.some(item => !item._id);

        if (imageData.length > 0 && needsUpdate) {
            const updatedImageData = imageData.map(item => {
                if (!item._id) {
                    const tempId = 'temp' + Math.floor(Math.random() * 1000000);
                    return { ...item, _id: tempId };
                }
                return item;
            });

            setImageData(updatedImageData);
        }

    }, [imageData]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = imageData.findIndex((img) => img._id === active.id);
            const newIndex = imageData.findIndex((img) => img._id === over.id);
            const newData = arrayMove(imageData, oldIndex, newIndex);
            setImageData(newData);
        }
    };


    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={`${modalId}Label`} aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        {/* Modal Content */}
                        <div className="modal-body d-flex flex-row flex-wrap">
                            <SortableContext items={imageData.map((img) => img._id)}>
                                {imageData.map((image) => (
                                    <SortableImage key={image._id} id={image._id} url={image.imageUrl || image.firebaseURL || image.blobURL} />
                                ))}
                            </SortableContext>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </DndContext>
    );
};

export default React.memo(ModalChangeOrder);
