import axios from 'axios';

// Base URL for the product-related endpoints
const baseURL = `${process.env.REACT_APP_ENDPOINT}`;

// Function to get all products

export const searchTemplates = (token, searchTerm, size) => {
  const requestBody = { search: searchTerm };
  if (size) {
    requestBody.size = size;
  }

  return axios.post(`${baseURL}/templates/search`, 
    requestBody,  
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  .then(response => response.data);
};

export const aiAnalysis = (token, urls) => {
  return axios.post(`${baseURL}/templates/ai`, 
    { urls: urls },  
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  .then(response => response.data);
};

export const getCategories = (token) => {
  return axios.get(`${baseURL}/categories/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  .then(response => response.data);
};

export const getMarketplaceCategories = (token, marketplaceName) => {
  return axios.get(`${baseURL}/categories/${marketplaceName.toLowerCase()}/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  .then(response => response.data);
};

export const getCategoryData = (categoryId, token) => {
  return axios.get(`${baseURL}/categories/specifics/${categoryId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
}

export const getCategoryConditionData = (categoryId, token) => {
  return axios.get(`${baseURL}/categories/condition/${categoryId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
}

export const getBrandList = (token) => {
  return axios.get(`${baseURL}/brandlist`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
}

export const getPoshmarkBrands = (token) => {
  return axios.get(`${baseURL}/brandlist/poshmark`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
}

export const getMercariBrands = (token) => {
  return axios.get(`${baseURL}/brandlist/mercari`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
}

