import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../../../context/UserAuthContext';
import Footer from '../../Helper/Footer';
import SettingsNav from './SettingsNav';
import { cloudAPI } from '../../../api';
import SettingsCollapsableCard from './SettingsCollapsableCard';

const ConnectMercariModal = ({ show, onClose }) => {
    const { user } = useUserAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [authFormLoading, setAuthFormLoading] = useState(false);
    const [authResponse, setAuthResponse] = useState({});
    const [codeLoading, setCodeLoading] = useState(false);
    const [codeResponse, setCodeResponse] = useState({})
    const [validationMessage, setValidationMessage] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [inputCode, setInputCode] = useState('');
    const [alertUsername, setAlertUsername] = useState({ message: '', type: ''});
    const [alertCode, setAlertCode] = useState({ message: '', type: ''});

    const handleConnect = async (event) => {
        event.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(username)) {
            setAlertUsername({ message: 'Please enter a valid email address.', type: 'warning' });
            return;
        }
        if (password.length < 6) {
            setAlertUsername({ message: 'Password is invalid. Password must be at least 6 characters long.', type: 'warning' });
            return;
        }

        setAlertUsername({ message: '', type: ''});

        setValidationMessage('');

      console.log('handle connect')
      try {
          setAuthFormLoading(true);
          const token = await user.getIdToken(true);
          const cloudResponse = await cloudAPI.createMarketplaceAuth(token, username, password, 'mercari');
          console.log('authResponse - ', cloudResponse);
          setAuthResponse(cloudResponse);
          handleNextStep();
      } catch (error) {
          console.error('An error occured creating marketplace auth object:', error);
      } finally {
        setAuthFormLoading(false);
      }
    };

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleSubmitCode = async () => {
        try {
            setCodeLoading(true);
            const token = await user.getIdToken(true);
            const cloudResponse = await cloudAPI.updateMarketplaceAuth(token, 'mercari', authResponse.authId, { inputCode: inputCode });
            console.log('Updated Marketplace Auth - ', cloudResponse);
            setCodeResponse(cloudResponse);
            setAlertCode({ message: 'Login information submitted. Refresh page in 1 minute to check connection.', type: 'success'});
        } catch (error) {
            console.error('An error occurred while updating Marketplace Auth:', error);
        } finally {
            setCodeLoading(false);
        }
    };

    return (
        <>
            {show && <div className="modal-backdrop show"></div>}
            <div className={`modal ${show ? 'show d-block' : ''}`} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Connect to Mercari</h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body">
                            {currentStep === 1 && (
                                <div>
                                    <p>
                                        We connect to Mercari on your behalf to complete the following tasks:
                                    </p>
                                    <ul>
                                      <li>Import Listings for Cross-Listing</li>
                                      <li>Create New Listings</li>
                                      <li>Re-List Listings</li>
                                      <li>Send Offers</li>
                                      <li>Check for Sold Listings</li>
                                      <li>More actions as we grow our features</li>
                                    </ul>
                                    <p>By connecting, you agree to allow us to complete these actions. All actions are started by you and done on your behalf.</p>
                                    
                                    <button type="button" className="btn btn-primary" onClick={handleNextStep}>
                                        Accept
                                    </button>
                                </div>
                            )}
                            {currentStep === 2 && (
                                <div>
                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label">Email Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            placeholder="Enter your Mercari email"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Enter your Mercari password"
                                            autoComplete="new-password"
                                        />
                                    </div>
                                    <p>We secure your passwords with high level encryption. Our team cannot read your password.</p>
                                    {alertUsername.message && (
                                        <div className={`alert alert-${alertUsername.type}`} role="alert">
                                            {alertUsername.message}
                                        </div>
                                    )}
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleConnect}
                                        disabled={authFormLoading || (authResponse && Object.keys(authResponse).length > 0)}
                                    >
                                        {authFormLoading ? 'Loading...' : (authResponse && Object.keys(authResponse).length > 0 ? 'Submitted' : 'Connect')}
                                    </button>
                                </div>
                            )}
                            {currentStep === 3 && (
                                <div>
                                    <p>Mercari will send you a code. Notifying you of a new login. This is coming from us. Provide the code here to connect your acccount.</p>
                                    <div className="mb-3">
                                        <label htmlFor="inputCode" className="form-label">Input Code</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputCode"
                                            value={inputCode}
                                            onChange={(e) => setInputCode(e.target.value)}
                                            placeholder="Enter Code sent by Mercari to your Email"
                                            autoComplete="off"
                                        />
                                    </div>
                                    {alertCode.message && (
                                        <div className={`alert alert-${alertCode.type}`} role="alert">
                                            {alertCode.message}
                                        </div>
                                    )}
                                    <button 
                                        type="button" 
                                        className="btn btn-primary"
                                        onClick={handleSubmitCode}
                                        disabled={codeLoading || (codeResponse && Object.keys(codeResponse).length > 0)}
                                    >
                                        Submit Code
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const SettingsMercari = () => {
  const { user } = useUserAuth();
  const [authData, setAuthData] = useState(null);
  const [authDataLoading, setAuthDataLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logData, setLogData] = useState([]);

  const exampleProductData = {
    title: 'Test Title - Hawaiian Shirt',
    description: 'Test item. Do not buy.',
    pictures: [
        { imageUrl: 'https://i.ebayimg.com/images/g/GZgAAOSwkntm5f8q/s-l1600.jpg'}, 
        { imageUrl: 'https://i.ebayimg.com/images/g/4nUAAOSw1Xxm5f8p/s-l1600.jpg'} 
    ],
    originalPrice: 199,
    price: 99,
    sizeChart: 'Standard',
    size: 'S',
    category: {
        department: 'Men',
        category: 'Shirts'
    }
  }

  const handleCloudAction = async (actionName) => {
    try {
      const token = await user.getIdToken(true);
      let dataToUse = {};
      if(actionName === 'poshmarkListProduct'){
        dataToUse = exampleProductData;
      }
      const cloudResponse = await cloudAPI.createCloudAction(token, actionName, dataToUse);
      console.log(`${actionName} Cloud Action Response:`, cloudResponse);
      setLogData(prevLogData => [...prevLogData, `Cloud Action Response: ${JSON.stringify(cloudResponse)}`]);
    } catch (error) {
      console.error(`An error occurred on handle ${actionName} CloudAction:`, error);
      setLogData(prevLogData => [...prevLogData, `Cloud Action Error: ${error}`]);
    }
  };

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        setAuthDataLoading(true);
        const token = await user.getIdToken(true);
        const authResponse = await cloudAPI.readMarketplaceAuth(token, 'mercari');
        setLogData(prevLogData => [...prevLogData, `Auth Response: ${JSON.stringify(authResponse)}`]);
        setAuthData(authResponse || {}); // Ensure authData is always an object
        setConnected(authResponse?.connected || false);
      } catch (error) {
        console.error('An error occurred while getting auth data:', error);
        setLogData(prevLogData => [...prevLogData, `Auth Error: ${error}`]);
        setAuthData({}); // Set to empty object on error
      } finally {
        setAuthDataLoading(false);
      }
    };

    if (user) {
      setLogData(prevLogData => [...prevLogData, 'User Found']);
      fetchAuthData();
    }
  }, [user]);


  const updateSettings = () => {
    console.log("Update settings function called");
  };

  return (
    <>
    <main>
      <div className="container-xl px-4 mt-4">
        <SettingsNav />

          <div className="row">
            <div className="col-lg-4">
                <div className="card mb-4">
                <div className="card-header">Mercari Connection</div>
                <div className="card-body">
                    {authDataLoading ? (
                        <div>Checking Connection Status...</div>
                    ) : (
                        connected ? (
                            <>
                                <p>Connected!</p>
                            </>
                        ) : (
                            <>
                                <button className="btn btn-secondary" onClick={() => setIsModalOpen(true)}>
                                    Connect to Mercari
                                </button>
                            </>
                        )
                    )}                    
                    
                </div>
                </div>
                <div className="card mb-2">
                    <div className="card-header">Cloud Action Testing</div>
                    <div className="card-body">
                        <button
                            type="button"
                            className="btn btn-primary mt-3"
                            onClick={() => handleCloudAction('testMediumAction')}
                        >
                            CloudAction Medium
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary mt-3"
                            style={{ marginLeft: '3px' }}
                            onClick={() => handleCloudAction('testMicroAction')}
                        >
                            CloudAction Micro
                        </button>
                    </div>
                </div>

            </div>
            <div className="col-lg-8">
                <SettingsCollapsableCard
                    cardTitle="Mercari Automation Settings"
                    idFragment="MercariAutomationSettings"
                    theme="mercari"
                    updateSettings={updateSettings}
                    settingsDataSaved={false}
                    submitLoading={false}
                >
                    <div className="container">
                        <div 
                            style={{
                                maxHeight: '20vh',
                                overflowY: 'auto',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '10px',
                                marginBottom: '15px'
                            }}
                        >
                            {logData.map((log, index) => (
                                <div 
                                    key={index} 
                                    style={{
                                        borderBottom: index < logData.length - 1 ? '1px solid #eee' : 'none',
                                        padding: '5px 0',
                                        whiteSpace: 'normal', // Allow text to wrap
                                        overflow: 'visible',  // Ensure overflow is visible
                                        textOverflow: 'clip'  // Remove ellipsis
                                    }}
                                >
                                    {log}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="container">
                        <div className="row mb-3 align-items-center">
                            <div className="col-md-12">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => handleCloudAction('mercariListProduct')}
                                    disabled={authDataLoading || !connected}
                                >
                                    List Example Product
                                </button>
                            </div>
                        </div>
                    </div>
                </SettingsCollapsableCard>

            </div>
          </div>
            <ConnectMercariModal 
                show={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
            />

      </div>
    </main>
    <Footer />
    </>
  );
};

export default SettingsMercari;

