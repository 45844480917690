import React, { useState } from 'react';

const ProfileCard = () => {
    const [selectedProfile, setSelectedProfile] = useState("/assets/img/profile-1.png");

    const handleProfileClick = (profile) => {
        setSelectedProfile(profile);
    };

    return (
        <div className="card mb-4 mb-xl-0">
            <div className="card-header">Profile Picture</div>
            <div className="card-body text-center">
                {/* Selected Profile picture image */}
                <img 
                    className="img-account-profile rounded-circle mb-2" 
                    src={selectedProfile} 
                    alt="Selected Profile" 
                    style={{ maxWidth: '100%', height: 'auto' }} // Ensures the image doesn't exceed the container width
                />
                
                {/* Profile picture help block */}
                <div className="small font-italic text-muted mb-4">Select a photo to use as your avatar.</div>
                
                {/* Profile Pictures */}
                <div className="d-flex justify-content-center flex-wrap">
                    {Array.from({ length: 5 }, (_, i) => `/assets/img/profile-${i + 1}.png`).map((profile, index) => (
                        <img 
                            key={index} 
                            src={profile} 
                            alt={`Profile ${index + 1}`} 
                            className={`img-profile-selectable rounded-circle m-1 ${selectedProfile === profile ? 'img-profile-selected' : ''}`} 
                            onClick={() => handleProfileClick(profile)}
                            style={{ width: '50px', height: '50px', cursor: 'pointer' }} 
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProfileCard;
