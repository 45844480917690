import React, { useRef } from 'react';
import CollapsableCard from '../Helper/CollapsableCard';


const MarketplaceCategory = ({ marketplaceDataCategory, setMarketplaceData, marketplaceName, marketplaceCategories }) => {
    const categoryRef = useRef(null);
    const subCategoryRef = useRef(null);

    //TODO: ability to search among all

    const handleCategoryClick = (type, value) => {
        console.log(`Selected ${type}:`, value);

        setMarketplaceData(prevData => {
            const categoryUpdates = {
                ...prevData.category, // Spread the existing category data
                [type]: value // Update the specific type with the new value
            };

            if (type === 'department') { // If the department changes, we need to reset both category and subCategory
                categoryUpdates.category = null;
                categoryUpdates.subCategory = null;
            } else if (type === 'category') { // If the category changes, reset subCategory
                categoryUpdates.subCategory = null;
            }

            return { ...prevData, category: categoryUpdates };
        });


        if(type === 'department' && categoryRef.current){
            categoryRef.current?.focus();
        }else if(type === 'category' && subCategoryRef.current){
            subCategoryRef.current?.focus();
        }

    };
    

    return (
        <CollapsableCard cardTitle={`${marketplaceName} Category`} idFragment={`${marketplaceName}Category`} >
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="departmentSelect">Department</label>
                        <select className="form-control" id="departmentSelect" value={marketplaceDataCategory?.department || ''}
                                onChange={(e) => handleCategoryClick('department', e.target.value)}>
                            <option value="">Select Department</option>
                            {Object.keys(marketplaceCategories).length > 0 ? (
                                Object.keys(marketplaceCategories).map(department => (
                                    <option key={department} value={department}>{department}</option>
                                ))
                            ) : (
                                <option value="">Categories Loading...</option>
                            )}

                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="categorySelect">Category</label>
                        <select 
                            ref={categoryRef}
                            className="form-control" 
                            id="categorySelect" 
                            value={marketplaceDataCategory?.category || ''}
                            onChange={(e) => handleCategoryClick('category', e.target.value)} 
                            disabled={!marketplaceDataCategory?.department}
                        >
                            {!marketplaceDataCategory?.department ? (
                                <option value="">Select Department First</option>
                            ) : (
                                <>
                                    <option value="">Select a Sub-Category</option>
                                    {
                                        (() => {
                                            const categories = marketplaceCategories[marketplaceDataCategory?.department];
                                            if (categories && typeof categories === 'object') {
                                                return Object.keys(categories).map(category => (
                                                    <option key={category} value={category}>{category}</option>
                                                ));
                                            } else {
                                                // This will handle cases where the department is set but no categories are found
                                                return <option value="">Categories Loading...</option>;
                                            }
                                        })()
                                    }
                                </>
                            )}
                        </select>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="subCategorySelect">Sub-Category</label>
                        <select 
                            ref={subCategoryRef}
                            className="form-control" 
                            id="subCategorySelect" 
                            value={marketplaceDataCategory?.subCategory || ''}
                            onChange={(e) => handleCategoryClick('subCategory', e.target.value)} 
                            disabled={!marketplaceDataCategory?.category}
                        >
                            {!marketplaceDataCategory?.category ? (
                                <option value="">Select Category First</option>
                            ) : (
                                <>
                                    <option value="">Select a Sub-Category</option>
                                    {
                                        (() => {
                                            const department = marketplaceDataCategory?.department;
                                            const category = marketplaceDataCategory?.category;
                                            const subCategories = department && category && marketplaceCategories[department]?.[category];
                                            if (typeof subCategories === 'object' && subCategories.options && Array.isArray(subCategories.options)) {
                                                //POSHMARK formatting for categories
                                                return subCategories.options.map(option => (
                                                    <option key={option} value={option}>{option}</option>
                                                ));
                                            }else if (subCategories && Array.isArray(subCategories)) {
                                                //MERCARI formatting for categories
                                                return subCategories.map(subCategory => (
                                                    <option key={subCategory.name} value={subCategory.name}>{subCategory.name}</option>
                                                ));
                                            } else {
                                                return <option value="">No sub-categories available</option>;
                                            }
                                        })()
                                    }
                                </>
                            )}
                        </select>
                    </div>
                </div>

            </div>
        </CollapsableCard>
    );
    
};

export default MarketplaceCategory;
