import React, { useEffect } from "react";
import ItemSpecific from './ItemSpecific';

const ItemSpecificOutside = React.memo(({ specifics, formDataItemSpecifics, formDataSuggestSpecifics, setFormData, suggestSpecifics }) => {
    const itemSpecifics = formDataItemSpecifics || {}; 
    //TODO: ensure Outer Material for Jacket is handled Correct
    //Ability to Show/Add Extra Item Specifics 

    
    useEffect(() => {
        if(formDataItemSpecifics && specifics){
            const outerShellMaterialObject = specifics.find(item => item.name === 'Outer Shell Material');
            const materialEntry = formDataItemSpecifics.hasOwnProperty('Material') 
                ? { Material: formDataItemSpecifics['Material'] } 
                : undefined;

            if(outerShellMaterialObject && outerShellMaterialObject.rules?.isSingle && materialEntry){
                setFormData(prevData => {
                    // Update the item specifics by adding 'Outer Shell Material' with the first element from 'Material'
                    const newItemSpecifics = {
                      ...prevData.itemSpecifics,
                      'Outer Shell Material': [formDataItemSpecifics['Material'][0]]
                    };
                    delete newItemSpecifics['Material'];
                
                    return {
                      ...prevData,
                      itemSpecifics: newItemSpecifics
                    };
                  });
            }
        }
        
    }, []);
    
    return (
        <>
            <div>
                {specifics
                    .sort((a, b) => {
                        // Sort specifics to have required ones first
                        if (a.rules?.required && !b.rules?.required) return -1;
                        if (!a.rules?.required && b.rules?.required) return 1;
                        return 0;
                    })
                    .map((specific, index) => {
                        // Combine suggestSpecifics with formDataSuggestSpecifics
                        const combinedSuggestSpecifics = [
                            ...(suggestSpecifics?.[specific.name] || []),
                            ...(formDataSuggestSpecifics?.[specific.name] || [])
                        ];

                        return (
                            <ItemSpecific 
                                key={specific.name} 
                                type={specific.rules?.required ? "required" : "ccommon"} 
                                specific={specific} 
                                initialValue={itemSpecifics[specific.name] || null} 
                                setFormData={setFormData}
                                suggestSpecifics={combinedSuggestSpecifics}
                            />
                        );
                    })
                }
            </div>
        </>
    )
});

function ItemSpecificContainer({ 
    categoryData, 
    formDataItemSpecifics, 
    formDataSuggestSpecifics, 
    setFormData, 
    templates = []
}) {

    return (
        <div className="card mb-2" id="item-specifics">
            <div className="card-header">Item Specifics</div>
            <div className="card-body">
                <p className="card-text">
                    {!categoryData?.categoryId
                        ? "Please select a category to see all Item Specifics."
                        : categoryData?.categorySpecifics?.length 
                            ? <span>Details for category {categoryData?.categoryId}. <strong>Bold</strong> specifics are required.</span>
                            : 'Loading...'
                    }
                </p>

                <ItemSpecificOutside 
                    specifics={categoryData?.categorySpecifics || []} 
                    formDataItemSpecifics={formDataItemSpecifics} 
                    formDataSuggestSpecifics={formDataSuggestSpecifics}
                    setFormData={setFormData}
                    suggestSpecifics={templates?.suggestItemSpecifics || []}
                />
            </div>
        </div>
    );
}

export default React.memo(ItemSpecificContainer);

