import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';
import { userAPI, listingAPI } from '../../api';
import { CartCheck, Tags } from 'react-bootstrap-icons';

const ImportCard = ({ title, count, type, color = 'primary', icon, setImportMessage, setImportNext }) => {
    const { user } = useUserAuth();
    const [importCardLoading, setImportCardLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(count);
    const [nextPage, setNextPage] = useState(1);

    const addImportMessage = (importData) => {
        const remaining = (totalItems - importData.itemsReturnedSoFar);
        const newMessage = `${importData.itemsImported} items Imported! ${importData.upsertedCount} new items, ${importData.modifiedCount} items modified, ${importData.notModifiedOrUpserted} were already imported. ${remaining} remaining.`;
        console.log('remaining - ', remaining);

        setImportMessage(prevState => ({
            ...prevState,
            showMessage: true,
            messages: [...prevState.messages, newMessage]
        }));
    }

    const handleImportClick = async (type) => {
        console.log(`Handle click for: ${type}`);
        setImportCardLoading(true);
        try {
            console.log('nextPage - ', nextPage);
            const token = await user.getIdToken(true); 
            const importData = await listingAPI.getImport(token, { listType: type, page: nextPage });
            console.log('importData - ', importData);
            setImportCardLoading(false);
            setNextPage(importData.nextPage);
            addImportMessage(importData);
            setImportNext(true);
        } catch (error) {
            setImportCardLoading(false);
            setImportMessage(prevState => ({
                ...prevState,
                showMessage: true,
                messages: [...prevState.messages, `Error on Importing: ${error.message || error}`]
            }));
        }
    }

    return (
        <div className={`card bg-${color} text-white h-100`}>
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="me-3">
                        <div className="text-white-75 small">{title}</div>
                        <div className="text-lg fw-bold">{count}</div>
                    </div>
                    {icon}
                </div>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between small">
                {String(count) === '0' ? "Nothing to Import" : 
                    !nextPage ? "All imported" :
                    importCardLoading ? "Loading..." : (
                        <a 
                            className={`text-white stretched-link ${importCardLoading ? "disabled-link" : ""}`} 
                            href="#!" 
                            onClick={e => { 
                                if (importCardLoading) e.preventDefault(); 
                                else handleImportClick(type); 
                            }}
                        >Start Import</a>
                    )
                }
            </div>
        </div>
    );
}

const OnboardImport = ({ setMessage, setAlertType, activeStep, display }) => {
    const { user } = useUserAuth();
    const [importLoading, setImportLoading] = useState(false);
    const [importTotals, setImportTotals] = useState([]);
    const [importMessage, setImportMessage] = useState({ showMessage: false, messages:[]})
    const [importNext, setImportNext] = useState(false);
    const navigate = useNavigate();

    //listingAPI.getImport(token, {listType: 'Active})

    useEffect(() => {
        const fetchImport = async () => {
            try {
                setImportLoading(true);
                const token = await user.getIdToken(true);
                const importData = await listingAPI.getImportTotal(token);
                console.log('importData - ', importData);
                setImportTotals(importData.result);
                setImportLoading(false);

            } catch (error) {
                setImportLoading(false);
                console.error("Error Importing", error);
                setMessage(`Error Importing: ${error.message || error}`);
                setAlertType('danger');    
            }
        }
        if (activeStep === 3 || display === 'Settings') {
            fetchImport();
        }
    }, [activeStep]);

    const handleSkipClick = async () => {
        try {
            const token = await user.getIdToken(true); 
            const userData = await userAPI.updateUser(token, { storeSetup: true });    
            navigate('/dashboard');
        } catch (error) {
            setMessage(`Error on Skip: ${error.message || error}`);
            setAlertType('danger');
        }
    }

    return(
        <>
        {display === 'Onboard' && (
            <>
                <h3 className="text-primary">Step 3</h3>
                <h5 className="card-title mb-4">Importing Products</h5>
            </>
        )}
        

        <div className="info-section">
            <p>
                We can import all Active, Sold (last 60 days). Auto-Import is coming soon. 
                {display === 'Onboard' && (
                     "Start import here, or Skip to go to the dashboard."
                )}
            </p>
        </div>

        {!importLoading ? (
            <>
                <h5 className="card-title mb-4">Available to Import</h5>

                {importMessage.showMessage && (
                    <div className="info-section mt-3">
                        <div className="row">
                            <div class="col-sm-12">
                                <div className="alert alert-success" role="alert">
                                <h4 className="alert-heading">Imported!</h4>
                                
                                {importMessage.messages.map((message, index) => (
                                    <div key={index}>
                                    {index !== 0 && <hr />}
                                    <p>{message}</p>
                                    </div>
                                ))}

                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div class="row">
                    <div className="col">
                        <ImportCard 
                            title="Active Listings" 
                            count={importTotals.active} 
                            color={"primary"}
                            type="Active"
                            icon={<Tags size={32} className="mb-2"/>}
                            setImportMessage={setImportMessage}
                            setImportNext={setImportNext}
                        />
                    </div>
                    <div className="col">
                        <ImportCard 
                            title="Sold Listings (60 Days)" 
                            count={importTotals.sold} 
                            color={"success"}
                            type="Sold"
                            icon={<CartCheck size={32} className="mb-2"/>}
                            setImportMessage={setImportMessage}
                            setImportNext={setImportNext}
                        />
                    </div>
                </div>

            </>
        ) : (
            <h5 className="card-title mb-4">Loading Listings from Your Account...</h5>
        )}


        {display === 'Onboard' && (
            <>
                <hr className="my-4" />
                <div className="d-flex justify-content-end">
                    <button 
                        className="btn btn-secondary" 
                        type="button"
                        onClick={handleSkipClick}
                    >
                    {importNext ?("Skip"): ("Next")}
                    </button>
                </div>
            </>
        )}

        </>
    )
}

export default OnboardImport;
