import React, { useState, useEffect } from 'react';
import ResetButton from './FormSimple/ResetButton';
import SingleSuggestButton from '../Helper/SingleSuggestButton';

//TODO: Poshmark does not allow a custom size if it is a value in 1 of the size charts.
//Ex: Women's Dresses will not allow custom "11" since Junior Size Chart has option 11
//To validate, ensure custom values are not in 1 of the size charts, and update initial conversion to always check all size charts

const PoshmarkSize = ({ poshmarkSize, poshmarkSizeChart, categorySizeCharts, setMarketplaceData }) => {
    const [oneOption, setOneOption] = useState(false);
    const [customSize, setCustomSize] = useState('');
    const [sizeChartTab, setSizeChartTab] = useState(null);

    const handleCustomSizeSelect = () => {
        if (customSize.trim() !== '') {
            setMarketplaceData(prevData => ({
                ...prevData,
                size: customSize,
                sizeChart: 'Custom'
            }));
        }
    };

    useEffect(() => {
        //Conversion of Size using Category Size Chart
        if (categorySizeCharts.length > 0 && poshmarkSize) {
            console.log("Category Size Charts:", categorySizeCharts);

            let exactMatch = false;
            let returnValue = null;
            let returnSizeChart = null;

            const checkAndUpdateSize = (chart) => {
                
                let exactMatchValue = chart.values.includes(poshmarkSize);
                if (exactMatchValue) {
                    exactMatch = true;
                    returnValue = poshmarkSize;
                    returnSizeChart = chart.name;
                } else {
                    // Check for numeric matches
                    chart.values.forEach(value => {
                        const numericValue = value.replace(/[^0-9.]/g, '');
                        if (numericValue && parseFloat(numericValue) === parseFloat(poshmarkSize)) {
                            returnValue = value;
                            returnSizeChart = chart.name;
                        }
                    });
                }
            };

            // Check if a specific size chart is targeted
            if (poshmarkSizeChart) {
                const relevantSizeChart = categorySizeCharts.find(chart => chart.name === poshmarkSizeChart);
                if (relevantSizeChart) {
                    checkAndUpdateSize(relevantSizeChart);
                }
            }

            if(!poshmarkSizeChart || !returnValue){
                console.log('check all size charts')
                for (let chart of categorySizeCharts) {
                    checkAndUpdateSize(chart);
                    if (exactMatch) break; // Stop searching once a match is found
                }
            }

            if(returnValue){
                setMarketplaceData(prevData => ({
                    ...prevData,
                    size: returnValue,
                    sizeChart: returnSizeChart
                }));
                setSizeChartTab(returnSizeChart);
            }else{
                setCustomSize(poshmarkSize)
                setMarketplaceData(prevData => ({
                    ...prevData,
                    sizeChart: 'Custom'
                }));
            }

        }

    }, [categorySizeCharts, poshmarkSize, poshmarkSizeChart])



    useEffect(() => {

        if(categorySizeCharts && categorySizeCharts.length > 1 && categorySizeCharts[0].name){
            if(poshmarkSizeChart){
                setSizeChartTab(poshmarkSizeChart);
            }else{
                setSizeChartTab(categorySizeCharts[0].name);
            }
        }

        if(categorySizeCharts && categorySizeCharts.length === 1 && categorySizeCharts[0].name === 'Standard' && categorySizeCharts[0].values[0] === 'One Size'){
            setOneOption(true);
            setMarketplaceData(prevData => ({
                ...prevData,
                sizeChart: 'Standard',
                size: 'One Size'
            }));
        }else{
            setOneOption(false); //condition of category changing
        }

    }, [categorySizeCharts])

    return (
        <>
            <div className="mb-2">
                <label htmlFor={"MercariSizeLabel"} className="form-label mb-0" style={{ fontSize: '1.2em' }}>Size</label>
                <ResetButton onReset={() => setMarketplaceData(prevData => {
                    const updatedData = { ...prevData };
                    delete updatedData.size;
                    delete updatedData.sizeChart;
                    return updatedData;
                })} />
            </div>

            {
                poshmarkSizeChart && poshmarkSizeChart !== '' && 
                <>
                    <span style={{ marginRight: '3px' }}>Size Chart:</span>
                    <SingleSuggestButton 
                        value={poshmarkSizeChart}
                        selected="true"
                        specific="size"
                        setSelectedSpecifics={() => {
                            setMarketplaceData(prevData => ({
                                ...prevData,
                                sizeChart: '',
                                size: ''
                            }));
                            setCustomSize('');
                        }}
                    />
                </>
            }
            {
                poshmarkSize && poshmarkSize !== '' && 
                <>
                    <span style={{ marginRight: '3px' }}>Size:</span>
                    <SingleSuggestButton 
                        value={poshmarkSize}
                        selected="true"
                        specific="size"
                        setSelectedSpecifics={() => {
                            setMarketplaceData(prevData => ({
                                ...prevData,
                                size: ''
                            }));
                            if(poshmarkSizeChart){
                                setSizeChartTab(poshmarkSizeChart)
                            }
                            setCustomSize('');
                        }}
                    />
                </>
                
            }
            
            <div className="row">
                <div className="col">
                    <div className="form-group">

                    {(!categorySizeCharts || !(categorySizeCharts.length > 0)) ? (
                            <p>Select Category to see options.</p>
                        ) : (

                            <>
                            <div className="card">
                            <div className="card-body" style={{padding: '15px'}}>
                                <ul className="nav nav-pills card-header-pills" id="cardPill" role="tablist" style={{marginBottom: '10px'}}>
                                    {categorySizeCharts.map((chart, index) => (
                                    <li className="nav-item" key={chart.name}>
                                        <a 
                                            className={`nav-link ${sizeChartTab === chart.name ? 'active' : ''}`}
                                            style={{padding: '5px'}}
                                            id={`${chart.name}-pill`}
                                            href={`#${chart.name}Pill`}
                                            data-bs-toggle="tab"
                                            role="tab"
                                            aria-controls={chart.name}
                                            aria-selected={sizeChartTab === chart.name ? 'true' : 'false'}
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent the browser from following the href
                                                setSizeChartTab(chart.name);
                                            }}
                                        >
                                        {chart.name}
                                        </a>
                                    </li>
                                    ))}
                                </ul>

                                <div className="tab-content">
                                    {categorySizeCharts.map((chart) => (
                                    <div
                                        id={`${chart.name}Pill`}
                                        className={`tab-pane ${sizeChartTab === chart.name ? 'active' : ''}`}
                                        role="tabpanel"
                                        aria-labelledby={`${chart.name}-pill`}
                                        key={chart.name}
                                    >
                                        {chart.values && chart.values.length > 0 ? (
                                            chart.values.map(value => (
                                                <button
                                                key={value}
                                                type="button"
                                                className={`btn ${value === poshmarkSize && chart.name === poshmarkSizeChart ? 'btn-primary' : 'btn-outline-primary'}`}
                                                onClick={() => {
                                                    setCustomSize('');
                                                    setMarketplaceData(prevData => ({
                                                        ...prevData,
                                                        size: value,
                                                        sizeChart: chart.name
                                                    }))
                                                }}>
                                                {value}
                                                </button>
                                            ))
                                            ) : (
                                            <p>No size options available.</p>
                                        )}

                                    </div>
                                    ))}
                                </div>

                                {/* Custom Entry Field */}

                                <div className="input-group" style={{marginTop: '15px'}}>
                                    <input 
                                        style={{maxWidth: '200px'}}
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Custom Size"
                                        value={customSize}
                                        onChange={(e) => setCustomSize(e.target.value)}
                                    />
                                    <button 
                                        className="btn btn-outline-primary" 
                                        type="button"
                                        onClick={handleCustomSizeSelect}
                                    >
                                        &gt; 
                                    </button>
                                </div>


                            </div>
                            </div>
                            </>

                    )}

                    

                    </div>
                </div>
            </div>

        </>
    );
};

export default PoshmarkSize;