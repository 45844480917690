import React from 'react';

function ListingDetails({ storeData, formData, setFormData }) {

    const handleInputChange = (e, inputType = null) => {
        const { name, id, type, value, options } = e.target;
    
        const key = name || id;
        let inputValue;
    
        if (type === 'checkbox') {
            inputValue = e.target.checked;
        } else if (type === 'select-one') {
            const selectedOption = options[options.selectedIndex];
            const selectedName = selectedOption.text;
            inputValue = value ? { name: selectedName, id: value } : null;
        } else {
            inputValue = value;
        }

        if(inputType === 'sellerProfiles'){
            setFormData(prevData => ({
                ...prevData,
                sellerProfiles: {
                    ...prevData.sellerProfiles,
                    [key]: inputValue
                }
            }));
        }else{
            setFormData(prevData => ({
                ...prevData,
                [key]: inputValue
            }));
        }

    };
    

    return (
        <div className="card mb-2" id="listing-details">
            <div className="card-header">Listing Details</div>
            <div className="card-body">
                <p>We auto-filled in your Store Defaults.</p>
                <form>
                    <div className="row mb-3">
                        <label htmlFor="sku" className="col-sm-2 col-form-label">SKU</label>
                        <div className="col-sm-4">
                            <input key="uniqueSkuKey" type="text" className="form-control" id="sku" value={formData.sku ? formData.sku : ''} onChange={handleInputChange} />
                        </div>
                        <label htmlFor="locationPostalCode" className="col-sm-2 col-form-label">Item Location (ZIP Code)</label>
                        <div className="col-sm-4">
                            <input type="text" className="form-control" id="locationPostalCode" value={formData.locationPostalCode ? formData.locationPostalCode : ''} onChange={handleInputChange}  />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="storeCategory" className="col-sm-2 col-form-label">Store Category 1</label>
                        <div className="col-sm-4">
                            {storeData.storeCategories && storeData.storeCategories.length > 0 ? (
                                <select className="form-select" id="storeCategory" value={formData.storeCategory?.id ? formData.storeCategory.id : ''} onChange={handleInputChange} >
                                    <option value="">(Optional) Store Category 1</option>
                                    {storeData.storeCategories.map(category => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </select>
                            ) : (
                                <input type="text" className="form-control" placeholder="No Store Categories" disabled />
                            )}
                        </div>

                        <label htmlFor="storeCategory2" className="col-sm-2 col-form-label">Store Category 2</label>
                        <div className="col-sm-4">
                            {storeData.storeCategories && storeData.storeCategories.length > 0 ? (
                                <select className="form-select" id="storeCategory2" value={formData.storeCategory2?.id ? formData.storeCategory2.id : ''} onChange={handleInputChange} >
                                    <option value="">(Optional) Store Category 2</option>
                                    {storeData.storeCategories.map(category => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </select>
                            ) : (
                                <input type="text" className="form-control" placeholder="No Store Categories" disabled />
                            )}
                        </div>
                    </div>


                    <div className="row mb-3">
                        <label htmlFor="returnPolicy" className="col-sm-2 col-form-label">Return Policy</label>
                        <div className="col-sm-4">
                            {storeData.returnPolicies && storeData.returnPolicies.length > 0 ? (
                                <select className="form-select" id="returnPolicy" value={formData.sellerProfiles?.returnPolicy ? formData.sellerProfiles?.returnPolicy?.id : ''} onChange={e => handleInputChange(e, 'sellerProfiles')}>
                                    <option value="">Select Return Policy</option>
                                    {storeData.returnPolicies.map(policy => (
                                        <option key={policy.id} value={policy.id}>{policy.name}</option>
                                    ))}
                                </select>
                            ) : (
                                "Loading or not found. Add in settings."
                            )}
                        </div>

                        <label htmlFor="paymentPolicy" className="col-sm-2 col-form-label">Payment Policy</label>
                        <div className="col-sm-4">
                            {storeData.paymentPolicies && storeData.paymentPolicies.length > 0 ? (
                                <select className="form-select" id="paymentPolicy" value={formData.sellerProfiles?.paymentPolicy ? formData.sellerProfiles?.paymentPolicy?.id: ''} onChange={e => handleInputChange(e, 'sellerProfiles')}>
                                    <option value="">Select Payment Policy</option>
                                    {storeData.paymentPolicies.map(policy => (
                                        <option key={policy.id} value={policy.id}>{policy.name}</option>
                                    ))}
                                </select>
                            ) : (
                                "Loading or not found. Add in settings."
                            )}
                        </div>
                    </div>


                </form>
            </div>
        </div>
    )
}

export default React.memo(ListingDetails);