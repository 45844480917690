import axios from 'axios';

const baseURL = `${process.env.REACT_APP_ENDPOINT}/users`;

export const postWaitlist = (formData) => {
    return axios.post(`${baseURL}/waitlist`, formData)
    .then(response => response.data)
    .catch(error => {
        console.error(error);
        throw error;
    });
}

export const getUser = (token) => {
    console.log('GET USER baseURL - ', baseURL)

    return axios.get(`${baseURL}/find`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error(error);
        throw error;
    });
  };

export const getStore = (token) => {
    return axios.get(`${baseURL}/store`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error(error);
        throw error;
    });
}

export const setAutoImport = (token, enable) => {
    return axios.get(`${baseURL}/autoimport`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            enable: enable
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error(error);
        throw error;
    });
}

export const getStoreData = (token, refresh = false) => {
    const queryParams = refresh ? '?refresh=true' : '';
    return axios.get(`${baseURL}/store/policies${queryParams}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error(error);
        throw error;
    });
};

export const createUser = (token, userData) => {
    console.log('createUser POST - ', userData);
    return axios.post(`${baseURL}/create`, userData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error(error);
        throw error;
    });
};

export const updateUser = (token, userData) => {
    return axios.post(`${baseURL}/update`, userData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error(error);
        throw error;
    });
};

export const getEbayAuthorizationLink = (token) => {
    return axios.get(`${baseURL}/ebay/authUrl`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error(error);
        throw error;
    });
  };
  

export const ebayCallback = ({code, state}) => {
    console.log('ebayCallback code - ', code);
    console.log('ebayCallback state - ', state);
    return axios.post(`${baseURL}/callback`, {code, state})
    .then(response => response.data)
    .catch(error => {
        console.error(error);
        throw error;
    });
};

export const refreshStoreData = (token) => {
    return axios.get(`${baseURL}/store/policies?refresh=true`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => response.data);
  };
  

  