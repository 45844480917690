import React from 'react';

//NOTE: updated to just be a card, not using collapsable

const CollapsableCard = ({ 
    cardTitle,
    idFragment,
    children
}) => {

    const headerId = `${idFragment}-header`;

    return (
        <div className="card mb-2" id={headerId}>
            <div className="card-header">{cardTitle}</div>
            <div className="card-body">
                {children}
            </div>
        </div>
    );
};

export default CollapsableCard;
