import React, { useState } from 'react';
import { userAPI } from '../../../api';
import { useUserAuth } from '../../../context/UserAuthContext';
import ConnectMarketplaceModal from './ConnectMarketplaceModal';
import ConnectButton from './ConnectButton';


const MarketplaceSection = ({ name, color, connectionData = {}, loading = false, disabled = false }) => {
    const marketplaceData = connectionData?.[name.toLowerCase()] ?? {};
    const [loadingMarketplace, setLoadingMarketplace] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user } = useUserAuth();

    const handleConnect = async () => {
        const connectFunction = {
            ebay: handleConnectEbay,
            poshmark: handleConnectPoshmark,
            mercari: handleConnectMercari
        }[name.toLowerCase()];

        if (connectFunction) {
            await connectFunction();
        } else {
            console.error(`No connect function defined for ${name}`);
        }
    };

    const handleConnectPoshmark = async () => {
        setIsModalOpen(true)
    };

    const handleConnectMercari = async () => {
        // Define the logic for connecting to Mercari
    };

    const handleConnectEbay = async () => {
        try {
            setLoadingMarketplace(true);
            const token = await user.getIdToken(true);
            const response = await userAPI.getEbayAuthorizationLink(token);
            console.log('handleConnectEbay response - ', response);
            const authUrl = response.url;
            window.location.href = authUrl;
        } catch (error) {
            console.error('Error connecting to eBay:', error);
        } finally {
            setLoadingMarketplace(false);
        }
    };

    let content;
    if (disabled) {
        content = <p>Coming Soon</p>;
    }else if(marketplaceData.connected){
        content = <p>{marketplaceData.username} Connected</p>;
    }else {
        content = (
            <ConnectButton 
                marketplace={name} 
                onClick={handleConnect} 
                loading={loadingMarketplace} 
            />
        );
    }
    

    return (
        <div 
            className="card-body" 
            style={{ 
                backgroundColor: disabled ? '#d3d3d3' : color, 
                color: 'white' 
            }}
        >
            <h5 style={{ margin: 0, color: 'white' }}>{name}</h5>
            <div style={{ textAlign: 'right' }}>
                {loading ? <p>Loading...</p> : content }
            </div>

            {name.toLowerCase() !== 'ebay' && !disabled && (
                <ConnectMarketplaceModal 
                    marketplaceName={name}
                    show={isModalOpen} 
                    onClose={() => setIsModalOpen(false)} 
                />
            )}

        </div>
    );
};

const MarketplaceConnections = ({ connectionData, loading }) => {

    console.log('connectionData - ', connectionData);
    console.log('loading - ', loading);

    return (
        <div className="card mb-4 mb-xl-0">
            <div className="card-header">Marketplace Connections</div>
                {/* eBay Section */}
                <MarketplaceSection
                    name="eBay"
                    color="#2937f0"
                    connectionData={connectionData}
                    loading={loading}
                />
                {/* Poshmark Section */}
                <MarketplaceSection
                    name="Poshmark"
                    color="#7f0353"
                    content="Coming Soon"
                    connectionData={connectionData}
                    loading={loading}
                />
                {/* Mercari Section */}
                <MarketplaceSection
                    name="Mercari"
                    color="#5356ee"
                    content="Coming Soon"
                    disabled={true}
                />

        </div>
    );
}

export default React.memo(MarketplaceConnections);;
