import React from 'react';
import SettingsCollapsableCard from './SettingsCollapsableCard'; // Adjust the import path as necessary

const GeneralSettingsCard = ({ settingsDataGeneral, handleChange, updateSettings, settingsDataSaved, submitLoading }) => {


    return (
        <SettingsCollapsableCard
            cardTitle="General Settings"
            idFragment="GeneralSettings"
            isCollapsed={false}
            theme="default"
            updateSettings={updateSettings}
            settingsDataSaved={settingsDataSaved}
            submitLoading={submitLoading}
        >
            <div className="row mb-3 align-items-center">
                <div className="col-md-4">
                    <label htmlFor="locationPostalCode" className="form-label"><p>Item Location Postal Code</p></label>
                </div>
                <div className="col-md-4">
                    <input type="text" className="form-control" id="locationPostalCode" placeholder="Enter Zip Code" 
                        value={settingsDataGeneral.locationPostalCode || ''} 
                        onChange={(e) => handleChange(e, 'general.locationPostalCode')}
                    />
                </div>
            </div>

            <div className="row mb-3 align-items-center">
                <div className="col-md-4">
                    <label htmlFor="package-dimensions" className="form-label"><p>Package Dimensions</p></label>
                </div>
                <div className="col-sm-1" style={{ padding: 0 }}>
                    <input 
                        type="text" 
                        id="packageDepth"
                        className="form-control" 
                        placeholder="in." 
                        style={{ textAlign: 'center', minWidth: '46px' }} 
                        value={settingsDataGeneral.packageDetails?.packageDepth || ''} 
                        onChange={(e) => handleChange(e, 'general.packageDetails.packageDepth')}
                    />
                </div>
                <div className="col-sm-1" style={{ padding: 0 }}>
                    <input 
                        type="text" 
                        id="packageLength"
                        className="form-control" 
                        placeholder="in."
                        style={{ textAlign: 'center', minWidth: '46px' }} 
                        value={settingsDataGeneral.packageDetails?.packageLength || ''} 
                        onChange={(e) => handleChange(e, 'general.packageDetails.packageLength')}
                    />
                </div>
                <div className="col-sm-1" style={{ padding: 0 }}>
                    <input 
                        type="text" 
                        id="packageWidth"
                        className="form-control" 
                        placeholder="in."
                        style={{ textAlign: 'center', minWidth: '46px' }} 
                        value={settingsDataGeneral.packageDetails?.packageWidth || ''} 
                        onChange={(e) => handleChange(e, 'general.packageDetails.packageWidth')}
                    />
                </div>


            </div>


            <div className="row mb-3">
                <div className="col-12">
                    <label htmlFor="conditionDescription" className="form-label">Condition Description (Used items)</label>
                    <textarea className="form-control" id="conditionDescription" rows="3" 
                        value={settingsDataGeneral.conditionDescription || ''} 
                        onChange={(e) => handleChange(e, 'general.conditionDescription')}
                    >
                    </textarea>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-12">
                    <label htmlFor="description" className="form-label">Item Description</label>
                    <p>Use [title] and/or [size] to dynamically adjust description.</p>
                    <textarea className="form-control" id="description" rows="5" 
                        value={settingsDataGeneral.description || ''} 
                        onChange={(e) => handleChange(e, 'general.description')}
                    >
                    </textarea>
                </div>
            </div>

        </SettingsCollapsableCard>
    );
};

export default GeneralSettingsCard;
