import React, { useState, useRef, useEffect } from "react";
import ItemSpecific from '../ItemSpecific';
import { useUserAuth } from '../../../context/UserAuthContext';
import { templateAPI } from '../../../api';
import ResetButton from './ResetButton';


const FormBrand = ({ formDataBrand, setFormData }) => {
    const { user } = useUserAuth();
    const [brandList, setBrandList] = useState([]);

    useEffect(() => {
        //workaround for error where brand is turning into Array (from templates due to all Item Specifics being arrays)

        if(formDataBrand && Array.isArray(formDataBrand) && formDataBrand.length > 0){
            setFormData(prevData => ({
                ...prevData,
                brand: formDataBrand[0]
            }))
        }
    }, [formDataBrand])

    useEffect(() => {
        const fetchBrandList = async () => {
            try {
                const token = await user.getIdToken(true);
                const brands = await templateAPI.getBrandList(token);
                setBrandList(brands);
            } catch (error) {
                console.error('Error fetching brand list:', error);
            }
        };

        if (user) {
            fetchBrandList();
        }
    }, [user]);

    const brandSpecific = {
        name: 'Brand',
        rules: {isFreeText: true, isSingle: true, required: true},
        values: brandList
    }

    const handleResetBrand = () => {
        setFormData(prevData => {
            const updatedData = { ...prevData };
            delete updatedData.brand; 
            return updatedData;
        });

    };

    return (
        <div className="col-5">
            <div style={{ width: '100%' }}>
                <label htmlFor="itemBrand" className="form-label mb-0" style={{ fontSize: '1.2em' }}>Brand</label>
                <ResetButton onReset={handleResetBrand} />
                    <ItemSpecific 
                        key="brandKey"
                        specific={brandSpecific}
                        type="required"
                        initialValue={formDataBrand ? formDataBrand : null}
                        setFormData={setFormData}
                        suggestSpecifics={[]}
                        title={false}
                        fieldPath='brand'
                    />
            </div>
        </div>
    );
    
};

export default React.memo(FormBrand);
