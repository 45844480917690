import React, { useEffect } from 'react';
import { translateData } from '../../utils/translate';
import { StarFill } from 'react-bootstrap-icons'; 

const AITranslateCard = ({ formData, ebayData = {}, marketplaceData, setMarketplaceData, marketplaceName }) => {

    // TODO: better optimize when this runs, or move this logic elsewhere? 
    // Initial Reason for this was to AI Translate when automated if, then is not available by trigger OpenAI api request

    useEffect(() => {

        if (marketplaceData && !marketplaceData.utils?.translated && !marketplaceData.id) {
            translateData(formData, ebayData, (updatedData, itemSpecificsData) => {

                setMarketplaceData(prevData => {
                    const hasItemSpecifics = Object.keys(itemSpecificsData).length > 0;
                    const newState = {
                        ...prevData,
                        ...updatedData,
                        utils: { ...prevData.utils, translated: true }
                    };

                    if (hasItemSpecifics) {
                        newState.itemSpecifics = { ...prevData.itemSpecifics, ...itemSpecificsData };
                    }

                    console.log('newState.itemSpecifics - ', newState.itemSpecifics);
            
                    return newState;
                });

            }, marketplaceName);
        }
    }, [marketplaceData]); //removed marketplaceData so it just runs once.
    
    
    const boxStyle = {
        position: 'relative',
        resize: 'both',
        fontWeight: 700,
        width: '100%',
        backgroundImage: `linear-gradient(
                            105deg, 
                            #2937f0 0%, 
                            #9f1ae2 100%
                          )`
    }

    return (
        <>
            {/* <div className="card mb-2">
                <div className="card-header text-white" style={boxStyle}>
                    AI Translation <StarFill style={{ marginLeft: '5px'}}/>                    
                </div>
                <div className="card-body">
                    Translating...
                </div>
            </div> */}
        </>
    );
};

export default AITranslateCard;
