import React, { useState, useEffect } from 'react';
import { useUserAuth } from '../../../context/UserAuthContext';
import Footer from '../../Helper/Footer';
import SettingsNav from './SettingsNav';
import OnboardImport from '../../Onboard/OnboardImport';
import { listingAPI } from '../../../api';


const SettingsTotalImport = () => {
    const { user } = useUserAuth();
    const [loadingTotalImported, setLoadingTotalImported] = useState(false);
    const [totalImported, setTotalImported] = useState(0);
  
    useEffect(() => {
        const fetchTotalImported = async () => {
            try {
                setLoadingTotalImported(true); 
                const token = await user.getIdToken(true);
                const importedData = await listingAPI.getImportedTotal(token);
                console.log('importedData - ', importedData);
                setTotalImported(importedData.total)
                setLoadingTotalImported(false);
            } catch (error) {
                console.error('An error occurred while getting total imported:', error);
                setLoadingTotalImported(false);
            }
        };

        fetchTotalImported();
    }, [user]);



    return (
        <div className="card mb-4 mb-xl-0">
            <div className="card-header">Total Imported</div>
            <div className="card-body text-center">
                {loadingTotalImported ? (
                    <div>Loading ...</div>
                ) : (
                    <div>Total Imported: {totalImported}</div>
                )}
            </div>
        </div>
    );
}


const SettingsImport = () => {
  const { user } = useUserAuth();
  const [message, setMessage] = useState('');
  const [alertType, setAlertType] = useState('info');

  return (
    <>
    <main>
      <div className="container-xl px-4 mt-4">
        <SettingsNav />
        <div className="row">
          <div className="col-lg-4">
            <SettingsTotalImport />
          </div>
          <div className="col-lg-8">
            <div className="card mb-4">
              <div className="card-header">Importing</div>
              <div className="card-body">
                <OnboardImport setMessage={setMessage} setAlertType={setAlertType} activeStep={3} display='Settings' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
    </>
  );
};

export default SettingsImport;

