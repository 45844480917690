import React from 'react';
import SettingsCollapsableCard from './SettingsCollapsableCard'; // Adjust the import path as necessary

const PoshmarkSettingsCard = ({ settingsDataPoshmark, setSettingsData, handleChange, updateSettings, settingsDataSaved, submitLoading }) => {

    const handleAdjustPriceChange = (e, path) => {
        const { value } = e.target;
        const newValue = value ? Number(value) : '';  // Convert input value to Number, reset if empty
    
        setSettingsData(prevData => {
            // Start by creating a new object to avoid direct mutations
            const newData = { ...prevData };
    
            // Determine what field we're updating and reset the other
            if (path === 'poshmark.priceChangePercent') {
                newData.poshmark = {
                    ...newData.poshmark,
                    priceChangePercent: newValue,  // Set new value for percentage
                    priceChangeAmount: ''          // Reset amount
                };
            } else if (path === 'poshmark.priceChangeAmount') {
                newData.poshmark = {
                    ...newData.poshmark,
                    priceChangeAmount: newValue,  // Set new value for amount
                    priceChangePercent: ''        // Reset percentage
                };
            } else if(path === 'poshmark.originalPricePercent'){
                newData.poshmark = {
                    ...newData.poshmark,
                    originalPricePercent: newValue, 
                    originalPriceAmount: ''        
                };
            } else if(path === 'poshmark.originalPriceAmount'){
                newData.poshmark = {
                    ...newData.poshmark,
                    originalPriceAmount: newValue,
                    originalPricePercent: ''
                };
            }
    
            return newData;
        });
    };

    
    return (
        <SettingsCollapsableCard
            cardTitle="Poshmark Settings"
            idFragment="PoshmarkSettings"
            theme="poshmark"
            updateSettings={updateSettings}
            settingsDataSaved={settingsDataSaved}
            submitLoading={submitLoading}
        >
            <div className="container">
                {/* Discounted Shipping Dropdown */}
                <div className="row mb-3 align-items-center">
                    <div className="col-md-4">
                        <label htmlFor="discountedShipping" className="form-label">
                            <p>Discounted Shipping</p>
                        </label>
                    </div>
                    <div className="col-md-4">
                        <select 
                            className="form-control" 
                            id="discountedShipping" 
                            value={settingsDataPoshmark.discountedShipping || ''}
                            onChange={(e) => handleChange(e, 'poshmark.discountedShipping')}
                        >
                            <option value="">No Discount</option>
                            <option value="5.95">$5.95</option>
                            <option value="4.99">$4.99</option>
                            <option value="0">FREE</option>
                        </select>
                    </div>
                </div>

                {/* Original Price Change Toggle */}
                <div className="row mb-3">
                    <label className="form-check-label">
                        Adjust Original Price
                    </label>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="originalPricePercent" className="form-label"><p>Adjust Percentage (%)</p></label>
                    </div>
                    <div className="col-md-2">
                        <input 
                            type="number" 
                            className="form-control" 
                            id="originalPricePercent"
                            placeholder="%"
                            value={settingsDataPoshmark.originalPricePercent || ''}
                            onChange={(e) => handleAdjustPriceChange(e, 'poshmark.originalPricePercent')}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="originalPriceAmount" className="form-label"><p>Adjust Amount ($)</p></label>
                    </div>
                    <div className="col-md-2">
                        <input 
                            type="number" 
                            className="form-control" 
                            id="originalPriceAmount"
                            placeholder="$"
                            value={settingsDataPoshmark.originalPriceAmount || ''}
                            onChange={(e) => handleAdjustPriceChange(e, 'poshmark.originalPriceAmount')}
                        />
                    </div>
                </div>


                {/* Listing Price Change Toggle */}
                <div className="row mb-3 align-items-center">
                    <div className="col-md-4">
                        <label className="form-check-label" htmlFor="priceChange">
                            Adjust Listing Price
                        </label>
                    </div>
                    <div className="col-md-2 form-check form-switch">
                        <input 
                            type="checkbox" 
                            className="form-check-input" 
                            id="priceChange"
                            checked={settingsDataPoshmark.priceChange || false}
                            onChange={(e) => handleChange({ target: { value: e.target.checked } }, 'poshmark.priceChange')}
                        />
                        <label className="form-check-label" htmlFor="priceChange"></label>
                    </div>
                </div>
                {/* Conditional Price Adjustment Inputs */}
                {settingsDataPoshmark.priceChange && (
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <label htmlFor="priceChangePercent" className="form-label"><p>Adjust Percentage (%)</p></label>
                        </div>
                        <div className="col-md-2">
                            <input 
                                type="number" 
                                className="form-control" 
                                id="priceChangePercent"
                                placeholder="%"
                                value={settingsDataPoshmark.priceChangePercent || ''}
                                onChange={(e) => handleAdjustPriceChange(e, 'poshmark.priceChangePercent')}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="priceChangeAmount" className="form-label"><p>Adjust Amount ($)</p></label>
                        </div>
                        <div className="col-md-2">
                            <input 
                                type="number" 
                                className="form-control" 
                                id="priceChangeAmount"
                                placeholder="$"
                                value={settingsDataPoshmark.priceChangeAmount || ''}
                                onChange={(e) => handleAdjustPriceChange(e, 'poshmark.priceChangeAmount')}
                            />
                        </div>
                    </div>
                )}
            </div>
        </SettingsCollapsableCard>
    );
};

export default PoshmarkSettingsCard;
