import React from 'react';
import CollapsableCard from '../Helper/CollapsableCard';
import FormColor from './FormSimple/FormColor';
import PoshmarkSize from './PoshmarkSize';
import PoshmarkBrand from './PoshmarkBrand';
import MarketplaceCondition from './MarketplaceCondition';

const PoshmarkItemDetails = ({ poshmarkSize, poshmarkSizeChart, categorySizeCharts, poshmarkCondition, poshmarkBrand, poshmarkColor, setMarketplaceData }) => {
    
    const poshmarkColorChart = [ {name: 'Blue', hex: '#6998AB'}, {name: 'Black', hex: '#333333'}, 
        {name: 'White', hex: '#F5F5F5'}, {name: 'Gray', hex: '#9E9E9E'}, {name: 'Red', hex: '#C75D5D'}, 
        {name: 'Green', hex: '#8CBF88'}, {name: 'Brown', hex: '#B08968'}, {name: 'Orange', hex: '#E6A57E'}, 
        {name: 'Tan', hex: '#D1B48E'}, {name: 'Yellow', hex: '#F8E3A3'}, {name: 'Purple', hex: '#9B72AA'}, 
        {name: 'Pink', hex: '#F7BEC0'}, {name: 'Gold', hex: '#FFD700'}, {name: 'Silver', hex: '#C0C0C0'}, {name: 'Cream', hex: '#F4E0CA'}
    ]
    const poshmarkConditionArray = [{name: 'New With Tags', id: '1000', value: 'nwt'}, 
    {name: 'Used', id: '3000', value: 'non_nwt'}];


    return (
        <CollapsableCard cardTitle="Poshmark Item Details" idFragment="poshmarkItemDetails">
            <div className="row">
                <div className="col-5">
                    <MarketplaceCondition 
                        marketplaceCondition={poshmarkCondition}
                        conditionArray={poshmarkConditionArray}
                        setMarketplaceData={setMarketplaceData}
                        marketplaceName={'Poshmark'}
                    />
                </div>
                <div className="col-7">
                    <FormColor 
                        formDataColor={poshmarkColor}
                        colorChart={poshmarkColorChart}
                        setFormData={setMarketplaceData}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-5">
                    <PoshmarkBrand
                        poshmarkBrand={poshmarkBrand}
                        setMarketplaceData={setMarketplaceData}
                    />
                </div>
                <div className="col-7">
                    <PoshmarkSize 
                        poshmarkSize={poshmarkSize}
                        poshmarkSizeChart={poshmarkSizeChart}
                        categorySizeCharts={categorySizeCharts}
                        setMarketplaceData={setMarketplaceData}
                    />
                </div>
            </div>
        </CollapsableCard>
    );
    
};

export default PoshmarkItemDetails;
