import React, {useState, useEffect} from 'react';
import MarketplaceCard from './MarketplaceCard';
import PhotoPreview from './FormSimple/PhotoPreview';
import MarketplaceTitle from './FormSimple/MarketplaceTitle';
import MarketplaceDescription from './FormSimple/MarketplaceDescription';
import MarketplaceCategory from './MarketplaceCategory';
import PoshmarkItemDetails from './PoshmarkItemDetails';
import PoshmarkPricingDetails from './PoshmarkPricingDetails';

import { useUserAuth } from '../../context/UserAuthContext';
import { templateAPI, cloudAPI, listingAPI } from '../../api';
import { updateMarketplaceStatus } from '../../utils/updateStatus';
import { validatePoshmark } from '../../utils/marketplace';

const CrossListPoshmark = ({formData, setFormData, ebayData, poshmarkData, setPoshmarkData, setActiveMarketplace}) => {
    const { user } = useUserAuth();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [poshmarkCategories, setPoshmarkCategories] = useState({});
    const [poshmarkSizeChartRegistry, setPoshmarkSizeChartRegistry] = useState({});
    const [categorySizeCharts, setCategorySizeCharts] = useState([]);
    const [checkGeneralListingTimeout, setCheckGeneralListingTimeout] = useState(null);

    const fetchPoshmarkCategories = async () => {
        try {
            const token = await user.getIdToken(true);
            const data = await templateAPI.getMarketplaceCategories(token, 'Poshmark')
            
            console.log('data - ', data);
            setPoshmarkCategories(data.categories);
            setPoshmarkSizeChartRegistry(data.sizeCharts)
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };

    useEffect(() => {
        if (Object.keys(poshmarkCategories).length === 0) {
            fetchPoshmarkCategories();
        }
    }, []);

    useEffect(() => {
        if (poshmarkData?.category?.category && Object.keys(poshmarkSizeChartRegistry).length > 0) {
            const sizeCharts = poshmarkCategories?.[poshmarkData?.category?.department]?.[poshmarkData?.category?.category]?.['sizeCharts'];
            let sizeChartArray = [];

            sizeCharts?.forEach(sizeChart => {
                const chartDetails = poshmarkSizeChartRegistry?.[sizeChart];
                sizeChartArray.push(chartDetails)
            });

            setCategorySizeCharts(sizeChartArray);
        }
    }, [poshmarkData?.category?.category, poshmarkSizeChartRegistry]);


    const handleSubmitPoshmark = async () => {
        setSubmitLoading(true);
        setAlert({ message: '', type: '' }); 
        try {
            const validationResponse = validatePoshmark(poshmarkData);
            if (!validationResponse.validated) {
                setAlert({ message: validationResponse.alertMessage, type: validationResponse.alertType });
                setSubmitLoading(false); 
                return; 
            }

            updateMarketplaceStatus('Poshmark', 'processing...', setFormData);

            const token = await user.getIdToken(true);
            //create a product ID first, update with cloudAction finish?
            const listingResponse = await listingAPI.createMarketplaceListing(token, {...poshmarkData, status: 'processing'}, formData);

            if (listingResponse?.marketplaceListingData) {
                let updates = {};
                if (listingResponse.marketplaceListingData._id) { updates.id = listingResponse.marketplaceListingData._id; }
                if (listingResponse.marketplaceListingData.generalListing){ updates.generalListing = listingResponse.marketplaceListingData.generalListing; }
                if (listingResponse.marketplaceListingData.status){ updates.status = listingResponse.marketplaceListingData.status; }
                if (listingResponse.marketplaceListingData.marketplaceListingId){ updates.marketplaceListingId = listingResponse.marketplaceListingData.marketplaceListingId; }
                if (Object.keys(updates).length > 0) {
                    setPoshmarkData(prevPoshmarkData => ({
                        ...prevPoshmarkData,
                        ...updates
                    }));
                }
            }

            if(!formData.id && listingResponse?.generalListingData?._id){
                setFormData(prevFormData => ({
                    ...prevFormData,
                    id: listingResponse.generalListingData._id
                }));
            }

            const cloudResponse = await cloudAPI.createCloudAction(token, "poshmarkListProduct", { poshmarkData, marketplaceListingId: listingResponse?.marketplaceListingData?._id});
            console.log('cloudResponse - ', cloudResponse);

            setAlert({ message: 'Poshmark listing submitted and processing. You can leave or close page.', type: 'success' });
            

            const checkGeneralListing = async (token, listingId, retries = 10, delay = 5000) => {
                if (retries <= 0) {
                    return;
                }
            
                try {
                    const generalListingResponse = await listingAPI.readGeneralListing(token, listingId);
                    let processedUpdates = {};
                    const updatedMarketplaceListingId = generalListingResponse?.listingData?.Poshmark?.marketplaceListingId;
                    const updatedStatus = generalListingResponse?.listingData?.Poshmark?.status;
            
                    if (updatedStatus && updatedStatus !== poshmarkData.status) {
                        processedUpdates.status = updatedStatus;
                    }
                    if (updatedMarketplaceListingId) {
                        processedUpdates.marketplaceListingId = updatedMarketplaceListingId;
                    }
            
                    console.log('General Listing Response:', generalListingResponse);
                    setPoshmarkData(prevPoshmarkData => ({
                        ...prevPoshmarkData,
                        ...processedUpdates
                    }));
                    updateMarketplaceStatus('Poshmark', updatedStatus, setFormData);
            
                    if (!updatedMarketplaceListingId && !updatedStatus && !checkGeneralListingTimeout) {
                        setTimeout(() => checkGeneralListing(token, listingId, retries - 1, delay), delay);
                    }
                } catch (error) {
                    console.error('Error checking general listing:', error);
                }
            };
            
            // Set timeout to check for listing ID, but allow to be reset if form changes.
            setCheckGeneralListingTimeout(
                setTimeout(() => {
                    checkGeneralListing(token, listingResponse?.generalListingData?._id, 10, 5000);
                }, 90000)
            );

            // setAlert({message: extSubmitResponse.alertMessage, type: extSubmitResponse.alertType});
        } catch (err) {
            updateMarketplaceStatus('Poshmark', 'error', setFormData);
            setAlert({message: `Error in saving: ${err.response?.data?.message}`, type: 'danger'});
            return false;
        } finally {
            setSubmitLoading(false);
        }
    }

    useEffect(() => {
        if (formData.utils?.mode === 'reloadPending') {
            if (checkGeneralListingTimeout) {
                clearTimeout(checkGeneralListingTimeout);
            }
        }
    }, [formData.utils?.mode]);

    return(
        <MarketplaceCard 
            formData={formData}
            setFormData={setFormData}
            ebayData={ebayData}
            marketplaceData={poshmarkData}
            setMarketplaceData={setPoshmarkData}
            marketplaceName="Poshmark"
            handleSubmitMarketplace={handleSubmitPoshmark}
            submitLoading={submitLoading}
            alert={alert}
            setAlert={setAlert}
            setActiveMarketplace={setActiveMarketplace}
        >
            <PhotoPreview
                formDataPictures={formData.pictures}
                marketplaceDataPictures={poshmarkData.pictures || []}
                setMarketplaceData={setPoshmarkData}
                marketplaceName="Poshmark"
                maxPhotos={16}
            />
            <MarketplaceTitle 
                marketplaceDataTitle={poshmarkData.title}
                setMarketplaceData={setPoshmarkData}
                marketplaceName="Poshmark"
            />
            <MarketplaceDescription
                marketplaceDataDescription={poshmarkData.description}
                setMarketplaceData={setPoshmarkData}
                marketplaceName="Poshmark"
            />
            <MarketplaceCategory 
                marketplaceDataCategory={poshmarkData.category}
                setMarketplaceData={setPoshmarkData}
                marketplaceName="Poshmark"
                marketplaceCategories={poshmarkCategories}
            />
            <PoshmarkItemDetails 
            // TODO: style tags
                poshmarkSize={poshmarkData.size}
                poshmarkSizeChart={poshmarkData.sizeChart}
                categorySizeCharts={categorySizeCharts}
                poshmarkCondition={poshmarkData.condition}
                poshmarkBrand={poshmarkData.brand}
                poshmarkColor={poshmarkData.color}
                setMarketplaceData={setPoshmarkData}
            />
            <PoshmarkPricingDetails
            //TODO: Discounted Shipping (Optional), Cost Price (optional), Availability 
                poshmarkPrice={poshmarkData.price}                
                poshmarkOriginalPrice={poshmarkData.originalPrice}
                poshmarkSKU={poshmarkData.sku}
                poshmarkUtils={poshmarkData.utils || {}}
                setMarketplaceData={setPoshmarkData}
            />


        </MarketplaceCard>
    )
}


export default CrossListPoshmark;