import React from "react";

const ProductRowSkeleton = () => {
    return (
      <div className="card" style={{ borderRadius: '10px', margin: '10px' }}>
        <div className="row no-gutters" style={{ height: '100%' }}>
          <div className="col-2">
            <div className="img-fluid" style={{ backgroundColor: 'grey', height: '100%', borderRadius: '10px 0 0 10px' }} />
          </div>
          <div className="col-8 d-flex flex-column">
            <div className="ml-4 mt-4 flex-grow-1">
              <div className="skeleton-title" />
              <div className="d-flex">
                <div className="skeleton-details" />
                <div className="skeleton-details" />
                <div className="skeleton-details" />
              </div>
            </div>
            <div className="d-flex marketplaces ml-4 mb-4">
              <div className="skeleton-icon" />
              <div className="skeleton-icon" />
              <div className="skeleton-icon" />
            </div>
          </div>
          <div className="col-2 d-flex align-items-center justify-content-center" style={{ border: 'none' }}>
            <div className="skeleton-icon" />
            <div className="skeleton-icon" />
          </div>
        </div>
      </div>
    );
};

export default ProductRowSkeleton;