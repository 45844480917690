import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Footer from '../../Helper/Footer';
import SettingsNav from './SettingsNav';
import { billingAPI } from '../../../api';
import { useUserAuth } from '../../../context/UserAuthContext';

const stripePromise = loadStripe('pk_live_51PqIhrP51DzKe2xqZc6e8ode7f8lBEVjqGYebgOgJRqsdbVbbDXB3cQddnBIguEJV2kJ362idIcRZDGJt8EnWmT7000VgU20HT');

const PaymentForm = () => {
  const { user } = useUserAuth();
  const stripe = useStripe();
  const elements = useElements();
  const [alert, setAlert] = useState({ type: '', message: '' });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
        const token = await user.getIdToken(true);
        const { clientSecret } = await billingAPI.createPaymentIntent(token, 1000);
  
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });
    
        if (error) {
            console.log('[error]', error);
            setAlert({ message: 'Payment failed. Please try again.', type: 'error'});
        } else {
            console.log('[PaymentIntent]', paymentIntent);
            setAlert({ message: 'Payment successful!', type: 'success'});
        }
      } catch (error) {
        console.error('Error creating payment intent:', error);
        setAlert({ message: 'An error occurred while processing your payment.', type: 'error'});
      }
  };

  return (
    <form onSubmit={handleSubmit} className="container mt-2">
      <div className="container">
        {alert.message && (
          <div className={`alert alert-${alert.type}`} role="alert">
            {alert.message}
          </div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="card-element" className="form-label">
          Card Details
        </label>
        <CardElement id="card-element" className="form-control" />
      </div>
      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-primary" disabled={!stripe}>
          Pay
        </button>
      </div>
    </form>
  );
};

const PlanDetails = () => {


  return (
    <>
      <div className="card mb-3">
        <div className="card-header">Current Plan</div>
        <div className="card-body">
          <div className="card mb-3">
            <div className="card-header">Launch Plan</div>
            <div className="card-body">
              <p><span>Price: Free*</span></p>
              <p><span>*Free for a limited time as we fix issues and build more features.</span></p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Billing = () => {
  return (
    <>
    <main>
      <div className="container-xl px-4 mt-4">
        <SettingsNav />

        <div className="row">
            <div className="col-lg-4">
              <PlanDetails />
            </div>
            <div className="col-lg-8">
                <div className="card mb-4 bg-light text-muted">
                <div className="card-header">Payment Details</div>
                <div className="card-body">
                    <div className="alert alert-success" role="alert">
                      Free in Launch period
                    </div>
                    <p><span>Instead of payment, we ask for feedback. Help us build the best tools for resellers.</span></p>
                    {/* <Elements stripe={stripePromise}>
                        <PaymentForm />
                    </Elements> */}
                </div>
                </div>
            </div>
        </div>

      </div>
    </main>
    <Footer />
    </>
  );
};

export default Billing;
