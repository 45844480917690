import React, { useState, useEffect, useRef } from 'react';
import { useUserAuth } from '../../../context/UserAuthContext';
import { listingAPI } from '../../../api';

import ImageSimple from '../ImageSimple';
import AIAssist from './AIAssist'
import AIAssistNew from './AIAssistNew';
import FormItemDetails from './FormItemDetails';
import ShipPriceDetails from './ShipPriceDetails';
import FormDescription from './FormDescription';
import CategorySelector from './CategorySelector';
import BasicSearch from './BasicSearch';

import { updateMarketplaceStatus } from '../../../utils/updateStatus';

const GeneralListing = ({ formData, setFormData, templates, setTemplates, imageData, setImageData, setEbayData, setActiveMarketplace }) => {
    const { user } = useUserAuth();
    const [saveDraftLoading, setSaveDraftLoading] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [formDataChanged, setFormDataChanged]= useState(false);
    const savedFormDataRef = useRef(null);

    useEffect(() => {
        if(formData.id){
            console.log('useEffect formData.id - ', formData.id);
            savedFormDataRef.current = formData;
        }
    }, [formData.id]); 

    const saveDraft = async () => {
        console.log('save Draft formData - ', formData);
        setSaveDraftLoading(true)
        try {
            const generalListingId = formData.id ?? null;

            if (!validateDraftSave()) {
                setSaveDraftLoading(false); 
                return;
            }

            //NOTE: covers edge case of imported product General photo order changing; use tempId since no ID then remove for db validation;
            const updatedFormData = {
                ...formData,
                ...(formData.pictures && { 
                    pictures: formData.pictures.map(picture => ({
                      ...picture,
                      _id: picture._id && picture._id.startsWith('temp') ? undefined : picture._id
                    }))
                })
            };
            
            let responseData;
            const token = await user.getIdToken(true);
            if(generalListingId){
                responseData = await listingAPI.updateGeneralListing(token, updatedFormData, generalListingId);
                savedFormDataRef.current = formData
            }else{
                responseData = await listingAPI.createGeneralListing(token, updatedFormData );
            }
            
            console.log('responseData - ', responseData);
            setAlert({ message: '', type: '' }); //reset any previous alerts from errors

            if (responseData.generalListing?._id) { 
                updateMarketplaceStatus('General', 'saved', setFormData);
            }
            setFormDataChanged(false);

        } catch (err) {
            setAlert({message: `Error in saving: ${err}`, type: 'danger'});
        } finally {
            setSaveDraftLoading(false); 
        }
    };

    useEffect(() => { //checks for changes made after draft is saved
        if (savedFormDataRef.current && JSON.stringify({ ...formData, utils: {} }) !== JSON.stringify({ ...savedFormDataRef.current, utils: {} })) {
            setFormDataChanged(true);
        }
    }, [formData]);

    const validateDraftSave = () => {
        const isUploadFinished = formData.utils?.imageUploadFinished !== false;
        if (!isUploadFinished) {
            setAlert({ message: 'Please wait for images to finish uploading. Try again shortly.', type: 'warning' });
            return false; // Early return if uploads aren't finished
        }

        return true;
    };
    
    const handleStartNew = () => {
        // Remove any query parameters
        if (window.location.search) {
            // Construct the new URL without query parameters
            const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            window.history.pushState({ path: newUrl }, '', newUrl);
        }
        // Reload the page
        window.location.reload();
    };


    const handleEbayClick = () => {
        setActiveMarketplace('eBay');
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('marketplace', 'ebay');
        window.history.pushState(null, '', '?' + searchParams.toString());
    };

    useEffect(() => {
        console.log('General Listing formData - ', formData);
    }, [])

    const footerGeneral = (
        <>
            {alert.message && (
                <div className={`alert alert-${alert.type}`} role="alert">
                    {alert.message}
                </div>
            )}
            <div className="text-end">
                {formData.id && formData.status !== 'temporary' && !formDataChanged ? (
                    <>
                        <button className="btn btn-success btn-sm" disabled>
                            Saved
                        </button>
                        <button 
                            className="btn btn-secondary btn-sm" 
                            style={{ marginLeft: '5px' }} 
                            onClick={ handleStartNew }
                        >
                            Start New Listing
                        </button>
                        <button 
                            className="btn btn-primary btn-sm"
                            onClick={() => handleEbayClick() }
                            style={{marginLeft: '2px'}}
                        >Next: eBay Listing</button>
                    </>
                ) : (
                    <>
                        <button
                            className="btn btn-secondary btn-sm"
                            onClick={() => saveDraft(formDataChanged)} //pass arg for update, not save
                            disabled={saveDraftLoading}
                        >
                            {saveDraftLoading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : formDataChanged ? (
                                "Update Draft"
                            ) : (
                                "Save Draft"
                            )}
                        </button>
                        <button 
                            className="btn btn-primary btn-sm"
                            onClick={() => handleEbayClick() }
                            style={{marginLeft: '2px'}}
                        >Next: eBay Listing</button>
                    </>
                )}
            </div>
        </>
    )

    return (

    <div className="card" id="generalCard" style={{marginBottom: '100px'}}>
        <div className="card-header">General Listing</div>
        <div className="card-body">
            <ImageSimple 
                key={`image-simple-0}`} 
                imageData={imageData}
                setImageData={setImageData}
                formData={formData}
                setFormData={setFormData}
                setEbayData={setEbayData}
            />

            {
                formData.utils?.mode !== 'loaded' && (
                    <AIAssistNew
                        imageData={imageData}
                        formData={formData}
                        setFormData={setFormData}
                        setEbayData={setEbayData}
                    />
                )
            }

            <BasicSearch 
                setTemplates={setTemplates}
                searchTermOutside={formData.utils?.searchTerm ?? ''}
                size={formData.size ?? ''}
            />
            <CategorySelector 
                formDataCategoryId={formData.categoryId}
                formDataCategoryName={formData.categoryName}
                formDataMode={formData.utils?.mode ?? ''}
                setFormData={setFormData}
                templates={templates}
            />
            <FormItemDetails 
                formData={formData}
                setFormData={setFormData}
                templates={templates}
            />
            <FormDescription
                formData={formData}
                setFormData={setFormData}
            />
            <ShipPriceDetails
                formData={formData}
                setFormData={setFormData}
            />
        </div>  
        <div className="card-footer">
            {footerGeneral}
        </div>
    </div>
    );
};

export default GeneralListing;
