import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthFooter from './AuthFooter';
import { useUserAuth } from '../../context/UserAuthContext';


function Onboard() {
    const [email, setEmail] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [inviteCode, setInviteCode] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { signUp } = useUserAuth();
    const navigate = useNavigate(); 

    useEffect(() => {
        if (error) {
            setIsLoading(false);
        }
    }, [error]);

    const validateForm = ({email, password, confirmPassword, displayName, termsAccepted}) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!email || !emailPattern.test(email)) {
          return 'Please enter a valid email address.';
      }
  
      if (password !== confirmPassword) {
          return 'Passwords do not match.';
      }
  
      if (password.length < 8) {
          return 'Password must be at least 8 characters long.';
      }
  
      if (!displayName) {
          return 'Please enter a username.';
      }
  
      if (!termsAccepted) {
          return 'You must accept the Terms of Service to continue.';
      }
  
      // If all validations pass, return null indicating no errors
      return null;
    };
  
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError("");
      setIsLoading(true);
  
      // Consolidate form data into an object for validation
      const formData = {email, password, confirmPassword, displayName, termsAccepted};
      const validationError = validateForm(formData);
  
      if (validationError) {
          setError(validationError);
          setIsLoading(false); // Ensure loading is set to false on error
          return;
      }
  
      try {
        console.log('sign Up - ', displayName);
        const userData = await signUp(email, password, displayName, inviteCode);
        setIsLoading(false);
        console.log('Onboard userData - ', userData);
        if (userData?.backendUser?.user?.betaAccess) {
            navigate('/onboard/main');
        } else {
            navigate('/onboard/waitlist');
        }
      } catch (err) {
        setIsLoading(false); // Ensure loading is set to false on error
        const errorMessage = err?.response?.data?.error ?? err.message ?? 'An unexpected error occurred.';
        setError(errorMessage);
      }
    
    };    

  return (
    <div id="layoutAuthentication" className="bg-primary">
        <div id="layoutAuthentication_content">
        <main>
            <div className="container-xl px-4">
            <div className="row justify-content-center">
                <div className="col-lg-7">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header justify-content-center"><h3 className="fw-light my-4">Create Account</h3></div>
                    <div className="card-body">
                      {error && <div className="alert alert-danger" role="alert">
                          {error}
                      </div>}
                    <form onSubmit={handleSubmit}>
                        <div className="row gx-3">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="small mb-1" htmlFor="inputEmailAddress">Email</label>
                                    <input className="form-control" id="inputEmailAddress" type="email" value={email} onChange={e => setEmail(e.target.value)} aria-describedby="emailHelp" placeholder="Enter email" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="small mb-1" htmlFor="displayName">Username</label>
                                    <input className="form-control" id="displayName" type="text" value={displayName} onChange={e => setDisplayName(e.target.value)} aria-describedby="displayName" placeholder="Enter username" />
                                </div>
                            </div>
                        </div>
                        <div className="row gx-3">
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label className="small mb-1" htmlFor="inputPassword">Password</label>
                                <input className="form-control" id="inputPassword" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Enter password" />
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label className="small mb-1" htmlFor="inputConfirmPassword">Confirm Password</label>
                                <input className="form-control" id="inputConfirmPassword" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="Repeat password" />
                            </div>
                            </div>
                        </div>
                        <div className="row gx-3">
                          <div className="col-md-6">
                          <div className="mb-3">
                            <label className="small mb-1" htmlFor="inputInviteCode">Invite Code</label>
                            <input 
                                className="form-control" 
                                id="inputInviteCode" 
                                type="text" 
                                value={inviteCode} 
                                onChange={e => setInviteCode(e.target.value)} 
                                placeholder="Enter invite code (optional)" 
                            />
                          </div>
                          </div>
                          <div className="col-md-6 mt-5">
                          <div className="mb-3 form-check">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                id="termsCheckbox" 
                                checked={termsAccepted} 
                                onChange={e => setTermsAccepted(e.target.checked)} 
                            />
                            <label className="form-check-label small mb-1" htmlFor="termsCheckbox">
                                I accept the <a href="https://allbutnew.com/privacy-policy" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                            </label>

                          </div>
                          </div>
                        </div>
                        <button className="btn btn-primary btn-block" type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                "Create Account"
                            )}
                        </button>
                        </form>
                    </div>
                    <div className="card-footer text-center">
                      <div className="small">
                        <Link to="/login">Have an account? Go to login</Link>
                      </div>
                    </div>

                </div>
                </div>
            </div>
            </div>
        </main>
        </div>

        <AuthFooter />
    </div>
  );
}

export default Onboard;
