import axios from 'axios';

// Base URL for the product-related endpoints
const baseURL = `${process.env.REACT_APP_ENDPOINT}/settings`;



export const getDefaults = async (token, options = {}) => {
  const response = await axios.get(`${baseURL}/defaults`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: options
  });
  return response.data;
};

export const updateDefaults = async (token, settingsData) => {
  const response = await axios.put(`${baseURL}/defaults`, settingsData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const createDefaults = async (token, settingsData) => {
  const response = await axios.post(`${baseURL}/defaults`, settingsData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const getMarketplaceConnections = async (token, options = {}) => {
  const response = await axios.get(`${baseURL}/connections`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: options
  });
  return response.data;
};
