// utils/translate.js

/**
 * Updates marketplace data based on formData.
 * @param {Object} formData - The form data.
 * @param {Function} setMarketplaceData - State setter function for marketplace data.
 * @param {String} marketplaceName - The name of the marketplace.
 */
export const translateData = (formData, ebayData = {}, callback, marketplaceName) => {
    //TODO: more advanced translation; trigger API call if category is not found.
    //TODO: also include eBay data to analyze (if available) to find poshmark category (for example);
    let updates = {};
    let itemSpecifics = {};

    const propertiesToUpdate = ['title', 'sku', 'locationPostalCode', 'description', 'pictures']; 

    propertiesToUpdate.forEach(property => {
        if (formData[property]) {
            updates[property] = formData[property];
        }
    });

    if(formData.packageDetails){
      //skip for Poshmark
      if(marketplaceName === 'eBay' || marketplaceName === 'Mercari'){
        updates.packageDetails = formData.packageDetails
      }
    }

    if(formData.price){
        if(marketplaceName === 'Poshmark'){ //requires whole numbers
          updates.price = Math.ceil(formData.price); 
        }else{
          updates.price = formData.price
        }        
    }

    if (formData.brand) {
        if(marketplaceName === 'eBay'){
            const brandArray = Array.isArray(formData.brand) ? formData.brand : [formData.brand];
            itemSpecifics['Brand'] = brandArray
        }else if(marketplaceName === 'Mercari'){
          if (formData.brand in mercariBrandConvertObj) {
            updates.brand = mercariBrandConvertObj[formData.brand];
          }else{
            updates.brand = formData.brand
          }
        }else if(marketplaceName === 'Poshmark'){
           if (formData.brand in poshmarkBrandConvertObj) {
              updates.brand = poshmarkBrandConvertObj[formData.brand];
            }else{
              updates.brand = formData.brand
            }
        }else{
            updates.brand = formData.brand;
        }
    }

    if (formData.size) {
        //TODO: better size conversions per marketplace here; ex: Poshmark, Mercari
        if(marketplaceName === 'eBay'){
            const sizeArray = Array.isArray(formData.size) ? formData.size : [formData.size];
            itemSpecifics['Size'] = sizeArray;
        }else if(marketplaceName === 'Poshmark'){
            if (formData.size === "2XL") updates.size = "XXL";
            else updates.size = formData.size;

        }else if(marketplaceName === 'Mercari'){
          //note: moved to MercariSize since size charts are loaded there;
          //due to some Big & Tall, Women's nuances - adjustments are also made here;
          if (formData.size in mercariSizeConvertObj) {
            updates.size = mercariSizeConvertObj[formData.size];
          }else{
            updates.size = formData.size
          }
        }else{
            updates.size = formData.size
        }        
    }


    //TODO: for eBay convert size / material by category
    //Examples: Coats & Jackets uses Outer Material
    //Ex: Waist Pants, Neck Dress Shirts, US Shoe sizes, etc.


    //Poshmark sizeChart
    if(ebayData.itemSpecifics?.['Size Type'] && marketplaceName === 'Poshmark'){
        const sizeTypeConvert = {
          'Regular': 'Standard',
          'Big & Tall': 'Big & Tall',
          'Plus': 'Plus',
          'Petites': 'Petite',
          'Juniors': 'Juniors',
          'Maternity': 'Maternity'
        }

        updates.sizeChart = sizeTypeConvert[ebayData.itemSpecifics['Size Type']] ? sizeTypeConvert[ebayData.itemSpecifics['Size Type']] : updates.sizeChart;

      //eBay
      //WOMEN: Regular, Plus, Petites, Tall, Juniors, Maternity
      //MEN: Regular, Big & Tall

      //Poshmark
      //WOMEN: Standard, Plus, Petite, Juniors, Maternity
      //MEN: Standard, Big & Tall
    }

    //COLORS 
    if(formData.color){
        if(marketplaceName === 'eBay'){
            itemSpecifics['Color'] = [formData.color[0]];
        }else if(marketplaceName === 'Mercari'){
          const mercariAcceptedColors = ['Blue', 'Black', 'White', 'Grey', 'Red', 'Green', 'Brown', 'Orange', 'Beige', 'Yellow', 'Purple', 'Pink', 'Gold', 'Silver'];
    
          const filteredColors = formData.color
              .map(color => color === 'Gray' ? 'Grey' : color) // Change 'Grey' to 'Gray'
              .filter(color => mercariAcceptedColors.includes(color)); // Keep only accepted colors

          if(filteredColors.length > 0){
            updates.color = filteredColors[0];
          }

        }else if(marketplaceName === 'Poshmark'){
          const poshmarkAcceptedColors = ['Blue', 'Black', 'White', 'Gray', 'Red', 'Green', 'Brown', 'Orange', 'Tan', 'Yellow', 'Purple', 'Pink', 'Gold', 'Silver', 'Cream'];
    
          updates.color = formData.color
              .map(color => color === 'Beige' ? 'Tan' : color) // Change 'Beige' to 'Tan'
              .filter(color => poshmarkAcceptedColors.includes(color)); // Keep only accepted colors
        }
    }

    

    //Condition
    if(formData.conditionId){
        if(marketplaceName === 'eBay'){

          console.log('formData.conditionId - ', formData.conditionId);
            if (Number(formData.conditionId) >= 3000 && Number(formData.conditionId) < 4000) {
              updates.conditionId = '3000';
            }else{
              updates.conditionId = formData.conditionId
            }

        }else if(marketplaceName === 'Mercari'){

          if(String(formData.conditionId) === "1000"){
            updates.condition = 'New'
          }else if(String(formData.conditionId) === "3000"){
            updates.condition = 'Like new'
          }else{
            updates.condition = formData.conditionName
          }

        }else if(marketplaceName === 'Poshmark'){
            if(String(formData.conditionId) === '1000'){
                updates.condition = 'nwt';
            }else{
                updates.condition = 'non_nwt';
            }
        }
    }
    


    ////CATEGORY
    if(marketplaceName === 'Poshmark' && Object.keys(ebayData).length > 0){
        const poshmarkCat = ebayCategoryConvert(ebayData, formData, ebayPoshmarkCatConvertObj);
        if(Object.keys(poshmarkCat).length > 0){
            updates.category = poshmarkCat;
        }
    }else if(marketplaceName === 'Mercari' && Object.keys(ebayData).length > 0){
        const mercariCat = ebayCategoryConvert(ebayData, formData, mercariCatConvertObj);
        if(Object.keys(mercariCat).length > 0){
            updates.category = mercariCat;
        }
    }

  console.log('updates - ', updates);
  console.log('itemSpecifics - ', itemSpecifics);
  callback(updates, itemSpecifics); 
};



function updateMarketplaceData(updates, itemSpecifics) {
    if (itemSpecifics && Object.keys(itemSpecifics).length > 0) {
        return {
            ...updates,
            itemSpecifics: {
                ...itemSpecifics
            }
        };
    } else {
        return updates;
    }
}



function ebayCategoryConvert(ebayItem, formData, convertObj) {
    const categoryIdToUse = formData.categoryId || ebayItem.categoryId; //eBay Category ID; also used in General
    const itemSpecifics = ebayItem.itemSpecifics || {}; //TODO: use formData info to build a backup version??
    const titleToUse = formData.title || ebayItem.title || '';

    if(!categoryIdToUse){
        console.log('NO CATEGORY TO USE FOUND');
        return {};
    }

    // //TODO: Fix Sports Function for Mercari/Poshmark
    // if(ebaySportsPoshmarkConvert['categories'].includes(ebayItem.categoryId)){
    //   return sportsCatSpecific(ebayItem)
    // }
  
    let returnObj = { "subCategory": "" };
    let category = convertObj[categoryIdToUse];

    // TODO: category not found handling
    if(typeof category === 'undefined'){
        console.log('CATEGORY MAPPING NOT SET UP YET')
        return {};
    }
  
    returnObj.department = category.department;
    returnObj.category = category.category;
  
    if (category.aspects) {
      Object.keys(itemSpecifics).forEach((aspectName) => {
        const aspectValue = itemSpecifics[aspectName];
        const aspectMappings = category.aspects[aspectName];
        if (Array.isArray(aspectValue)) {
          aspectValue.forEach((value) => {
            if (aspectMappings && aspectMappings[value]) {
              returnObj.subCategory = aspectMappings[value];
            }
          });
        } else {
          if (aspectMappings && aspectMappings[aspectValue]) {
            returnObj.subCategory = aspectMappings[aspectValue];
          }
        }
      });
  
      //NOTE: currently only used for Women's Jackets
      if (category.aspects.TextMatch && titleToUse) {
        const matchedCategory = Object.values(category.aspects.TextMatch).reduce((foundCategory, currentCategory) => {
          const foundIndex = foundCategory ? titleToUse.toLowerCase().indexOf(foundCategory.toLowerCase()) : -1;
          const currentIndex = titleToUse.toLowerCase().indexOf(currentCategory.toLowerCase());
  
          return (currentIndex !== -1 && (foundIndex === -1 || currentIndex < foundIndex)) ? currentCategory : foundCategory;
        }, '');
  
        if (matchedCategory && category.aspects.TextMatch[matchedCategory]) {
          returnObj.subCategory = category.aspects.TextMatch[matchedCategory];
        }
      }
    }else if(category.subCategory){
      returnObj.subCategory = category.subCategory;
    }
  
  
    if(category.default && returnObj.subCategory === ""){
      returnObj.subCategory = category.default;
    }
  
    return returnObj;
}

const ebayPoshmarkCatConvertObj = {
    "63866": {
      department: "Women",
      category: "Sweaters",
      aspects: {
        "Neckline": {
          "Crew Neck": "Crew & Scoop Necks",
          "V-Neck": "V-Necks"
        },
        "Style": {
          "Cardigan": "Cardigans"
        }
      }
    },
    "53159": {
      department: "Women",
      category: "Tops",
      aspects: {
        "Type": {
          "Blouse": "Blouses",
          "Button-Up": "Button Down Shirts",
          "Tank": "Tank Tops",
          "T-Shirt": "Tees - Short Sleeve",
        },
        "Style": {
          "Cropped": "Crop Tops"
        }
      }
    },
    "57988": {
      department: "Men",
      category: "Jackets & Coats",
      aspects: {
        "Type": {
          "Vest": "Vests"
        },
        "Style": {
          "Bomber Jacket": "Bomber & Varsity",
          "Varsity Jacket": "Bomber & Varsity",
          "Military Jacket": "Military & Field",
          "Windbreaker": "Windbreakers",
          "Puffer Jacket": "Puffers",
          "Pea Coat": "Pea Coats",
          "Trench Coat": "Trench Coats",
          "Rain Coat": "Raincoats"
        }
      }
    },
    "181134": {
      //Golf Jacket
      department: "Men",
      category: "Jackets & Coats",
      aspects: {
        "Type": {
          "Vest": "Vests"
        },
        "Style": {
          "Bomber Jacket": "Bomber & Varsity",
          "Basic Jacket": "Performance Jacket",
          "Varsity Jacket": "Bomber & Varsity",
          "Military Jacket": "Military & Field",
          "Windbreaker": "Windbreakers",
          "Windshirt": "Windbreakers",
          "Puffer Jacket": "Puffers",
          "Pea Coat": "Pea Coats",
          "Trench Coat": "Trench Coats",
          "Rain Coat": "Raincoats"
        }
      }
    },
    "63862": {
      department: "Women",
      category: "Jackets & Coats",
      aspects: {
        "Type": {
          "Vest": "Vests"
        },
        "Style": {
          "Bomber Jacket": "Bomber Jackets",
          "Varsity Jacket": "Varsity Jackets",
          "Puffer Jacket": "Puffers",
          "Pea Coat": "Pea Coats",
          "Trench Coat": "Trench Coats"
        },
        "TextMatch": {
          "Blazer": "Blazers & Suit Jackets",
          "Cape": "Capes",
          "Denim": "Jean Jackets",
          "Leather": "Leather Jackets",
          "Ski": "Ski & Snow Jackets",
          "Teddy": "Teddy Jackets",
          "Vest": "Vests",
          "Trench": "Trench Coats"
        }
      }
    },
    "155183": {
      department: "Men",
      category: "Shirts",
      subCategory: "Sweatshirts & Hoodies"
    },
    "57990": {
      department: "Men",
      category: "Shirts",
      subCategory: "Casual Button Down Shirts"
    },
    "15687": {
      department: "Men",
      category: "Shirts",
      aspects: {
        "Sleeve Length": {
          "Short Sleeve": "Tees - Short Sleeve",
          "Long Sleeve": "Tees - Long Sleeve",
          "Sleeveless": "Tank Tops"
        }
      }
    },
    "57991": {
      department: "Men",
      category: "Shirts",
      subCategory: "Dress Shirts"
    },
    "185101": {
      department: "Men",
      category: "Shirts",
      subCategory: "Polos"
    },
    "11483": {
      department: "Men",
      category: "Jeans",
      aspects: {
        "Style": {
          "Bootcut": "Bootcut",
          "Skinny": "Skinny",
          "Straight": "Straight",
        }
      }
    },
    "57989": {
      department: "Men",
      category: "Pants",
      aspects: {
        "Style": {
          "Cargo": "Cargo",
          "Chino": "Chinos & Khakis",
          "Khakis": "Chinos & Khakis",
          "Dress Pants": "Dress",
          "Sweatpants": "Sweatpants & Joggers",
        },
        "Fabric Type": {
          "Corduroy": "Corduroy"
        }
      }
    },
    "15689": {
      // Clothing (Men's) > Shorts
      department: "Men",
      category: "Shorts",
      aspects: {
        "Style": {
          "Cargo": "Cargo",
          "Biker": "Athletic",
          "Sweat": "Athletic"
        },
        "Fabric Type": {
          "Denim": "Jean Shorts"
        },
        "Front Type": {
          "Flat Front": "Flat Front"
        }
      }
    },
    "260957": {
      // Clothing (Men's) > Activewear > Activewear Shorts
      department: "Men",
      category: "Shorts",
      subCategory: "Athletic"
    },
    "260956": {
      // Clothing (Men's) > Activewear > Activewear Pants
      department: "Men",
      category: "Pants"
    },
    "15690": {
      // Clothing (Men's) > Swimwear
      department: "Men",
      category: "Swim",
      aspects: {
        "Type": {
          "Rash Guard": "Rash Guards"
        },
        "Swim Bottom Style": {
          "Trunks": "Swim Trunks",
          "Board Shorts": "Board Shorts"
        }
      }
    },
    "181137": {
    //Golf Pants
      department: "Men",
      category: "Pants",
      aspects: {
        "Style": {
          "Cargo": "Cargo",
          "Chino": "Chinos & Khakis",
          "Khakis": "Chinos & Khakis",
          "Dress Pants": "Dress",
          "Sweatpants": "Sweatpants & Joggers",
        },
        "Fabric Type": {
          "Corduroy": "Corduroy"
        }
      }
    },
    "11554": {
      department: "Women",
      category: "Jeans",
      aspects: {
        "Style": {
          "Skinny": "Skinny",
          "Boyfriend": "Boyfriend",
          "Ankle": "Ankle & Cropped",
          "Cropped": "Ankle & Cropped",
          "Flared": "Flare & Wide Leg",
          "Jegging": "Jeggings",
          "Bootcut": "Boot Cut",
          "Straight": "Straight Leg"
        },
        "Rise": {
          "High (Greater than 10.5 in)": "High Rise"
        }
      }
    },
    "169001": {
      //Clothing (Women's) > Leggings
      department: "Women",
      category: "Pants & Jumpsuits",
      subCategory: "Leggings"
    },
    "63864": {
      department: "Women",
      category: "Skirts",
      aspects: {
        "Style": {
          "A-Line": "A-Line or Full",
          "Skater": "Circle & Skater",
          "Maxi": "Maxi",
          "Mini": "Mini",
          "Straight & Pencil": "Pencil"
        },
        "Skirt Length": {
          "Midi": "Midi"
        }
      }
    },
    "11555": {
      //Clothing > Women's Shorts
      department: "Women",
      category: "Shorts",
      aspects: {
        "Style": {
          "Compression": "Athletic Shorts",
          "Mom": "Jean Shorts",
          "Bermuda": "Bermudas",
          "Biker": "Bike Shorts",
          "Cargo": "Cargos"
        },
        "Fabric Type": {
          "Denim": "Jean Shorts"
        },
        "Rise": {
          "High (Greater than 10.5 in)": "High Waist"
        },
        "Occasion": {
          "Activewear": "Athletic Shorts"
        }
      }
    },
    "177851": {
      department: "Men",
      category: "Shirts",
      subCategory: "Jerseys"
    },
    "155226": {
      department: "Women",
      category: "Tops",
      subCategory: "Sweatshirts & Hoodies"
    },
    "11484": {
      department: "Men",
      category: "Sweaters",
      aspects: {
        "Neckline": {
          "Crew Neck": "Crewneck",
          "V-Neck": "V-Neck",
          "Turtleneck": "Turtleneck"
        },
        "Style": {
          "Cardigan": "Cardigan"
        }
      }
    },
    "185076": {
      //Clothing (Men's) > Activewear > Activewear Tops
      department: "Men",
      category: "Shirts",
      aspects: {
        "Style": {
          "Polo": "Polos"
        },
        "Sleeve Length": {
          "Short Sleeve": "Tees - Short Sleeve",
          "Long Sleeve": "Tees - Long Sleeve",
          "Sleeveless": "Tank Tops"
        }
      }
    },
    "185082": {
      //Clothing (Women's) > Activewear > Activewear Tops
      department: "Women",
      category: "Tops",
      aspects: {
        "Type": {
          "Tank": "Tank Tops"
        },
        "Sleeve Length": {
          "Short Sleeve": "Tees - Short Sleeve",
          "Long Sleeve": "Tees - Long Sleeve"
        }
      }
    },
    "57916":{
      // Clothing (Kid's, Boy's) > Sweatshirts & Hoodies
      department: "Kids",
      category: "Shirts & Tops",
      subCategory: "Sweatshirts & Hoodies"
    },
    "177853": {
    //Sporting Goods > Cycling > Shorts
      department: "Men",
      category: "Shorts",
      subCategory: "Athletic"
    },
    "185079": {
      //Clothing (Women's) > Activewear > Activewear Jackets
      department: "Women",
      category: "Jackets & Coats",
      aspects: {
        "Style": {
          "Varsity Jacket": "Varsity Jackets",
          "Ski Jacket": "Ski & Snow Jackets"
        }
      }
    },
    "185702": {
      //Clothing (Men's) > Activewear > Activewear Jackets
      department: "Men",
      category: "Jackets & Coats",
      aspects: {
        "Style": {
          "Varsity Jacket": "Varsity Jackets",
          "Ski Jacket": "Ski & Snow Jackets"
        }
      }
    },
    "175770": {
      //Clothing > Vintage > Men Casual Shirts
      department: "Men",
      category: "Shirts",
      aspects: {
        "Style": {
          "Button-Front": "Casual Button Down Shirts",
          "Polo, Rugby": "Polos",
          "Hawaiian": "Casual Button Down Shirts",
          "Tank": "Tank Tops",
          "Bowling": "Casual Button Down Shirts"
        }
      }
    },
    "52365": {
      // Clothing (Men's) > Accessories > Hats
      department: "Men",
      category: "Accessories",
      subCategory: "Hats"
    },
    "35843": {
      // Collectibles > Disneyana > T-Shirts
      department: "Women",
      category: "Tops",
      subCategory: "Tees - Long Sleeve"
    },
    "63861": {
      // Clothing (Women's) > Dresses
      department: "Women",
      category: "Dresses",
      aspects: {
        "Style": {
          "Maxi": "Maxi",
          "Mini": "Mini",
        },
        "Dress Length": {
          "Midi": "Midi"
        },
        "Neck Line": {
          "One Shoulder": "One Shoulder"
        },
        "Occasion": {
          "Wedding": "Wedding"
        },
        "Sleeve Length": {
          "Long Sleeve": "Long Sleeve"
        }
      }
    },
    "63863": {
      // Clothing (Women's) > Pants
      department: "Women",
      category: "Pants & Jumpsuits"
    }
};

const ebaySportsPoshmarkConvert = {
    categories: ["24541", "206", "24442", "24510", "24410", "2888", "2887", "2891", "46156", "27291", "2870"],
    conversion: {
      department: "",
      category: "",
      aspects: {
        "Gender": {
          "Women": "Women",
          "Men": "Men",
          "Unisex Adult": "Men",
          "Boys": "Kids",
          "Girls": "Kids",
          "Unisex Children": "Kids"
        },
        "Product": {
          "Jacket": "Jackets & Coats",
          "Sweatshirt": "Shirts",
          "Hoodie": "Shirts",
          "Jersey": "Shirts",
          "Shirt": "Shirts",
          "Cap, Hat": "Accessories",
          "Swimsuit": "Swim",
          "Sweater": "Sweaters",
          "Shorts": "Shorts"
        },
        "SubCategory": {
          "Bomber": "Bomber & Varsity",
          "Varsity": "Bomber & Varsity",
          "Sweatshirt": "Sweatshirts & Hoodies",
          "Hoodie": "Sweatshirts & Hoodies",
          "Puffer": "Puffers",
          "Ski": "Ski & Snowboard",
          "Vest": "Vests",
          "Windbreaker": "Windbreakers",
          "Polo": "Polos",
          "Hawaiian": "Casual Button Down Shirts",
          "T-Shirt": "Tees - Short Sleeve",
          "Jersey": "Jerseys",
          "Button": "Casual Button Down Shirts",
          "Swim Trunks": "Swim Trunks",
          "Athletic": "Athletic"
        }
      }
    }
}

const sportsCatSpecific = (ebayItem) => {
    const { itemSpecifics, title } = ebayItem;
    const { conversion } = ebaySportsPoshmarkConvert; 
  
    let gender = conversion.aspects.Gender[itemSpecifics.Gender] || "";
    let product = conversion.aspects.Product[itemSpecifics.Product] || "";
  
    let subCategory = "";
    for (let keyword in ebaySportsPoshmarkConvert.conversion.aspects["SubCategory"]) {
      if (title.toLowerCase().includes(keyword.toLowerCase())) {
        subCategory = ebaySportsPoshmarkConvert.conversion.aspects["SubCategory"][keyword];
        break;
      }
    }
  
    //Edge Cases
    if (gender === "Women" && product === "Shirts") {
      subCategory = "Tops";
    }
  
    return {
      department: gender,
      category: product,
      subCategory: subCategory
    };
}

const poshmarkBrandConvertObj = {
  'Duluth': 'Duluth Trading Co',
  'Lululemon': 'lululemon athletica',
  'Carbon': 'Carbon 2 Cobalt',
  'Polo Ralph Lauren': 'Polo by Ralph Lauren',
  'J.CREW': 'J. Crew',
  'Under armour': 'Under Armour',
  'Polo Sport': 'Polo by Ralph Lauren',
  'Harry Potter': 'Warner Bros.',
  'Buckle Black': 'Buckle'
}

const mercariSizeConvertObj = {
  '00': 'XXS',
  '0': 'XS',
  '2': 'XS',
  '4': 'S',
  '6': 'S',
  '8': 'M',
  '10': 'M',
  '12': 'L',
  '14': 'L',
  '16': 'XL',
  '18': 'XL',
  '20': '2XL',
  '22': '2XL',
  'MT': 'Tall M',
  'LT': 'Tall L',
  'XLT': 'Tall XL',
  '2XLT': 'Tall XXL',
  '2XL': 'XXL'
}

const mercariBrandConvertObj = {
  'Lululemon': 'lululemon athletica',
  'Duluth': 'Duluth Trading Company'
}

const mercariCatConvertObj = {
  "63866": {
    department: "Women",
    category: "Sweaters",
    aspects: {
      "Neckline": {
        "Collared": "Collared",
        "Cowl Neck": "Cowl neck",
        "Crew Neck": "Crewneck",
        "V-Neck": "V-neck"
      },
      "Style": {
        "Cardigan": "Cardigan",
        "Full Zip": "Full zip",
        "Henley": "Henley",
        "Vest": "Sweater Vests",
        "Tunic": "Tunic",
        "Wrap": "Wrap",
        "Shrug": "Shrug"
      }
    }
  },
  "53159": {
    department: "Women",
    category: "Tops & blouses",
    aspects: {
      "Type": {
        "Blouse": "Blouse",
        "Polo": "Polo Shirt",
        "T-Shirt": "T-shirts",
        "Button-Up": "Button down shirt",
        "Tank": "Tank Tops",
      },
      "Style": {
        "Tunic": "Tunic",
        "Camisole": "Camisoles"
      }
    }
  },
  "57988": {
    department: "Men",
    category: "Coats & jackets",
    aspects: {
      "Type": {
        "Poncho": "Poncho",
        "Vest": "Vest"
      },
      "Style": {
        "Bomber Jacket": "Flight/bomber",
        "Military Jacket": "Military",
        "Motorcycle Jacket": "Motorcycle",
        "Parka": "Parka",
        "Pea Coat": "Peacoat",
        "Puffer Jacket": "Puffer",
        "Rain Coat": "Rainwear",
        "Trench Coat": "Trench",
        "Varsity Jacket": "Varsity/baseball",
        "Windbreaker": "Windbreaker"
      },
      "Fabric Type": {
        "Fleece": "Fleece jacket",
        "Denim": "Jean jacket"
      }
    }
  },
  "181134": {
    //Golf Jacket
    department: "Men",
    category: "Coats & jackets",
    aspects: {
      "Type": {
        "Poncho": "Poncho",
        "Vest": "Vest"
      },
      "Style": {
        "Puffer Jacket": "Puffer",
        "Rain Coat": "Rainwear",
        "Windbreaker": "Windbreaker"
      }
    }
  },
  "63862": {
    department: "Women",
    category: "Coats & jackets",
    aspects: {
      "Type": {
        "Poncho": "Poncho",
        "Vest": "Vest"
      },
      "Style": {
        "Military Jacket": "Military",
        "Motorcycle Jacket": "Motorcycle",
        "Parka": "Parka",
        "Pea Coat": "Peacoat",
        "Puffer Jacket": "Puffer",
        "Rain Coat": "Rainwear",
        "Trench Coat": "Trench",
        "Windbreaker": "Windbreaker"
      },
      "Fabric Type": {
        "Fleece": "Fleece jacket",
        "Denim": "Jean jacket"
      }
    }
  },
  "155183": {
    department: "Men",
    category: "Sweats & hoodies",
    aspects: {
      "Type": {
        "Hoodie": "Hoodie",
        "Sweatshirt": "Sweatshirt, pullover"
      }
    }
  },

  "57990": {
    department: "Men",
    category: "Tops",
    aspects: {
      "Theme": {
        "Hawaiian": "Hawaiian",
      }
    },
    default: 'Button-front'
  },
  "15687": {
    department: "Men",
    category: "Tops",
    aspects: {
      "Sleeve Length": {
        "Sleeveless": "Tank"
      }
    },
    default: "T-shirts"
  },
  "57991": {
    department: "Men",
    category: "Tops",
    subCategory: "Dress shirts"
  },

  "185101": {
    department: "Men",
    category: "Tops",
    aspects: {
      "Performance/Activity": {
        "Rugby": "Rugby Shirts"
      }
    },
    default: "Polos"
  },
  "11483": {
    department: "Men",
    category: "Pants",
    subCategory: "Casual pants"
  },
  "57989": {
    department: "Men",
    category: "Jeans",
    aspects: {
      "Style": {
        "Skinny": "Skinny Jeans",
        "Straight": "Classic, straight leg",
        "Bootcut": "Boot cut"
      },
      "Fit": {
        "Relaxed": "Relaxed",
        "Slim": "Slim Jeans"
      }
    }
  },
  "15689": {
    // Clothing (Men's) > Shorts
    department: "Men",
    category: "Shorts",
    aspects: {
      "Style": {
        "Cargo": "Cargo",
        "Chino": "Khakis, chinos"
      },
      "Fabric Type": {
        "Denim": "Denim"
      }
    }
  },
  "260957": {
    // Clothing (Men's) > Activewear > Activewear Shorts
    department: "Men",
    category: "Shorts",
    subCategory: "Athletic"
  },
  "260956": {
    // Clothing (Men's) > Activewear > Activewear Pants
    department: "Men",
    category: "Pants"
  },
  "15690": {
    // Clothing (Men's) > Swimwear
    department: "Men",
    category: "Swimwear",
    aspects: {
      "Swim Bottom Style": {
        "Trunks": "Swim trunks",
        "Board Shorts": "Board shorts"
      }
    }
  },
  "181137": {
  //Golf Pants
    department: "Men",
    category: "Pants",
    aspects: {
      "Style": {
        "Cargo": "Cargo",
        "Carpenter": "Carpenter",
        "Chino": "Khakis, chinos",
        "Khakis": "Khakis, chinos"
      },
      "Fabric Type": {
        "Corduroy": "Corduroys"
      }
    }
  },
  "11554": {
    department: "Women",
    category: "Jeans",
    aspects: {
      "Style": {
        "Bootcut": "Boot cut",
        "Boyfriend": "Boyfriend",
        "Flared": "Flare",
        "Jegging": "Leggings",
        "Straight": "Straight leg",
        "Wide-Leg": "Wide leg",
        "Skinny": "Skinny Jeans",
        "Capri": "Capri Jeans",
        "Cropped": "Cropped Jeans"
      }
    }
  },
  "169001": {
    //Clothing (Women's) > Leggings
    department: "Women",
    category: "Athletic apparel",
    subCategory: "Athletic Leggings"
  },
  "63864": {
    //TODO: check eBay for more? BELOW this line lazy updates
    department: "Women",
    category: "Skirts",
    aspects: {
      "Style": {
        "A-Line": "A-line",
        "Maxi": "Maxi",
        "Mini": "Mini"
      }
    }
  },
  "11555": {
    //Clothing > Women's Shorts
    department: "Women",
    category: "Shorts",
    aspects: {
      "Style": {
        "Bermuda": "Bermuda",
        "Biker": "Bike",
        "Cargo": "Cargo"
      },
      "Fabric Type": {
        "Denim": "Denim"
      }
    }
  },
  "177851": {
    //Cycling > Tops, Jerseys
    department: "Men",
    category: "Athletic apparel",
    subCategory: "Jerseys"
  },
  "155226": {
    department: "Women",
    category: "Athletic apparel",
    aspects: {
      "Type": {
        "Sweatshirt": "Athletic Sweatshirts",
        "Hoodie": "Athletic Hoodies"
      }
    }
  },
  "11484": {
    department: "Men",
    category: "Sweaters",
    aspects: {
      "Neckline": {
        "Crew Neck": "Crewneck",
        "V-Neck": "V-neck",
        "Turtleneck": "Turtleneck"
      },
      "Style": {
        "Cardigan": "Cardigan",
        "Full Zip": "Full zip",
        "Vest": "Vest"
      }
    }
  },
  "185076": {
    //Clothing (Men's) > Activewear > Activewear Tops
    department: "Men",
    category: "Tops",
    aspects: {
      "Style": {
        "Polo": "Polos"
      }
    },
    default: 'T-shirts'
  },
  "185082": {
    //Clothing (Women's) > Activewear > Activewear Tops
    department: "Women",
    category: "Athletic apparel",
    subCategory: "Athletic T-Shirts"
  },
  "177853": {
  //Sporting Goods > Cycling > Shorts
    department: "Men",
    category: "Athletic apparel",
    subCategory: "Shorts"
  },
  "185079": {
    //Clothing (Women's) > Activewear > Activewear Jackets
    department: "Women",
    category: "Athletic apparel",
    subCategory: "Jackets"
  },
  "185702": {
    //Clothing (Men's) > Activewear > Activewear Jackets
    department: "Men",
    category: "Athletic apparel",
    subCategory: "Jackets"
  },
  "175770": {
    //Clothing > Vintage > Men Casual Shirts
    department: "Men",
    category: "Tops",
    aspects: {
      "Style": {
        "Button-Front": "Button-front",
        "Polo, Rugby": "Polos",
        "Hawaiian": "Hawaiian"
      }
    }
  },
  "52365": {
    // Clothing (Men's) > Accessories > Hats
    department: "Men",
    category: "Men's accessories",
    subCategory: "Hats"
  },
  "35843": {
    // Collectibles > Disneyana > T-Shirts
    department: "Women",
    category: "Tops & blouses",
    subCategory: "T-shirts"
  },
  "63861": {
    // Clothing (Women's) > Dresses
    department: "Women",
    category: "Dresses",
    aspects: {
      "Style": {
        "Maxi": "Maxi",
        "Mini": "Above knee, mini",
      },
      "Dress Length": {
        "Midi": "Midi"
      }
    }
  },
  "63863": {
    // Clothing (Women's) > Pants
    department: "Women",
    category: "Pants",
    aspects: {
      "Style": {
        "Cargo": "Cargo",
        "Khaki": "Khakis, chinos",
        "Capri": "Capri Pants",
        "Cropped": "Cropped Pants"
      },
      "Material": {
        "Leather": "Leather",
        "Linen": "Linen"
      }
    }
  }
};