import { createContext, useEffect, useState, useContext } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    updateProfile,
    sendPasswordResetEmail,
    getIdToken,
} from "firebase/auth";
import { auth } from "../firebase";
import { userAPI } from '../api'

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }){
    const [user, setUser] = useState(null);
    const [token, setToken] = useState("");
    const [tokenExpiration, setTokenExpiration] = useState(null);
    const [userData, setUserData] = useState(null);

    async function signUp(email, password, displayName, inviteCode) {
        console.log('user auth signup')
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            console.log('userCredential - ', userCredential);
            await updateProfile(userCredential.user, {displayName: displayName});
            const token = await getIdToken(userCredential.user);

            const userData = {
                firebaseuid: userCredential.user.uid,
                email: email,
                displayName: displayName,
                inviteCode: inviteCode ? inviteCode : 'NONE'
            };

            const backendUser = await userAPI.createUser(token, userData);
            console.log('backendUser - ', backendUser);

          return { userCredential, backendUser };
        } catch (error) {
          console.error(error);
          throw error;
        }
    }

    async function logIn(email, password){
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const token = await getIdToken(userCredential.user);
            const backendUser = await userAPI.getUser(token); // Fetch user data from backend
            setUserData(backendUser); // Store user data in state
            return userCredential;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    function logOut(){
        setUserData(null)
        return signOut(auth);
    }

    function forgotPassword(email) {
        const actionCodeSettings = {
            url: 'https://thriftallday-37c6b.web.app/login'
        };
        return sendPasswordResetEmail(auth, email, actionCodeSettings);
    }

    async function getValidToken() {
        if (user) {
            try {

                if (token && tokenExpiration && tokenExpiration > Date.now()) {
                    // Token is still valid
                    return token;
                } else {
                    // Token has expired or doesn't exist, get a new one
                    const newToken = await user.getIdToken(user, true);
                    setToken(newToken);
                    setTokenExpiration(Date.now() + 60 * 60 * 1000); // Set expiration to 1 hour from now
                    return newToken;
                }

            } catch (error) {
                console.error('Error getting valid token:', error);
                throw error;
            }
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                const token = await getValidToken();
                setToken(token); 
                if (!userData) { // Only fetch user data if not already fetched
                    const backendUser = await userAPI.getUser(token);
                    setUserData(backendUser);
                }
            } else {
                // Handle user sign-out or no user state
                setToken(""); // Clear the token if there's no user
                setUserData(null);
            }
        });

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <userAuthContext.Provider value={{user, token, userData, signUp, logIn, logOut, forgotPassword, getValidToken}}>
            {children}
        </userAuthContext.Provider>
    )
}


export function useUserAuth(){
    return useContext(userAuthContext)
}