import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthFooter from './AuthFooter';
import { HourglassSplit } from 'react-bootstrap-icons';
import { useUserAuth } from '../../context/UserAuthContext';
import { userAPI } from '../../api';

function OnboardWaitlist() {
    const { user } = useUserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = await user.getIdToken(true);
                const userData = await userAPI.getUser(token);
                console.log('fetchUserData userData - ', userData);
                if (userData.betaAccess) {
                    navigate('/onboard/setup');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        if(user){
            fetchUserData();
        }
    }, [user]);

    return (
        <div id="layoutAuthentication" className="bg-primary d-flex flex-column justify-content-center">
          <div id="layoutAuthentication_content">
            <main>
              <div className="container-xl px-4">
                <div className="row justify-content-center">
      
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-11 mt-4">
                    <div className="card text-center h-100 d-flex flex-column justify-content-center">
                      <div className="card-body px-5 pt-5">
                        <h3 className="h3 text-primary">Waitlist</h3>
                        <p className="text-muted mb-4">You're on the list! We'll notify you as soon as it's your turn.</p>
                        <div className="icons-org-create align-items-center mx-auto">
                            <HourglassSplit />
                        </div>
                      </div>
                    </div>
                  </div>
      
                </div>
              </div>
            </main>
          </div>
      
          <AuthFooter />
        </div>
    );
      
}

export default OnboardWaitlist;
