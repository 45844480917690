import React, { useState, useEffect, useRef } from 'react';
import { useUserAuth } from '../../../context/UserAuthContext';
import { templateAPI } from '../../../api';

const EbayCondition = ({ categoryData, ebayData, setEbayData }) => {
  const conditionChangedRef = useRef(false);
  const { user } = useUserAuth();
  const [conditionOptions, setConditionOptions] = useState([]);

  useEffect(() => {
    const fetchConditionData = async () => {
      if (categoryData && categoryData.hasOwnProperty('categoryId') && !categoryData.condition) {
        try {
          const token = await user.getIdToken(true);
          const conditionData = await templateAPI.getCategoryConditionData(categoryData.categoryId, token);
          console.log('conditionData - ', conditionData);
          setConditionOptions(conditionData); 
        } catch (error) {
          console.error("Error fetching condition data:", error);
        }
      } 
    };

    fetchConditionData();
  }, [categoryData, user]);
  
  const handleConditionClick = (clickedConditionId) => {
    setEbayData(prevData => {
      if (prevData.conditionId === clickedConditionId) {
        return { ...prevData, conditionId: null };
      } else {
        return { ...prevData, conditionId: clickedConditionId };
      }
    });
    conditionChangedRef.current = true;
  };

  const handleConditionDescription = (descriptionValue) => {
    setEbayData(prevData => ({
      ...prevData,
      conditionDescription: descriptionValue
    }));
  };
  

  return (
    <div className="card mb-2" id="ebayCondition">
      <div className="card-header">Condition Details</div>
      <div className="card-body">
        
        <div className="d-flex flex-wrap">
          {conditionOptions.length > 0 ? (
            conditionOptions.map(condition => (
              <div key={condition.ID} style={{marginRight: '4px'}}>
                <button 
                  className={`btn btn-outline-primary ${String(ebayData.conditionId) === String(condition.ID) ? 'active' : ''}`} 
                  onClick={() => handleConditionClick(String(condition.ID))}
                >
                  {condition.DisplayName}
                </button>
              </div>
            ))
          ) : (
            <p className="card-text">
              Select a category first to see Condition options.
            </p>
          )}
        </div>

        <div className="mt-2">
            <textarea 
                id="conditionDescription" 
                className="form-control" 
                value={String(ebayData.conditionId) === '1000' ? "" : ebayData.conditionDescription} 
                onChange={(e) => handleConditionDescription(e.target.value)}
                disabled={String(ebayData.conditionId) === '1000'} // Disable textarea if condition ID is 1000
                placeholder={String(ebayData.conditionId) === '1000' ? "Condition description not used for New" : "Condition Description"}
            />
        </div>


      </div>
    </div>
  );
};

export default EbayCondition;
