import React, { useState } from 'react';
import ResetButton from './ResetButton';
import SingleSuggestButton from '../../Helper/SingleSuggestButton';

//TODO: on size select, also place item specifics (fields that are not size, sizeDisplay, etc.)
//ex: sizeType, waistSize

//UPDATE SIZE Chart when category is selected
//need categoryData as it loaded for the size option & choose correct sizeChart

//trigger NEW

const CustomSizeInput = ({ onSizeSelect }) => {
    const [customSize, setCustomSize] = useState('');

    const handleCustomSizeChange = (e) => {
        setCustomSize(e.target.value);
    };

    const handleCustomSizeSelect = () => {
        if (customSize.trim() !== '') {
            onSizeSelect(customSize);
            setCustomSize(''); //reset field
        }
    };

    return (
        <div className="input-group">
            <input 
                type="text" 
                className="form-control" 
                placeholder="Custom Size"
                value={customSize}
                onChange={handleCustomSizeChange} 
            />
            <button 
                className="btn btn-outline-primary" 
                type="button"
                onClick={handleCustomSizeSelect}
            >
                &gt; 
            </button>
        </div>
    );
};


const sizeChartWomen = [
    { size: '0', sizeDisplay: '0', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '2', sizeDisplay: '2', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '4', sizeDisplay: '4', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '6', sizeDisplay: '6', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '8', sizeDisplay: '8', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '10', sizeDisplay: '10', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '12', sizeDisplay: '12', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '14', sizeDisplay: '14', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '16', sizeDisplay: '16', sizeType: 'Regular', quickSelect: 'row1' },

    { size: 'XS', sizeDisplay: 'XS', sizeType: 'Regular', quickSelect: 'row2'},
    { size: 'S', sizeDisplay: 'Small', sizeType: 'Regular', quickSelect: 'row2' },
    { size: 'M', sizeDisplay: 'Medium', sizeType: 'Regular', quickSelect: 'row2' },
    { size: 'L', sizeDisplay: 'Large', sizeType: 'Regular', quickSelect: 'row2' },
    { size: 'XL', sizeDisplay: 'XL', sizeType: 'Regular', quickSelect: 'row2' },

    { size: '2XL', sizeDisplay: '2XL', sizeType: 'Plus', dropdown: true },
    { size: '3XL', sizeDisplay: '3XL', sizeType: 'Plus', dropdown: true },
    { size: '18', sizeDisplay: '18', sizeType: 'Plus', dropdown: true },
    { size: '20', sizeDisplay: '20', sizeType: 'Plus', dropdown: true },
    { size: '22', sizeDisplay: '22', sizeType: 'Plus', dropdown: true },
    { size: '18W', sizeDisplay: '18W', sizeType: 'Plus', dropdown: true },
    { size: '20W', sizeDisplay: '20W', sizeType: 'Plus', dropdown: true },
    { size: '22W', sizeDisplay: '22W', sizeType: 'Plus', dropdown: true },
    { size: '1X', sizeDisplay: '1X', sizeType: 'Plus', dropdown: true },
    { size: '2X', sizeDisplay: '2X', sizeType: 'Plus', dropdown: true },
    { size: '3X', sizeDisplay: '3X', sizeType: 'Plus', dropdown: true },
    { size: '4X', sizeDisplay: '4X', sizeType: 'Plus', dropdown: true }

];

const sizeChartPants = [
    { size: '28', sizeDisplay: '28', sizeType: 'Regular', waistSize: '28 in', quickSelect: 'row1'},
    { size: '29', sizeDisplay: '29', sizeType: 'Regular', waistSize: '29 in', quickSelect: 'row1'},
    { size: '30', sizeDisplay: '30', sizeType: 'Regular', waistSize: '30 in', quickSelect: 'row1'},
    { size: '31', sizeDisplay: '31', sizeType: 'Regular', waistSize: '31 in', quickSelect: 'row1'},
    { size: '32', sizeDisplay: '32', sizeType: 'Regular', waistSize: '32 in', quickSelect: 'row1'},
    { size: '33', sizeDisplay: '33', sizeType: 'Regular', waistSize: '33 in', quickSelect: 'row1'},
    { size: '34', sizeDisplay: '34', sizeType: 'Regular', waistSize: '34 in', quickSelect: 'row1'},
    { size: '35', sizeDisplay: '35', sizeType: 'Regular', waistSize: '35 in', quickSelect: 'row1'},
    { size: '36', sizeDisplay: '36', sizeType: 'Regular', waistSize: '36 in', quickSelect: 'row1'},
    { size: '38', sizeDisplay: '38', sizeType: 'Regular', waistSize: '38 in', quickSelect: 'row1'},
    { size: '40', sizeDisplay: '40', sizeType: 'Regular', waistSize: '40 in', quickSelect: 'row1'},

    { size: 'XS', sizeDisplay: 'XS', sizeType: 'Regular', quickSelect: 'row2'},
    { size: 'S', sizeDisplay: 'Small', sizeType: 'Regular', quickSelect: 'row2' },
    { size: 'M', sizeDisplay: 'Medium', sizeType: 'Regular', quickSelect: 'row2' },
    { size: 'L', sizeDisplay: 'Large', sizeType: 'Regular', quickSelect: 'row2' },
    { size: 'XL', sizeDisplay: 'XL', sizeType: 'Regular', quickSelect: 'row2' },
    { size: '2XL', sizeDisplay: '2XL', sizeType: 'Regular', quickSelect: 'row2' },
    { size: '3XL', sizeDisplay: '3XL', sizeType: 'Regular', quickSelect: 'row2' },

];

const sizeChartStandard = [
    { size: 'XS', sizeDisplay: 'XS', sizeType: 'Regular', quickSelect: 'row1'},
    { size: 'S', sizeDisplay: 'Small', sizeType: 'Regular', quickSelect: 'row1' },
    { size: 'M', sizeDisplay: 'Medium', sizeType: 'Regular', quickSelect: 'row1' },
    { size: 'L', sizeDisplay: 'Large', sizeType: 'Regular', quickSelect: 'row1' },
    { size: 'XL', sizeDisplay: 'XL', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '2XL', sizeDisplay: '2XL', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '3XL', sizeDisplay: '3XL', sizeType: 'Regular', quickSelect: 'row1' },
    { size: '4XL', sizeDisplay: '4XL', sizeType: 'Regular' },
    { size: '5XL', sizeDisplay: '5XL', sizeType: 'Regular' },
    { size: '6XL', sizeDisplay: '6XL', sizeType: 'Regular' },
  
    { size: 'MT', sizeDisplay: 'Medium Tall', sizeType: 'Big & Tall', dropdown: true },
    { size: 'LT', sizeDisplay: 'Large Tall', sizeType: 'Big & Tall', dropdown: true },
    { size: 'XLT', sizeDisplay: 'XLT', sizeType: 'Big & Tall', dropdown: true },
    { size: '2XLT', sizeDisplay: '2XLT', sizeType: 'Big & Tall', dropdown: true },
    { size: '3XLT', sizeDisplay: '3XLT', sizeType: 'Big & Tall', dropdown: true },
    { size: '4XLT', sizeDisplay: '4XLT', sizeType: 'Big & Tall', dropdown: true },
    { size: '5XLT', sizeDisplay: '5XLT', sizeType: 'Big & Tall', dropdown: true },
    { size: '6XLT', sizeDisplay: '6XLT', sizeType: 'Big & Tall', dropdown: true },
  
    { size: 'Big 1X', sizeDisplay: '1XB', sizeType: 'Big & Tall', dropdown: true },
    { size: 'Big 2X', sizeDisplay: '2XB', sizeType: 'Big & Tall', dropdown: true },
    { size: 'Big 3X', sizeDisplay: '3XB', sizeType: 'Big & Tall', dropdown: true },
    { size: 'Big 4X', sizeDisplay: '4XB', sizeType: 'Big & Tall', dropdown: true },
    { size: 'Big 5X', sizeDisplay: '5XB', sizeType: 'Big & Tall', dropdown: true },
    { size: 'Big 6X', sizeDisplay: '6XB', sizeType: 'Big & Tall', dropdown: true }
];

const FormSize = ({ formDataSize = '', setFormData, categorySizeCharts, sizeChart = sizeChartStandard }) => {
    const quickSelectRow1 = sizeChart.filter(item => item.quickSelect === 'row1');
    const quickSelectRow2 = sizeChart.filter(item => item.quickSelect === 'row2');

    const handleSizeSelect = (size) => {
        if(formDataSize === size){
            handleResetSize();
        }else{
            setFormData(prevData => ({
                ...prevData,
                size: size
            }));
        }
    };

    const handleResetSize = () => {
        setFormData(prevData => {
            const updatedData = { ...prevData };
            delete updatedData.size; 
            return updatedData;
        });
    };

    const renderSizeButtons = (sizeRow) => {
        const buttonStyle = sizeRow.length > 8 ? { padding: '6px' } : {};

        return (
            <div className="btn-group radio-size mb-1" role="group" aria-label="Size selection">
                {sizeRow.map((size, index) => (
                    <button
                        key={index}
                        style={buttonStyle}
                        className={`btn ${formDataSize === size.size ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => handleSizeSelect(size.size)}
                    >
                        {size.size}
                    </button>
                ))}
            </div>
        )
    }

    const renderDropdownSize = ({ sizeChart }) => {
        const dropdownItems = sizeChart.filter(item => item.dropdown);

        if (dropdownItems.length === 0) {
            return <></>;
        }
        const dropdownLabel = dropdownItems.length > 0 ? dropdownItems[0].sizeType : '';

        const handleDropdownItemClick = (sizeValue) => {
            setFormData(prevFormData => ({
                ...prevFormData,
                size: sizeValue
            }));
        };
    
        return (
            <div className="dropdown">
                <button className="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    {dropdownLabel}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {dropdownItems.map((item, index) => (
                        <li key={index}>
                            <a className="dropdown-item" href="#" onClick={(e) => {
                                e.preventDefault();
                                handleDropdownItemClick(item.size);
                            }}>{item.sizeDisplay}</a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };    

    return (
        <div style={{ width: '100%' }}>
            <div className="mb-2">
                <label htmlFor="itemSize" className="form-label" style={{ fontSize: '1.2em' }}>Size</label>
                <ResetButton onReset={handleResetSize} />
            </div>


            {
                formDataSize !== '' && 
                <>
                    <span style={{ marginRight: '3px' }}>Selected:</span>
                    <SingleSuggestButton 
                        value={formDataSize}
                        selected="true"
                        specific="size"
                        setSelectedSpecifics={() => {
                            setFormData(prevFormData => ({
                                ...prevFormData,
                                size: ''
                            }));
                        }}
                    />
                </>
                
            }

            <div className="row">
                <div className="col">
                    {renderSizeButtons(quickSelectRow1)}
                </div>
            </div>
            {quickSelectRow2.length > 0 && (
                <div className="row">
                    <div className="col">
                        {renderSizeButtons(quickSelectRow2)}
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col-md-6">
                    <CustomSizeInput onSizeSelect={handleSizeSelect} />
                </div>
                <div className="col-md-6">
                    {renderDropdownSize({ sizeChart: sizeChart })}
                </div>
            </div>
        </div>
    );
};

export default FormSize;
