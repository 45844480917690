import React, {useState, useEffect} from 'react';
import MarketplaceCard from './MarketplaceCard';
import PhotoPreview from './FormSimple/PhotoPreview';
import EbayCategory from './FormSimple/EbayCategory';
import MarketplaceTitle from './FormSimple/MarketplaceTitle';
import ItemSpecificContainer from './ItemSpecificContainer';
import EbayCondition from './FormSimple/EbayCondition';
import MarketplaceDescription from './FormSimple/MarketplaceDescription';
import ListingDetails from './ListingDetails';
import PricingDetails from './PricingDetails';

import { useUserAuth } from '../../context/UserAuthContext';
import { userAPI, listingAPI } from '../../api';
import { updateMarketplaceStatus } from '../../utils/updateStatus';
import { translateData } from '../../utils/translate';

const CrossListEbay = ({formData, setFormData, ebayData, setEbayData, templates, categoryData, categoryDataFetched, setActiveMarketplace}) => {
    const { user } = useUserAuth();
    const [storeData, setStoreData] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' });

    useEffect(() => {

        //before running, ensure we are not in formData.mode

        if(ebayData.id){
            console.log('ID found, skipping translate');
            return;
        }

        translateData(formData, ebayData, (updatedData, itemSpecificsData) => {
            setEbayData(prevData => {
                const hasItemSpecifics = Object.keys(itemSpecificsData).length > 0;
                const newState = {
                    ...prevData,
                    ...updatedData,
                    utils: { ...prevData.utils, translated: true }
                };

                newState.itemSpecifics = {
                    ...templates?.updatedItemSpecifics || {}, // Lowest priority
                    ...prevData.itemSpecifics, // Higher priority
                    ...(hasItemSpecifics ? itemSpecificsData : {}) // Highest priority
                };

                console.log('newState.itemSpecifics - ', newState.itemSpecifics);
        
                return newState;
            });

        }, 'eBay');

    }, []); 

    useEffect(() => {
        const fetchStoreData = async (retries = 0) => {
            try {
                const token = await user.getIdToken(true);
                const storeDataResult = await userAPI.getStoreData(token);
                setStoreData(storeDataResult);
            } catch (error) {
                console.error("Error fetching data:", error);
                if (retries < 2) {
                    // Retry fetching data after a delay
                    setTimeout(() => fetchStoreData(retries + 1), 1000);
                } else {
                    //TODO: error to user 
                }
            }
        };
    
        if (Object.keys(storeData).length === 0) {
            fetchStoreData();
        }
    }, []);

    const validateSubmitEbay = () => {
        // NOTE: currently does not validate that condition is chosen when required;

        let updatedEbayData = ebayData;

        if(ebayData?.packageDetails?.weightMajor && !ebayData?.packageDetails?.weightMinor){
            setEbayData(prevEbayData => {
                updatedEbayData = {
                    ...prevEbayData,
                    packageDetails: {
                        ...prevEbayData.packageDetails,
                        weightMinor: 0
                    }
                };
                
                return updatedEbayData;
              });
        }

        const requiredFields = [
            'conditionId', 'locationPostalCode', 'description', 'listingType',
            { key: 'packageDetails', fields: ['weightMinor', 'packageWidth', 'packageLength', 'packageDepth'] },
            { key: 'sellerProfiles', fields: ['fulfillmentPolicy', 'paymentPolicy', 'returnPolicy'] }
        ];

        const fieldNameConvert = {
            'conditionId': 'Condition Display Name (New, Pre-Owned)',
            'listingType': 'Listing Type (Buy it Now, Auction)'
        }

        function splitCamelCaseToWords(str) {
            return str
                // Insert a space before all caps
                .replace(/([A-Z])/g, ' $1')
                // Uppercase the first character
                .replace(/^./, (str) => str.toUpperCase());
        }
        
        // Validate required fields
        for (let field of requiredFields) {
            if (typeof field === 'string') {
                if (!updatedEbayData[field]) {
                    let fieldName = fieldNameConvert[field] ? fieldNameConvert[field] : splitCamelCaseToWords(field);
                    setAlert({ type: 'warning', message: `The field ${fieldName} is required.` });
                    return; 
                }
            } else if (typeof field === 'object' && updatedEbayData[field.key]) {
                for (let nestedField of field.fields) {
                    if (updatedEbayData[field.key][nestedField] !== 0 && !updatedEbayData[field.key][nestedField]) {
                        setAlert({ type: 'warning', message: `The field ${nestedField} is required.` });
                        return; 
                    }
                }
            }
        }

        // Validate category specifics
        if (categoryData?.categorySpecifics) {
            for (let specific of categoryData.categorySpecifics) {
                if (specific.rules.required && (!ebayData.itemSpecifics || !ebayData.itemSpecifics[specific.name])) {
                    setAlert({ type: 'warning', message: `The specific ${specific.name} is required.` });
                    return; 
                }
            }
        }

        // Check if images are still uploading
        if (formData.utils.imageUploadFinished === false){
            setAlert({ type: 'warning', message: 'Your images are still uploading. Please wait a few moments & try again.' });
            return;
        }

        if (updatedEbayData.packageDetails && updatedEbayData.packageDetails.weightMinor) {
            // Check if weightMinor is between 0 and 15
            const weight = Number(updatedEbayData.packageDetails.weightMinor);
            if (weight < 0 || weight > 15) {
                setAlert({ type: 'warning', message: 'Weight Minor must be between 0 and 15.' });
                return; 
            }
        }

        // Check if at least one picture is uploaded
        if(!ebayData.pictures || ebayData.pictures.length === 0){
            setAlert({ type: 'warning', message: 'Upload at least 1 photo.' });
            return;
        }

        // Validate title: it should exist, be at least 1 character long, and less than 80 characters
        if (!ebayData.title || ebayData.title.length < 1 || ebayData.title.length > 80) {
            setAlert({ type: 'warning', message: 'Title must be between 1 and 80 characters.' });
            return;
        }

        return true;
    }   

    const handleSubmitEbay = async () => {
        console.log('ebayData submit - ', ebayData);
        setSubmitLoading(true);
        try {
            if (!validateSubmitEbay()) {
                setSubmitLoading(false); 
                return; 
            }
            updateMarketplaceStatus('eBay', 'submitting...', setFormData);

            const token = await user.getIdToken(true);
            const responseData = await listingAPI.createEbaySubmit(token, ebayData, formData);

            console.log('LISTING API response - ', responseData);
            setAlert({ message: '', type: '' }); //reset any previous alerts from errors
            if(responseData?.marketplaceListingData?.marketplaceListingId){
                console.log('Update Marketplace Status to Active')
                updateMarketplaceStatus('eBay', 'active', setFormData, responseData?.marketplaceListingData?.marketplaceListingId, true);
            }

            if (responseData?.marketplaceListingData) {
                let updates = {};
                if (responseData.marketplaceListingData._id) { updates.id = responseData.marketplaceListingData._id; }
                if (responseData.marketplaceListingData.marketplaceListingId) { updates.marketplaceListingId = responseData.marketplaceListingData.marketplaceListingId; }
                if (responseData.marketplaceListingData.generalListing){ updates.generalListing = responseData.marketplaceListingData.generalListing; }
                if (responseData.marketplaceListingData.status){ updates.status = responseData.marketplaceListingData.status; }
                if (Object.keys(updates).length > 0) {
                  setEbayData(prevEbayData => ({
                    ...prevEbayData,
                    ...updates
                  }));
                }
            }

            if(!formData.id && responseData?.generalListingData?._id){
                setFormData(prevFormData => ({
                    ...prevFormData,
                    id: responseData.generalListingData._id
                }));
            }
              
        } catch (err) {
            console.log('err - ', err);
            updateMarketplaceStatus('eBay', 'error', setFormData);
            setAlert({message: `Error in saving: ${err.response?.data?.message}`, type: 'danger'});
        } finally {
            setSubmitLoading(false); 
        }
    }



    return(
        <MarketplaceCard 
            formData={formData}
            setFormData={setFormData}
            marketplaceData={ebayData}
            setMarketplaceData={setEbayData}
            marketplaceName="eBay"
            handleSubmitMarketplace={handleSubmitEbay}
            submitLoading={submitLoading}
            alert={alert}
            setAlert={setAlert}
            setActiveMarketplace={setActiveMarketplace}
        >
            <div className="row">
                <div className="col-sm-6">
                    <PhotoPreview
                        formDataPictures={formData.pictures}
                        marketplaceDataPictures={ebayData.pictures || []}
                        setMarketplaceData={setEbayData}
                        marketplaceName="eBay"
                        maxPhotos={24}
                    />
                </div>
                <div className="col-sm-6">
                    <EbayCategory
                        formDataCategoryId={formData.categoryId}
                        formDataCategoryName={formData.categoryName}
                        ebayData={ebayData}
                        setEbayData={setEbayData}
                    />
                </div>
            </div>

            <MarketplaceTitle 
                marketplaceDataTitle={ebayData.title}
                setMarketplaceData={setEbayData}
                marketplaceName="eBay"
            />
            <ItemSpecificContainer 
                categoryData={categoryData}
                formDataItemSpecifics={ebayData.itemSpecifics}
                formDataSuggestSpecifics={ebayData?.utils?.suggestSpecifics || {}}
                setFormData={setEbayData}
                templates={templates}
            />

            {/* Update Condition to only pass in condition ID & description? */}
            <EbayCondition
                categoryData={categoryData}
                ebayData={ebayData}
                setEbayData={setEbayData}
            />
            <MarketplaceDescription
                marketplaceDataDescription={ebayData.description}
                setMarketplaceData={setEbayData}
                marketplaceName="eBay"
            />
            <ListingDetails 
                formData={ebayData}
                setFormData={setEbayData}
                storeData={storeData}
            />
            <PricingDetails
                formData={ebayData}
                setFormData={setEbayData}
                storeData={storeData}
            />


        </MarketplaceCard>
    )
}

export default CrossListEbay;