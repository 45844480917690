import React, { useState, useEffect, useRef } from 'react';
import { storage } from '../../firebase'; // Import storage from firebase.js
import { ref, uploadBytesResumable, getDownloadURL, getStorage, deleteObject } from 'firebase/storage';
import { StarFill, Trash, Upload } from 'react-bootstrap-icons';
import { useUserAuth } from '../../context/UserAuthContext';
import { imageAPI } from '../../api';
import ModalChangeOrder from './ModalChangeOrder';

// NOTE: text extract and barcode reader removed in favor of AI analysis. File needs to be cleaned up.

const ImagePreviewModal = ({ show, onClose, imageUrl }) => {

    return (
        <>
            {show && <div className="modal-backdrop show"></div>}
            <div className={`modal ${show ? 'show d-block' : ''}`} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Image Preview</h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body">
                            <img src={imageUrl} alt="Preview" className="img-fluid" />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const ImageSimple = ({ imageData, setImageData, formData, setFormData, setEbayData }) => {
    const imageRefs = useRef([]);
    const [progress, setProgress] = useState(0); //currently not used - just spinner on upload
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState('');
    const [alert, setAlert] = useState({ message: '', type: '' });
    const hasLoadedImages = useRef(false);
    const { user } = useUserAuth(); 
    const imagesAISet = useRef(false);
    const processedImagesRef = useRef(new Set());

    useEffect(() => {
        imageData.forEach((data, index) => {
            if (data.firebaseURL && imageRefs.current[index] && !processedImagesRef.current.has(data.blobURL)) {
                processedImagesRef.current.add(data.blobURL); //setting early to prevent multiple
                const imgElement = imageRefs.current[index];
                const imageToLoad = new Image();
                imageToLoad.src = data.firebaseURL;
                imageToLoad.onload = () => {
                    imgElement.src = data.firebaseURL;
                    URL.revokeObjectURL(data.blobURL);
                };
            }
        });
    }, [imageData]); 
    
    useEffect(() => {
        //TODO: for imported/existing, do not do this
        // && formData.utils?.mode !== 'loaded' (did not work)

        // Set the first 2 images as AI assist on 1st load
        if(imageData.length > 0){
            if (!imagesAISet.current && imageData.length > 0) {

                setImageData(prevData => {
                    return prevData.map((data, index) => {
                        if (index === 0 || index === 1) {
                        return { ...data, aiAssist: true };
                        }
                        return data;
                    });
                });
                imagesAISet.current = true;
            }
        }
    }, [imageData]);

    // load in images only in Draft Mode
    useEffect(() => {
        if (!hasLoadedImages.current && formData.utils?.mode === 'draft') {
            if (formData.pictures?.length > 0) {
                const transformedData = formData.pictures.map(imageObj => ({
                    blobURL: imageObj.imageUrl,
                    firebaseURL: imageObj.imageUrl,
                    _id: imageObj._id
                }));
                console.log('Loading images:', { transformedData, formData });
                setImageData(transformedData);
            } else {
                console.log('Draft with no pictures');
            }
            hasLoadedImages.current = true;
        }
    }, [formData, setImageData]); 

    useEffect(() => {
        
        const saveImagesToDB = async () => {
            console.log('imageData - ', imageData);

            const imagesToSave = imageData.filter(imageObj => {
                const idStartsWithImage = imageObj._id && imageObj._id.startsWith('image-');
                const firebaseURLExists = !!imageObj.firebaseURL;
                const blobURLDifferentFromFirebaseURL = imageObj.blobURL !== imageObj.firebaseURL;

                return idStartsWithImage && firebaseURLExists && blobURLDifferentFromFirebaseURL;
            }).map(item => item.firebaseURL.split('&token=')[0]);

            console.log('imagesToSave - ', imagesToSave);


            if (imagesToSave.length > 0) {
                try {
                    const token = await user.getIdToken(true);
                    const dataToSend = {
                        images: imagesToSave,
                        generalListingId: formData.utils?.generalListingId ?? null
                    };
                    const imageResult = await imageAPI.bulkCreate(token, dataToSend);

                    console.log('imageResult - ', imageResult);

                    setImageData(prevData => {
                        return prevData.map(data => {
                            const cleanFirebaseURL = data.firebaseURL.split('&token=')[0];
                            const savedImage = imageResult.find(result => {
                                const cleanResultUrl = result.imageUrl.split('&token=')[0];
                                return cleanResultUrl === cleanFirebaseURL;
                            });
                            return savedImage ? { ...data, firebaseURL: cleanFirebaseURL, _id: savedImage._id } : data;
                        });
                    });
 
                } catch (error) {
                    console.error('Error in saving images: ', error);
                }
            }
        };

        const allUploaded = imageData.every(data => data.firebaseURL);
        const hasPlaceholderIds = imageData.some(data => data._id && data._id.startsWith('image-'));

        if (allUploaded) {
            setFormData(prevFormData => ({
                ...prevFormData, 
                utils: {
                    ...prevFormData.utils, 
                    imageUploadFinished: true 
                }
            }));
        }

        if(allUploaded && hasPlaceholderIds){
            saveImagesToDB();
        }

        checkAndUpdateFormData();         
    }, [imageData]); 

    const checkAndUpdateFormData = (imageDataSource = imageData) => {
        let differencesFound = false;
    
        const updatedPictures = [];
        imageDataSource.forEach(data => {
            const matchingPicture = formData.pictures?.find(picture => 
                picture.imageUrl === data.firebaseURL || picture._id === data._id
            );
    
            if (!matchingPicture) {
                // If there's no matching picture, it's a new item to add
                differencesFound = true;
                updatedPictures.push({ imageUrl: data.firebaseURL, _id: data._id });
            } else if (matchingPicture.imageUrl !== data.firebaseURL || matchingPicture._id !== data._id) {
                // If there's a mismatch, update the existing item
                differencesFound = true;
                updatedPictures.push({ ...matchingPicture, imageUrl: data.firebaseURL, _id: data._id });
            } else {
                // If it matches exactly, keep the existing item as is
                updatedPictures.push(matchingPicture);
            }
        });
    
        const existingPictureUrls = imageDataSource.map(data => data.firebaseURL);
        formData.pictures?.forEach(picture => {
            if (!existingPictureUrls.includes(picture.imageUrl)) {
                // If a picture in formData.pictures is not in the updated list, it's been removed
                differencesFound = true;
            }
        });
    
        // Update formData if differences were found
        if (differencesFound) {
            setFormData(prevFormData => ({
                ...prevFormData,
                pictures: updatedPictures
            }));
        }
    };
    
    const handleChange = (e) => {
        if (e.target.files) {
            let selectedImages = [...e.target.files];
            if (selectedImages.length === 0) {
                setAlert({message: 'Please select images to upload.', type: 'danger'})
                return;
            }

            const availableSlots = 24 - imageData.length;
            if (selectedImages.length > availableSlots) {
                setAlert({ 
                    message: 'Only the first 24 images will be processed due to the limit. Delete images & re-upload if you want different ones.', 
                    type: 'warning'
                });
                selectedImages = selectedImages.slice(0, availableSlots);
            }

            const validImages = selectedImages.filter(file => file.size <= 5 * 1024 * 1024); // 5MB in bytes
            if (validImages.length < selectedImages.length) {
                setAlert({
                    message: 'Some images were too large and have not been included. Maximum size is 5MB per image.',
                    type: 'warning'
                });
            }

            const imageDataArray = selectedImages.map((file, index) => ({
                _id: `image-${Date.now()}-${index}`, // Generate _id here
                blobURL: URL.createObjectURL(file),
                firebaseURL: null
            }));
    
            setImageData(prevData => [...prevData, ...imageDataArray]);
            handleUpload(selectedImages, imageDataArray);
        }
    };

    const handleUpload = async (images, localDataArray) => {

        setFormData(prevData => {
            const updatedUtils = prevData.utils ? { ...prevData.utils } : {};
            updatedUtils.imageUploadFinished = false;
            return {
                ...prevData,
                utils: updatedUtils
            };
        });

        const uploadWithRetry = async (image, localData, index, retries = 3) => {
            try {
                const timestamp = Date.now();
                const newImageName = `${timestamp}-${image.name}`;
                const storageRef = ref(storage, `users/${user.uid}/uploads/${newImageName}`);
                const uploadTask = uploadBytesResumable(storageRef, image);

                await new Promise((resolve, reject) => {
                    let lastProgress = 0;
                    let progressCheck;
                
                    const restartUpload = () => {
                        console.log("Restarting upload due to no progress");
                        clearTimeout(progressCheck);
                        if (retries > 0) {
                            uploadWithRetry(image, localData, index, retries - 1);
                        } else {
                            reject(new Error("Upload failed after multiple retries"));
                        }
                    };
                
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const progress = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
                            setProgress(progress);
                
                            // Reset the progress check timer if progress is made
                            if (progress > lastProgress) {
                                clearTimeout(progressCheck);
                                progressCheck = setTimeout(restartUpload, 4000); // 10 seconds for no progress
                                lastProgress = progress;
                            }
                        },
                        (error) => {
                            console.log(error);
                            clearTimeout(progressCheck);
                            if (retries > 0) {
                                setTimeout(() => {
                                    uploadWithRetry(image, localData, index, retries - 1);
                                }, 2000); // Retry after 2 seconds
                            } else {
                                reject(error);
                            }
                        },
                        async () => {
                            clearTimeout(progressCheck);
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            const localData = localDataArray[index];
                            setImageData(prevData => {
                                const newData = [...prevData];
                                const dataIndex = newData.findIndex(data => data.blobURL === localData.blobURL);
                                if (dataIndex !== -1) {
                                    newData[dataIndex].firebaseURL = downloadURL;
                                }
                                return newData;
                            });
                            resolve(downloadURL);
                        }
                    );
                
                    // Initialize the progress check timer
                    progressCheck = setTimeout(restartUpload, 4000); // 10 seconds for no progress
                });
                
            }catch (error) {
                console.log('Final upload attempt failed:', error);
                throw error;
            }
        }

        const promises = images.map((image, index) => uploadWithRetry(image, localDataArray[index], index));

        try {
            await Promise.all(promises);
            console.log('All images uploaded successfully');
        } catch (errors) {
            console.log('Some images failed to upload:', errors);
            setAlert({message: `Some images failed to upload: ${errors}`, type: 'danger'})
        }
 
    };

    const handleAIClick = (imageAtIndex) => {
        setImageData(prevData => {
            const newData = [...prevData];
            const dataIndex = newData.findIndex(data => data.blobURL === imageAtIndex.blobURL);
            if (dataIndex !== -1) {
                // Toggle the value of newData[dataIndex].aiAssist
                newData[dataIndex].aiAssist = !newData[dataIndex].aiAssist;
            }            
            return newData;
        });
    }
    
    const handleDeleteImage = async (data) => {
        if(!processedImagesRef.current.has(data.blobURL)){
            URL.revokeObjectURL(data.blobURL); //free up memory on user device
        }

        const updatedImageData = imageData.filter(item => item.blobURL !== data.blobURL);
        setImageData(updatedImageData);

        checkAndUpdateFormData(updatedImageData);

        if (data.firebaseURL) {
            const decodedURL = decodeURIComponent(data.firebaseURL);
            const matches = decodedURL.match(/\/o\/([^?]+)(\?|$)/);
            const filePath = (matches ? matches[1] : null);

            if (filePath) {
                const storage = getStorage();
                const fileRef = ref(storage, filePath);
    
                try {
                    await deleteObject(fileRef);
                    console.log(`Deleted ${filePath} from Firebase Storage.`);
                } catch (error) {
                    console.error(`Failed to delete ${filePath} from Firebase Storage:`, error);
                }
            }
        }

        if (data._id && !data._id.startsWith('image-')) {
            try {
                const token = await user.getIdToken(true);
                const response = await imageAPI.deleteImage(token, data._id);
                console.log('Image deleted successfully:', response);
            } catch (error) {
                console.error('Failed to delete image:', error);
            }
        }
        

    }

    // AI styling border
    const boxStyle = {
        padding: '3px',
        position: 'relative',
        resize: 'both',
        overflow: 'hidden',
        fontWeight: 700,
        width: '150px',
        height: '150px',
        border: '3px solid transparent', // Keep the border solid and transparent
        backgroundClip: 'padding-box',   // Prevents the background from bleeding into the border
        backgroundImage: `linear-gradient(
                            105deg, 
                            #2937f0 0%, 
                            #9f1ae2 100%
                          )`,
        borderRadius: '10px' 
    };

        // Define styles for container, upload button wrapper, and button/input within it
    const containerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '10px',
    };
    
    const uploadBtnWrapperStyle = {
        position: 'relative',
        display: 'inline-block',
        width: '150px',
        height: '150px',
        margin: '5px',
    };
    
    const buttonStyle = {
        width: '100%',
        height: '100%',
        border: '2px solid #ccc',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    
    const inputFileStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
    };
    
    
    
    return (
        <div>
            <div className="card mb-2" id="photos">
                <div className="card-header-actions">
                    <div className="card-header">Image Upload
                        {imageData && imageData.length > 0 && ( 
                            <button 
                                className="btn btn-sm btn-secondary" 
                                type="button" 
                                data-bs-toggle="modal" 
                                data-bs-target="#imageOrderModal"
                            >
                                Change Image Order
                            </button>
                        )}
                    </div>
                </div>


                <div className="card-body">
                    {alert.message && (
                        <div className={`alert alert-${alert.type}`} role="alert">
                            {alert.message}
                        </div>
                    )}
                    Max 24 images. Up to 5mb. 
                    <div className="image-upload-container" style={containerStyle}>
                        <div className="image-preview" id="imagePreview">
                            {imageData.map((data, index) => (
                                <div className="image-wrapper" key={index}>
                                    <img 
                                        src={data.firebaseURL || data.blobURL} 
                                        alt={`Image ${index}`} 
                                        ref={el => imageRefs.current[index] = el} 
                                        style={data.aiAssist ? boxStyle : {}}
                                    />

                                    <div 
                                        className="image-overlay"
                                        onClick={() => {
                                            setPreviewImageUrl(data.firebaseURL || data.blobURL);
                                            setIsModalOpen(true);
                                        }}
                                    >
                                        {/* IMAGE OVERLAY icons */}
                                        <>
                                            <button 
                                                className="icon-button" 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleAIClick(imageData[index])
                                                }}
                                            >
                                                <StarFill style={data.aiAssist ? { color: '#2937f0' } : {}} />
                                            </button>
                                            <button 
                                                className="icon-button trash-red" 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteImage(data)
                                                }}
                                            >
                                                <Trash />
                                            </button>
                                        </>
                                        
                                    </div>

                                </div>
                            ))}

                        </div>
                        {/* 
                        <div className="upload-btn-wrapper">
                            <button className="btn btn-primary"><Upload /></button>
                            <input type="file" name="myFile[]" id="formFile" multiple onChange={handleChange} />
                        </div> */}

                        <div className="upload-btn-wrapper" style={uploadBtnWrapperStyle}>
                            <button className="btn btn-primary" style={buttonStyle}>
                                <Upload size={32}/>
                            </button>
                            <input 
                            type="file" 
                            name="myFile[]" 
                            id="formFile" 
                            multiple 
                            onChange={handleChange} 
                            style={inputFileStyle} 
                            />
                        </div>


                    </div>
                </div>
            </div>

            <ModalChangeOrder imageData={imageData} setImageData={setImageData}/>
            <ImagePreviewModal 
                show={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                imageUrl={previewImageUrl} 
            />

        </div>
    );
};

export default React.memo(ImageSimple);
