import React, { useEffect, useRef } from 'react';
import CollapsableCard from '../../Helper/CollapsableCard';

const EbayCategory = ({ formDataCategoryId, formDataCategoryName, ebayData, setEbayData }) => {
    const categoryChangedRef = useRef(false);

    useEffect(() => {
        console.log('formDataCategoryId - ', formDataCategoryId);
        console.log('formDataCategoryName - ', formDataCategoryName);
        if (!categoryChangedRef.current && formDataCategoryId && formDataCategoryName) {
            setEbayData((prevData) => ({
                ...prevData,
                categoryId: formDataCategoryId,
                categoryName: formDataCategoryName
          }));
        }
    }, [formDataCategoryId, formDataCategoryName]);

    const renderModalCategory = () => {
        return(
            <div className="modal fade" id="categoryChangeEbay" tabIndex="-1" aria-labelledby="categoryChangeEbayLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="categoryChangeEbayLabel">Change eBay Category</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body d-flex flex-row flex-wrap">
                            <p>Modal Here</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <CollapsableCard cardTitle="Category" idFragment="ebayCategory">
            {ebayData.categoryId && ebayData.categoryName ? (
                <>
                    <div>
                        <p>Category ID: { ebayData.categoryId }</p>
                        <p>Category Name: { ebayData.categoryName }</p>
                    </div>
                    {/* <button 
                        className="btn btn-primary" 
                        type="button" 
                        data-bs-toggle="modal" 
                        data-bsßtarget={`#categoryChangeEbay`}
                    >
                        Change Category
                    </button> */}
                </>
            ) : (
                <>
                    Select Category in General Listing above.
                    {/* // <button 
                    //     className="btn btn-primary" 
                    //     type="button" 
                    //     data-bs-toggle="modal" 
                    //     data-bs-target={`#categoryChangeEbay`}
                    // >
                    //     Select Category
                    // </button></CollapsableCard> */}
                </>
            )}

            {/* {renderModalCategory()} */}

        </CollapsableCard>
    )


};

export default EbayCategory;