import React from 'react';
import { ArrowCounterclockwise } from 'react-bootstrap-icons';

const ResetButton = ({ onReset }) => (
    <div 
        className="ms-2"
        style={{ cursor: 'pointer', display: 'inline-block' }}
        title="Reset"
        onClick={onReset}
        onMouseOver={(e) => e.currentTarget.style.boxShadow = '0 0 5px rgba(0, 0, 0, 0.2)'}
        onMouseOut={(e) => e.currentTarget.style.boxShadow = 'none'}
    >
        <ArrowCounterclockwise color="red" />
    </div>
);

export default ResetButton;
