import React from 'react';
import ResetButton from './ResetButton';


//TODO: make New click have options NWT, NWOT, New With Defects, etc.

const conditionDefaults = [
    {name: "New With Tags", id: "1000"},
    {name: "Like New", id: "3000"},
    {name: "Good", id: "3100"},
    {name: "Fair", id: "3200"},
    {name: "Poor", id: "3300"}
]

const FormCondition = ({ formConditionId, setFormData }) => {

    const handleConditionSelect = (condition) => {
        if (formConditionId === condition.id) {
            setFormData(prevData => ({ ...prevData, conditionId: null, conditionName: null }));
        } else {
            setFormData(prevData => ({ ...prevData, conditionId: condition.id, conditionName: condition.name }));
        }
    };

    const handleResetCondition = () => {
        setFormData(prevData => ({ ...prevData, conditionId: null, conditionName: null }));
    };

    const flexPercent = (100 - (conditionDefaults.length * 2)) / conditionDefaults.length; //spacing for margins

    return (
        <div style={{ width: '100%' }}>
            <label htmlFor="itemCondition" className="form-label mb-0" style={{ fontSize: '1.2em' }}>Condition</label>
            <ResetButton onReset={handleResetCondition} />
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>
                {conditionDefaults.map((condition, index) => (
                    <button
                        key={`generalCondition-${index}`}
                        className={`btn btn-outline-primary me-2 mb-1 ${formConditionId === condition.id ? 'active' : ''}`}
                        style={{
                            flex: `1 1 ${flexPercent}%`,
                            height: '90px',
                            maxWidth: '100px'
                        }}
                        onClick={() => handleConditionSelect(condition)}
                    >
                        {condition.name}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default FormCondition;
