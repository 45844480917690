import React, { useState } from 'react';
import ResetButton from './ResetButton';
import SingleSuggestButton from '../../Helper/SingleSuggestButton';

const colorChartStandard = [ {name: 'Blue', hex: '#6998AB'}, {name: 'Black', hex: '#333333'}, 
    {name: 'Multicolor', hex: '#CCCCCC'}, {name: 'White', hex: '#F5F5F5'}, {name: 'Gray', hex: '#9E9E9E'}, 
    {name: 'Red', hex: '#C75D5D'}, {name: 'Green', hex: '#8CBF88'}, {name: 'Brown', hex: '#B08968'},
    {name: 'Orange', hex: '#E6A57E'}, {name: 'Beige', hex: '#F0E6D2'}, {name: 'Yellow', hex: '#F8E3A3'}, 
    {name: 'Purple', hex: '#9B72AA'}, {name: 'Pink', hex: '#F7BEC0'}
]

const allColors = ['Blue', 'Black', 'Multicolor', 'Multi-Color', 'White', 'Gray', 'Grey',
'Red', 'Green', 'Brown', 'Orange', 'Beige', 'Tan', 'Yellow', 'Purple', 'Pink', 'Ivory',
'Gold', 'Silver', 'Clear']

const FormColor = ({ formDataColor = [], setFormData, colorChart = colorChartStandard, colorMax = 2 }) => {

    const handleColorSelect = (color) => {
        setFormData(prevFormData => {
            const updatedData = { ...prevFormData };
            let updatedColors = prevFormData.color || [];    

            if(colorMax === 1){ //handle Mercari case
                updatedData.color = color;
                return updatedData;
            }

            if (updatedColors.includes(color)) {
                updatedColors = updatedColors.filter(c => c !== color);
            } else {

                updatedColors = updatedColors.length < colorMax
                    ? [...updatedColors, color] // Add the new color if less than two are selected
                    : [updatedColors[1], color]; // Replace the oldest color with the new one
            }

            if(updatedColors.length === 0){
                delete updatedData.color;
            }else{
                updatedData.color = updatedColors;
            }
    
            return updatedData
        });
    };

    const handleResetColor = () => {
        setFormData(prevData => {
            const updatedData = { ...prevData };
            delete updatedData.color; 
            return updatedData;
        });
    };

    const renderColorButtons = (colorChart) => {
        const row1 = colorChart.slice(0, 5); 
        const row2 = colorChart.slice(5, 10); 
        const row3 = colorChart.slice(10);   

        const getTextColor = (hexColor) => {
            // A simple algorithm to determine if the text should be white or black based on the hex color
            const r = parseInt(hexColor.substr(1, 2), 16);
            const g = parseInt(hexColor.substr(3, 2), 16);
            const b = parseInt(hexColor.substr(5, 2), 16);
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 128 ? 'black' : 'white';
        };
    
        const renderRow = (row) => (
            <div className="btn-group radio-size mb-1" role="group">
                {row.map((color, index) => {
                    const isSelected = formDataColor.includes(color.name);
                    const buttonStyle = {
                        backgroundColor: color.name === 'Multicolor' ? 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)' : color.hex,
                        color: getTextColor(color.hex),
                        border: `2px solid ${isSelected ? '#0d6efd' : (color.name === 'Multicolor' ? 'transparent' : color.hex)}`,
                        cursor: 'pointer'
                    };                    
        
                    return (
                        <button
                            key={index}
                            className={`btn ${isSelected ? 'btn-primary' : 'btn-light'}`}
                            onClick={() => handleColorSelect(color.name)}
                            style={buttonStyle}
                            onMouseOver={(e) => e.currentTarget.style.border = '2px solid black'}
                            onMouseOut={(e) => e.currentTarget.style.border = `2px solid ${isSelected ? '#0d6efd' : color.hex}`}
                        >
                            {color.name}
                        </button>
                    );
                })}
            </div>
        );
        
        return (
            <>
                {renderRow(row1)}
                {renderRow(row2)}
                {renderRow(row3)}
            </>
        );
    };

    const handleSelectedClick = (color) => {
        setFormData(prevData => {
            if (colorMax === 1){
                const updatedData = { ...prevData };
                delete updatedData.color; 
                return updatedData;
            }else{
                const filteredColors = prevData.color.filter(c => c !== color);
                return { ...prevData, color: filteredColors };
            }
        });
    };
    

    return (
        <div style={{ width: '100%' }}>
            <div className="mb-2">
                <label htmlFor="itemColor" className="form-label mb-0" style={{ fontSize: '1.2em' }}>Color</label>
                <ResetButton onReset={handleResetColor} />
            </div>

            {
                formDataColor.length > 0 && (
                    <>
                        <span style={{ marginRight: '3px' }}>Selected:</span>
                            {formDataColor.map((color, index) => (
                                <SingleSuggestButton 
                                    key={index}
                                    value={color}
                                    selected="true"
                                    specific="color"
                                    setSelectedSpecifics={() => handleSelectedClick(color)}
                                />
                        ))}
                    </>
                )
            }

            <div className="container-fluid">
                {renderColorButtons(colorChart)}
            </div>
            
        </div>
    );
};

export default FormColor;