// utils/marketplace.js

//TODO: combine logic?

// if (formData.utils.imageUploadFinished === false){
//     return { validated: false, alertType: 'warning', alertMessage: 'Your images are still uploading. Please wait a few moments & try again.' };
// }



const validateMercari = (mercariData, sizeChartExists) => {
    const requiredFields = [
        'title', 'description', 'condition', 'price',
        { key: 'category', fields: ['department', 'category', 'subCategory'] },
    ];

    function splitCamelCaseToWords(str) {
        return str
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    }

    for (let field of requiredFields) {
        if (typeof field === 'string') {
            if (!mercariData[field]) {
                let fieldName = splitCamelCaseToWords(field)
                return { validated: false, alertType: 'warning', alertMessage: `The field ${fieldName} is required.` };
            }
        } else if (typeof field === 'object' && mercariData[field.key]) {
            for (let nestedField of field.fields) {
                if (!mercariData[field.key][nestedField]) {
                    return { validated: false, alertType: 'warning', alertMessage: `The field ${nestedField} is required.` };
                }
            }
        }
    }

    if(sizeChartExists && !mercariData.size){
        return { validated: false, alertType: 'warning', alertMessage: 'Size needed for this category.' };
    }

    if(!mercariData.pictures || !(mercariData.pictures.length > 0)){
        return { validated: false, alertType: 'warning', alertMessage: 'Upload at least 1 photo.' };
    }

    if(!mercariData.price || (mercariData.price <= 1 || mercariData.price >= 2000)){
        return { validated: false, alertType: 'warning', alertMessage: 'Price must be between $1 and $2000.' };
    }

    if (!mercariData.title || mercariData.title.length < 1 || mercariData.title.length > 80) {
        return { validated: false, alertType: 'warning', alertMessage: 'Title must be between 1 and 80 characters.' };
    }

    if (!mercariData.description || 
        mercariData.description.split(' ').filter(word => word.trim().length > 0).length < 5 || 
        mercariData.description.length > 1000){
        return { validated: false, alertType: 'warning', alertMessage: 'Description must be at least 5 words and less than 1000 characters.' };
    }

    // If all validations pass, return a positive response
    return { validated: true };
}

const validatePoshmark = (poshmarkData) => {
    console.log('validation step poshmarkData - ', poshmarkData);

    const requiredFields = [
        'title', 'description', 'size', 'originalPrice', 'price'
    ];

    function splitCamelCaseToWords(str) {
        return str
            .replace(/([A-Z])/g, ' $1')  // Insert a space before all caps
            .replace(/^./, str => str.toUpperCase());  // Uppercase the first character
    }

    for (let field of requiredFields) {
        if (typeof field === 'string') {
            if (!poshmarkData[field]) {
                let fieldName = splitCamelCaseToWords(field);
                return { validated: false, alertType: 'warning', alertMessage: `The field ${fieldName} is required.` };
            }
        } 
    }

    if (!poshmarkData.pictures || poshmarkData.pictures.length === 0){
        return { validated: false, alertType: 'warning', alertMessage: 'Upload at least 1 photo.' };
    }

    if (!poshmarkData.title || poshmarkData.title.length < 1 || poshmarkData.title.length > 80) {
        return { validated: false, alertType: 'warning', alertMessage: 'Title must be between 1 and 80 characters.' };
    }

    if (!poshmarkData.category?.department) {
        return { validated: false, alertType: 'warning', alertMessage: 'Please select a Department for this listing.' };
    }

    if (!poshmarkData.category?.category) {
        return { validated: false, alertType: 'warning', alertMessage: 'Please select a Category for this listing.' };
    }

    if (poshmarkData.price) {
        const price = parseFloat(poshmarkData.price); // Converts to a float, ensures non-numeric strings are handled
        if (isNaN(price) || !Number.isInteger(price)) {
            return { validated: false, alertType: 'warning', alertMessage: 'Prices must be whole numbers.' };
        }
    }

    return { validated: true, alertType: 'success', alertMessage: 'Validation successful.' };
}


















export { validateMercari, validatePoshmark };