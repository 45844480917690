import React, { useRef } from 'react';
import CollapsableCard from '../../Helper/CollapsableCard';

const MarketplaceDescription = ({ marketplaceDataDescription, setMarketplaceData, marketplaceName }) => {
  const descriptionChangedref = useRef(false);

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;

    setMarketplaceData((prevData) => ({
        ...prevData,
        description: newDescription
    }));

    descriptionChangedref.current = true;
  };

  return (
    <> 
    <CollapsableCard cardTitle="Description" idFragment={`description-${marketplaceName}`}>
        <div>
            <textarea
                id="descriptionTextarea"
                className="form-control"
                value={marketplaceDataDescription || ''}
                onChange={handleDescriptionChange}
                rows="5" 
                placeholder="Enter description here"
            />
        </div>
    </CollapsableCard>
    </>
  );
};

export default MarketplaceDescription;
