import React from 'react';
import AuthFooter from './AuthFooter';
import { useLocation, Link } from 'react-router-dom';

function Error() {

    const location = useLocation();
    const errorMessage = location.state?.errorMessage;

    return (
        <div id="layoutError">
            <div id="layoutError_content">
                <main>
                    <div className="container-xl px-4">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="text-center mt-4">
                                    <img className="img-fluid p-4" src="https://firebasestorage.googleapis.com/v0/b/thriftallday-37c6b.appspot.com/o/site%2Ferorr.png?alt=media" alt="Error Illustration" />
                                    <p className="lead">Something went wrong. Please try again & contact us if this continues. We'll get you back to selling faster ASAP.</p>
                                    <p className="lead">{errorMessage || "This requested URL was not found on this server."}</p>
                                    <Link className="text-arrow-icon" to="/">
                                        Return to Homepage.
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <AuthFooter />
        </div>
    );
}

export default Error;
