import React, { useState, useEffect } from 'react';
import { useUserAuth } from '../../../context/UserAuthContext';
import { userAPI } from '../../../api';
import SettingsCollapsableCard from './SettingsCollapsableCard';

const EbaySettingsCard = ({ ebayConnectionData, settingsDataEbay, handleChange, updateSettings, settingsDataSaved, submitLoading }) => {
    const { user } = useUserAuth();
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [ebayStoreData, setEbayStoreData] = useState({});

    const getPolicyById = (id, policiesArray) => policiesArray.find(policy => policy.id === id);

    const handleSellerProfilesChange = (e, path, policiesArray) => {
        const { value } = e.target;  // This is the selected policy ID
        console.log('policiesArray - ', policiesArray)
        console.log('value - ', value);

        const policy = getPolicyById(value, policiesArray);  // Find the policy object by ID

        console.log('policy - ', policy);
    
        if (policy) {
            // Create a fake event object to use the existing handleChange structure
            const fakeEvent = {
                target: {
                    value: policy,  // Set the whole policy object as the value to be set in state
                    type: 'custom'  // Custom type to differentiate from normal input changes if necessary
                }
            };
            handleChange(fakeEvent, path);
        }
    };
    
    
    useEffect(() => {
        const fetchEbayStoreData = async () => {
            try {
                const token = await user.getIdToken(true);
                const storeDataResponse = await userAPI.getStoreData(token);
                console.log('ebay storeDataResponse - ', storeDataResponse)
                setEbayStoreData(storeDataResponse);
            } catch (err) {
                console.log('err found - ', err);
            }
        };
        fetchEbayStoreData();

    }, []);

    const handleRefreshStoreData = async () => {
        setRefreshLoading(true);
        const token = await user.getIdToken(true); 
        const refreshResponse = await userAPI.refreshStoreData(token);
        console.log("Refreshing Business Policies... ", refreshResponse);
        if(refreshResponse.businessPolicies){
            setEbayStoreData(prevState => ({
                ...prevState,
                returnPolicies: refreshResponse.returnPolicies,
                paymentPolicies: refreshResponse.paymentPolicies,
                fulfillmentPolicies: refreshResponse.fulfillmentPolicies,
                storeCategories: refreshResponse.storeCategories || ''
            }));
        }

        setRefreshLoading(false);
    };
    

    return (
        <SettingsCollapsableCard
            cardTitle="eBay Settings"
            idFragment="EbaySettings"
            isCollapsed={false}
            theme="ebay"
            updateSettings={updateSettings}
            settingsDataSaved={settingsDataSaved}
            submitLoading={submitLoading}
        >

            <div className="row mb-3 align-items-center">
                <>
                    <p>We import your eBay Business policies for Fulfillment (Shipping), Return, & Payment as well as Store Categories. If you've recently changed this, refresh here.</p>
                    <div className="d-flex justify-content-end">
                    <button 
                        className="btn btn-secondary" 
                        onClick={handleRefreshStoreData} 
                        disabled={refreshLoading} 
                    >
                        {refreshLoading ? (
                            <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</>
                        ) : (
                            "Refresh"
                        )}
                    </button>
                    </div>
                </>

            </div>

            <div className="row mb-3 align-items-center">
                <div className="col-md-2">Allow Offers</div>
                <div className="col-md-2 form-check form-switch">
                    <input type="checkbox" className="form-check-input" id="allowOffers"
                        checked={settingsDataEbay.allowOffers || false} onChange={(e) => handleChange(e, 'ebay.allowOffers')}
                    />
                    <label className="form-check-label" htmlFor="allowOffers"></label>
                </div>
            </div>
            

            <div className="row mb-3 align-items-center">
                <div className="col-md-4">
                    <label htmlFor="fulfillmentPolicies" className="form-label"><p>Fulfillment (Shipping) Policy</p></label>
                </div>
                <div className="col-md-8">
                    <select 
                        className="form-select" id="fulfillmentPolicies" 
                        value={settingsDataEbay.sellerProfiles?.fulfillmentPolicy?.id || ''} 
                        onChange={(e) => handleSellerProfilesChange(e, 'ebay.sellerProfiles.fulfillmentPolicy', ebayStoreData.fulfillmentPolicies)}
                    >
                        <option value="">Select Fulfillment Policy</option>
                        {ebayStoreData.fulfillmentPolicies && ebayStoreData.fulfillmentPolicies.length > 0 ? (
                            ebayStoreData.fulfillmentPolicies.map(policy => (
                                <option key={policy.id} value={policy.id}>{policy.name}</option>
                            ))
                        ) : null}
                    </select>
                </div>
            </div>
            
            <div className="row mb-3 align-items-center">
                <div className="col-md-4">
                    <label htmlFor="paymentPolicies" className="form-label"><p>Payment Policy</p></label>
                </div>
                <div className="col-md-8">
                    <select 
                        className="form-select" id="paymentPolicies" 
                        value={settingsDataEbay.sellerProfiles?.paymentPolicy?.id || ''} 
                        onChange={(e) => handleSellerProfilesChange(e, 'ebay.sellerProfiles.paymentPolicy', ebayStoreData.paymentPolicies)}
                    >
                        <option value="">Select Payment Policy</option>
                        {ebayStoreData.paymentPolicies && ebayStoreData.paymentPolicies.length > 0 ? (
                            ebayStoreData.paymentPolicies.map(policy => (
                                <option key={policy.id} value={policy.id}>{policy.name}</option>
                            ))
                        ) : null}
                    </select>
                </div>
            </div>
            
            <div className="row mb-3 align-items-center">
                <div className="col-md-4">
                    <label htmlFor="returnPolicies" className="form-label"><p>Return Policy</p></label>
                </div>
                <div className="col-md-8">
                    <select 
                        className="form-select" id="returnPolicies" 
                        value={settingsDataEbay.sellerProfiles?.returnPolicy?.id || ''} 
                        onChange={(e) => handleSellerProfilesChange(e, 'ebay.sellerProfiles.returnPolicy', ebayStoreData.returnPolicies)}
                    >
                        <option value="">Select Return Policy</option>
                            {ebayStoreData.returnPolicies && ebayStoreData.returnPolicies.length > 0 ? (
                                ebayStoreData.returnPolicies.map(policy => (
                                    <option key={policy.id} value={policy.id}>{policy.name}</option>
                                ))
                            ) : null}
                    </select>
                </div>
            </div>

            <div className="row mb-3 align-items-center">
                <div className="col-md-4">
                    <label htmlFor="storeCategory" className="form-label"><p>Store Category 1</p></label>
                </div>
                <div className="col-md-8">
                {ebayStoreData.storeCategories && ebayStoreData.storeCategories.length > 0 ? (
                    <select 
                        className="form-select" id="storeCategory" 
                        value={settingsDataEbay.storeCategory?.id || ''} 
                        onChange={(e) => handleSellerProfilesChange(e, 'ebay.storeCategory', ebayStoreData.storeCategories)}
                    >
                        <option value="">Select Store Category 1</option>
                        { ebayStoreData.storeCategories.map(storeCategory => (
                            <option key={storeCategory.id} value={storeCategory.id}>{storeCategory.name}</option>
                        ))}
                    </select>
                ) : (
                    <input type="text" className="form-control" placeholder="No Store Categories" disabled />
                )}
                </div>
            </div>

            <div className="row mb-3 align-items-center">
                <div className="col-md-4">
                    <label htmlFor="storeCategory2" className="form-label"><p>Store Category 2</p></label>
                </div>
                <div className="col-md-8">
                {ebayStoreData.storeCategories && ebayStoreData.storeCategories.length > 0 ? (
                    <select 
                        className="form-select" id="storeCategory2" 
                        value={settingsDataEbay.storeCategory2?.id || ''} 
                        onChange={(e) => handleSellerProfilesChange(e, 'ebay.storeCategory2', ebayStoreData.storeCategories)}
                    >
                        <option value="">Select Store Category 2</option>
                            {ebayStoreData.storeCategories.map(storeCategory => (
                                <option key={storeCategory.id} value={storeCategory.id}>{storeCategory.name}</option>
                            ))}
                    </select>
                ) : (
                    <input type="text" className="form-control" placeholder="No Store Categories" disabled />
                )}
                </div>
            </div>

        </SettingsCollapsableCard>
    );
};

export default EbaySettingsCard;