import React, { useState, useEffect, useRef } from "react";
import { useUserAuth } from '../../../context/UserAuthContext';
import { templateAPI } from '../../../api';
import { StarFill } from 'react-bootstrap-icons';
import SingleSuggestButton from "../../Helper/SingleSuggestButton";

const SkeletonLoader = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const messages = [
        'Images Uploading...',
        'Analyzing Images...',
        'Checking for Brand',
        'Checking for Size',
        'Checking for Color',
        'Checking for Materials',
        'Checking for Item Category',
        'Checking for Item Details'
    ];

    const styles = {
        loader: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        square: {
            width: '50px',
            height: '50px',
            backgroundColor: '#ccc',
            animation: 'pulse 1.5s ease-in-out infinite'
        },
        text: {
            color: '#ccc',
            fontSize: '16px',
            animation: 'fadeInOut 1.5s infinite'
        },
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((current) => (current + 1) % messages.length); // Cycle through messages
        }, 3000); // Change text every 3000ms

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, [messages.length]);

    return (
        <>
            <style>
            {`
                @keyframes pulse {
                    0%, 100% {
                        opacity: 1;
                    }
                    50% {
                        opacity: 0.5;
                    }
                }
                @keyframes fadeInOut {
                    0%, 100% {
                        opacity: 0;
                    }
                    50% {
                        opacity: 1;
                    }
                }
            `}
            </style>
            <div className="skeleton-loader" style={styles.loader}>
                <div className="skeleton-square" style={styles.square}></div>
                <div className="skeleton-text" style={styles.text}>{messages[currentIndex]}</div>
            </div>
        </>
    );
};

const AIAssistResults = ({aiResults, setFormData, setEbayData}) => {
    const [selectedSpecifics, setSelectedSpecifics] = useState({});
    const [searchTermAI, setSearchTermAI] = useState('');
    const prevSelectedSpecifics = useRef(selectedSpecifics);

    useEffect(() => {
        if (aiResults.returnObj) {
          console.log('AI RESULTS - ', aiResults.returnObj);
          const newSelectedSpecifics = { ...selectedSpecifics };

          for (const key in aiResults.returnObj) {
            // Check if aiResults.returnObj[key] is not already a key in newSelectedSpecifics
            if (!(key in newSelectedSpecifics)) {
                const value = aiResults.returnObj[key];
                const valuesArray = value === null ? null : Array.isArray(value) ? value : [value];

                if(valuesArray !== null){
                    switch (key) {
                        case 'color':
                          // Limit to 2 results
                          newSelectedSpecifics[key] = valuesArray.slice(0, 2);
                          break;
                        case 'material':
                          // No limit for material
                          newSelectedSpecifics[key] = valuesArray;
                          break;
                        case 'searchTerm':
                            setSearchTermAI(valuesArray[0])
                          break;
                        default:
                          // Limit all others to 1 result
                          newSelectedSpecifics[key] = valuesArray.slice(0, 1);
                    }
                }
            }
          }

          if(aiResults.sportsObj && aiResults.sportsObj.sportsTeamBool && aiResults.sportsObj.sportsTeamName){
            newSelectedSpecifics['Team'] = aiResults.sportsObj.sportsTeamName;
          }

          // Update the state only if there are changes.
          if (Object.keys(newSelectedSpecifics).length > Object.keys(selectedSpecifics).length) {
            console.log('newSelectedSpecifics - ', newSelectedSpecifics);
            setSelectedSpecifics(newSelectedSpecifics);
          }
        }
    }, [aiResults.returnObj]);

    useEffect(() => {
        if(searchTermAI !== ''){
            setFormData(prevFormData => ({
                ...prevFormData,
                utils: {
                    ...prevFormData.utils,
                    searchTerm: searchTermAI
                }
            }));
        }
    }, [searchTermAI]);

    useEffect(() => {
        //USE: Sets AI data into formData and ebayData
        //TODO: Toggle Removal;
        let updatedItemSpecifics = {};

        for (const key in selectedSpecifics) {
          if (selectedSpecifics[key] !== prevSelectedSpecifics.current[key]) {
    
            // Specific logic based on the key that changed
            if (['size', 'color', 'brand'].includes(key)) {
                let valueToFormData = null;
                if(['size', 'brand'].includes(key)){
                    valueToFormData = selectedSpecifics[key][0] //array -> single value
                }else{
                    valueToFormData = selectedSpecifics[key]
                }

                setFormData(prevFormData => ({
                    ...prevFormData,
                    [key]: valueToFormData
                }));

            } else {
                for (const key in selectedSpecifics) {
                    if (selectedSpecifics[key] !== null && key !== 'keywords' && key !== 'color') {
                        let formattedKey;
                
                        // Handle special cases
                        switch (key) {
                            case 'upc':
                                formattedKey = 'UPC';
                                break;
                            case 'country':
                                formattedKey = 'Country/Region of Manufacture';
                                break;
                            case 'gender':
                                formattedKey = 'Department';
                                break;
                            default:
                                // Convert camelCase to separate words with first letter capitalized
                                formattedKey = key.replace(/([A-Z])/g, ' $1').trim();
                                formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
                                break;
                        }

                        // Update the specifics with the formatted key
                        updatedItemSpecifics[formattedKey] = selectedSpecifics[key];
                    }
                }
            }
          }
        }

        if (Object.keys(updatedItemSpecifics).length > 0) {
            setEbayData(prevEbayData => {
                const newItemSpecifics = { ...prevEbayData.itemSpecifics };
        
                for (const key in updatedItemSpecifics) {
                    if (newItemSpecifics[key]) {
                        const updatedValues = Array.isArray(updatedItemSpecifics[key]) ? updatedItemSpecifics[key].flat() : [updatedItemSpecifics[key]];
                        newItemSpecifics[key] = Array.from(new Set([...newItemSpecifics[key], ...updatedValues]));
                    } else {
                        newItemSpecifics[key] = Array.isArray(updatedItemSpecifics[key]) ? updatedItemSpecifics[key].flat() : [updatedItemSpecifics[key]];
                    }
                }

                console.log('newItemSpecifics - ', newItemSpecifics);
        
                return {
                    ...prevEbayData,
                    itemSpecifics: { ...prevEbayData.itemSpecifics, ...newItemSpecifics }
                };
            });
        }
        
    
        // Update the ref with the current selectedSpecifics
        prevSelectedSpecifics.current = selectedSpecifics;
    }, [selectedSpecifics]);




    return (
        <>
            {searchTermAI && (
                <div className="row">
                    <div className="col">
                        <p>Item Identified: {searchTermAI}</p>
                    </div>
                </div> 
            )}

            {selectedSpecifics && Object.keys(selectedSpecifics).length > 0 && (
                <div className="row">
                    {/* Splitting the entries into two columns */}
                    {Object.entries(selectedSpecifics).map(([key, values], idx, arr) => {
                       
                       const formattedKey = key
                        .replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter
                        .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
                        .trim(); // Trim any leading space

                        // Skipping null or empty values
                        if (values === null || (Array.isArray(values) && values.length === 0)) {
                            return null;
                        }

                        const columnClass = idx < arr.length / 2 ? "col-6" : "col-6";
                        const valuesArray = Array.isArray(values) ? values : [values];

                        return (
                            <div key={idx} className={`${columnClass} d-flex align-items-center flex-wrap mb-1`}> 
                                <label className="mr-2 mb-0" style={{marginRight: '10px'}}>{formattedKey}:</label>
                                <div className="d-flex flex-wrap">
                                    {valuesArray.map((value, valueIdx) => {
                                        return (
                                            <SingleSuggestButton 
                                                key={valueIdx} 
                                                value={value} 
                                                selected={Array.isArray(selectedSpecifics[key]) && selectedSpecifics[key].includes(value)} 
                                                specific={key}
                                                setSelectedSpecifics={setSelectedSpecifics}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

        </>
        
    )
    
}

const AIAssistNew = ({imageData, formData, setFormData, setEbayData}) => {
    const { user } = useUserAuth();
    const [aiSearchLoading, setAISearchLoading] = useState(false);
    const [aiResults, setAIResults] = useState({});
    const [starCount, setStarCount] = useState(1);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [aiSearchReady, setAiSearchReady] = useState(false);
    const [aiSearchPending, setAiSearchPending] = useState(false);

    const handleAIAnalysis = async () => {
        if(imageData.length === 0){
            setAlert({ message: 'Upload images first', type: 'warning'});
        }else{
            setAlert({ message: '', type: ''})
            setAiSearchPending(true);
        }
    };

    useEffect(() => {
        if(aiSearchPending && aiSearchReady){
            const urls = imageData
            .filter(item => item.aiAssist === true)  // Filter items where aiAssist is true
            .map(item => item.firebaseURL);          // Extract firebaseURL from those items

            const itemsWithAiAssist = imageData.filter(item => item.aiAssist === true);
            const imagesLoaded = itemsWithAiAssist.length === urls.length && urls.every(url => url != null);

            console.log('itemsWithAiAssist - ', itemsWithAiAssist);
        
            if (user && urls.length >= 1 && imagesLoaded){
                setAiSearchPending(false);
                aiFetch(urls);
            }
        }
    }, [aiSearchPending, aiSearchReady]);

    const aiFetch = async (urls) => {
        try {
            setAISearchLoading(true);
            const token = await user.getIdToken(true);
            const data = await templateAPI.aiAnalysis(token, urls);
            console.log('data aiAnalysis - ', data);
            setAIResults(data);
            setFormData(prevData => ({
                ...prevData,
                utils: { 
                  ...prevData.utils,  
                  aiResults: data  
                }
            }));
        } catch (error) {
            console.error('An error occurred with AI Analysis:', error);
            let messageText = 'AI error on these images.'; // Default message

            if (typeof error.response?.data?.error === 'string' && error.response.data.error.trim().length > 0) {
                messageText = error.response.data.error;
            }

            setAlert({ message: messageText, type: 'danger' });
        } finally {
            setAISearchLoading(false); 
        }
    };

    useEffect(() => {
        if (imageData.length > 0) {
            const allAiAssistItemsValid = imageData.every(item => {
                return !item.aiAssist || (item.aiAssist && item.firebaseURL !== null);
            });
            console.log('useEffect imageData allAiAssistItemsValid - ', allAiAssistItemsValid);
            setAiSearchReady(allAiAssistItemsValid);
        }
    }, [imageData]);

    useEffect(() => {
        let interval;
        if (aiSearchLoading) {
            interval = setInterval(() => {
                setStarCount(prevCount => (prevCount % 3) + 1);
            }, 500); // Change the star count every 500ms
        }

        return () => clearInterval(interval);
    }, [aiSearchLoading]);

    function isNotEmptyObject(obj) {
        return obj && Object.keys(obj).length > 0;
    }


    return (
        <>
        <div className="mb-3"> 
        <div className="card bg-gradient-primary-to-secondary">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="me-3">
                        <div className="h1 text-white">AI Assist <StarFill style={{ paddingBottom: '8px'}}/></div>
                        <div className="small" style={{ '--bs-text-opacity': '1', color: 'hsla(0, 0%, 100%, 0.7)' }}>
                            Choose images that show the full front & any tags for best results.
                        </div>
                        <div className="small" style={{ '--bs-text-opacity': '1', color: 'hsla(0, 0%, 100%, 0.7)' }}>
                            Select images above by hovering over & clicking the <StarFill style={{ paddingBottom: '2px'}} /> icon.
                        </div>
                        <div className="small" style={{ '--bs-text-opacity': '1', color: 'hsla(0, 0%, 100%, 0.7)' }}>
                            When ready, click the "AI Anaylze" button and let AI fill in your listing details.
                        </div>
                        <div className="small" style={{ '--bs-text-opacity': '1', color: 'hsla(0, 0%, 100%, 0.7)' }}>
                            NOTE: AI can make mistakes. You are responsible for your listings. 
                        </div>
                    </div>
                    <div className="text-white">
                        <button 
                            className={`btn ${isNotEmptyObject(aiResults) ? 'btn-success' : 'btn-primary'} btn-lg`} 
                            onClick={handleAIAnalysis} 
                            disabled={aiSearchLoading || isNotEmptyObject(aiResults)}
                        >
                            {aiSearchLoading ? (
                                Array.from({ length: starCount }, (_, i) => <StarFill key={i} />)
                            ) : isNotEmptyObject(aiResults) ? (
                                "Loaded"
                            ) : (
                                "AI Analyze"
                            )}
                        </button>
                    </div>
                </div>

                
                {alert.message || aiSearchLoading || Object.keys(aiResults).length > 0 ? (
                    <div className="card" style={{ marginTop: '10px' }}>
                        <div className="card-body">
                            {alert.message && (
                                <div className={`alert alert-${alert.type}`} role="alert" style={{ marginBottom: '0px' }}>
                                    {alert.message}
                                </div>
                            )}

                            {aiSearchLoading && (
                                <SkeletonLoader />
                            )}
                            
                            {Object.keys(aiResults).length > 0 && (
                                <AIAssistResults aiResults={formData.utils?.aiResults || {}} setFormData={setFormData} setEbayData={setEbayData}/>
                            )}

                        </div>
                    </div>
                ): null}

            </div>
        </div>

        </div>
    </>
    );


};


export default React.memo(AIAssistNew);