import React from 'react';

const CallbackDecline = () => {
    return (
        <div id="layoutAuthentication" className="bg-primary d-flex flex-column justify-content-center" style={{ minHeight: '100vh' }}>
            <div id="layoutAuthentication_content">
                <main>
                    <div className="container-xl px-4">
                        <div className="row justify-content-center">
                            <div className="col-md-6 text-center text-white">
                                <p>ThriftAllDay only works with connection to eBay. Please contact support</p>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default CallbackDecline;
