import axios from 'axios';

// Base URL for the product-related endpoints
const baseURL = `${process.env.REACT_APP_ENDPOINT}`;

export const createProxy = async(token, proxyData) => {
    const response = await axios.post(`${baseURL}/cloud/proxy`, 
        { proxyData },  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
}

export const createCloudAction = async (token, actionName, data = {}) => {
    const response = await axios.post(`${baseURL}/cloud/queue/add`, 
        { actionName, data },  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
};


export const createMarketplaceAuth = async (token, username, password, marketplace) => {
    const response = await axios.post(`${baseURL}/cloud/${marketplace}/auth`, 
        { username, password },  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
};

export const updateMarketplaceAuth = async (token, marketplace, authId, data) => {
    const response = await axios.put(`${baseURL}/cloud/${marketplace}/auth/${authId}`, 
        data,  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
};

export const readMarketplaceAuth = async (token, marketplace) => {
    const response = await axios.get(`${baseURL}/cloud/${marketplace}/auth`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return response.data;
};

