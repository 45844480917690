import React, { useState, useEffect, useRef } from 'react';
import CollapsableCard from '../../Helper/CollapsableCard';

const FormDescription = ({ formData, setFormData }) => {
    const initialLoad = useRef(true);
    const descriptionChangedRef = useRef(false);
    const [generateDescription, setGenerateDescription] = useState(false);
    const [originalDescription, setOriginalDescription] = useState('');

    useEffect(() => {
        if (initialLoad.current && formData.description) {
            const placeholderRegex = /\[(title|size)\]/;
            const generateDescriptionCheck = placeholderRegex.test(formData.description);
            if(generateDescriptionCheck){
                setGenerateDescription(true);
                setOriginalDescription(formData.description);
            }
            initialLoad.current = false;
        }
    }, [formData.description]);

    const replacePlaceholders = (description) => {
        return description
            .replace(/\[title\]/g, formData.title || '')
            .replace(/\[size\]/g, formData.size || '')
    };

    useEffect(() => {
        // If generateDescription is true, replace placeholders when formData changes
        if (generateDescription && !descriptionChangedRef.current) {
            const updatedDescription = replacePlaceholders(originalDescription);
            setFormData(prevData => ({
                ...prevData,
                description: updatedDescription
            }));
        }
    }, [
        formData.title, 
        formData.size
    ]);

    const handleDescriptionChange = (e) => {
        descriptionChangedRef.current = true;
        setFormData({ ...formData, description: e.target.value });
    };

    return (
        <CollapsableCard cardTitle="Description" idFragment="Description">
            <div>
                <textarea
                    id="descriptionTextarea"
                    className="form-control"
                    value={formData.description}
                    onChange={handleDescriptionChange}
                    rows="5" 
                    placeholder="Enter description here"
                />
            </div>
        </CollapsableCard>
    );
};

export default FormDescription;
