import React, { useState, useEffect } from 'react';
import CollapsableCard from '../Helper/CollapsableCard';


const PoshmarkPricingDetails = ({ poshmarkPrice, poshmarkOriginalPrice, poshmarkSKU, poshmarkUtils, setMarketplaceData }) => {
    const [initialChange, setInitialChange] = useState(false);

    useEffect(() => {
        //make price changes based on listing defaults;
        
        if(poshmarkPrice && !initialChange){
            let updatedPrice;
            let originalPrice;

            if(poshmarkUtils.priceChange && poshmarkUtils.priceChangeAmount){
                updatedPrice = Number(poshmarkPrice) + Number(poshmarkUtils.priceChangeAmount)
            }else if(poshmarkUtils.priceChange && poshmarkUtils.priceChangePercent){
                updatedPrice = Math.round(Number(poshmarkPrice) * (1 + (Number(poshmarkUtils.priceChangeAmount) / 100) ))
            }else{
                updatedPrice = Number(poshmarkPrice);
            }

            if(poshmarkUtils.originalPriceAmount){
                originalPrice = updatedPrice + Number(poshmarkUtils.originalPriceAmount)
            }else if(poshmarkUtils.originalPricePercent){
                originalPrice = updatedPrice + Math.round(updatedPrice * (1 + (Number(poshmarkUtils.originalPricePercent) / 100) ))
            }

            setMarketplaceData(prevData => ({
                ...prevData,
                price: updatedPrice,
                originalPrice: originalPrice
            }));
            setInitialChange(true);
        }
    }, [poshmarkPrice]);

    const handleInputChange = (e) => {
        const { name, id, type, value, options } = e.target;
        const key = name || id;

        if(key === 'price'){
            setInitialChange(true);
        }

        setMarketplaceData(prevData => ({
            ...prevData,
            [key]: value
        }));

    };

    return (
        <CollapsableCard cardTitle="Poshmark Pricing Details" idFragment="poshmarkPricingDetails">
            <div className="row mb-3">
                <label htmlFor="originalPrice" className="col-sm-2 col-form-label">Original Price (MSRP)</label>
                <div className="col-sm-4">
                    <input key="poshOriginalPrice" type="text" className="form-control" id="originalPrice" value={poshmarkOriginalPrice ? poshmarkOriginalPrice : ''} onChange={handleInputChange} />
                </div>
                <label htmlFor="sku" className="col-sm-2 col-form-label">SKU</label>
                <div className="col-sm-4">
                    <input key="poshSKU" type="text" className="form-control" id="sku" value={poshmarkSKU ? poshmarkSKU : ''} onChange={handleInputChange} />
                </div>
            </div>
            <div className="row mb-3">
                <label htmlFor="price" className="col-sm-2 col-form-label">Listing Price</label>
                <div className="col-sm-4">
                    <input key="poshPrice" type="text" className="form-control" id="price" value={poshmarkPrice ? poshmarkPrice : ''} onChange={handleInputChange}  />
                </div>
            </div>

        </CollapsableCard>
    );
    
};

export default PoshmarkPricingDetails;
