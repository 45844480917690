import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../../../context/UserAuthContext';
import Footer from '../../Helper/Footer';
import SettingsNav from './SettingsNav';
import { cloudAPI } from '../../../api';
import SettingsCollapsableCard from './SettingsCollapsableCard';

const ConnectPoshmarkModal = ({ show, onClose }) => {
    const { user } = useUserAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [authFormLoading, setAuthFormLoading] = useState(false);
    const [authResponse, setAuthResponse] = useState({});
    const [validationMessage, setValidationMessage] = useState('')

    const handleConnect = async (event) => {
      event.preventDefault();

        if (username.length < 2) {
            setValidationMessage('Username is too short.');
            return;
        }
        if (password.length < 6) {
            setValidationMessage('Password is invalad. Password must be at least 6 characters long.');
            return;
        }

        setValidationMessage('');

      console.log('handle connect')
      try {
          setAuthFormLoading(true);
          const token = await user.getIdToken(true);
          const cloudResponse = await cloudAPI.createMarketplaceAuth(token, username, password, 'poshmark');
          console.log('authResponse - ', cloudResponse);
          setAuthResponse(cloudResponse);
      } catch (error) {
          console.error('An error occurred while getting total imported:', error);
      } finally {
        setAuthFormLoading(false);
      }

    };

    return (
        <>
            {show && <div className="modal-backdrop show"></div>}
            <div className={`modal ${show ? 'show d-block' : ''}`} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Connect to Poshmark</h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body">
                            {!acceptTerms ? (
                                <div>
                                    <p>
                                        We connect to Poshmark on your behalf to complete the following tasks:
                                    </p>
                                    <ul>
                                      <li>Share Your Closet</li>
                                      <li>Send Offers</li>
                                      <li>Import Listings for Cross-Listing</li>
                                      <li>Create New Listings</li>
                                      <li>Re-List Listings</li>
                                      <li>Check for Sold Listings</li>
                                      <li>More actions as we grow our features</li>
                                    </ul>
                                    <p>By connecting, you agree to allow us to complete these actions. All actions are started by you and done on your behalf.</p>
                                    <button type="button" className="btn btn-primary" onClick={() => setAcceptTerms(true)}>
                                        Accept
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label">Username</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            placeholder="Enter your Poshmark username"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Enter your Poshmark password"
                                            autoComplete="new-password"
                                        />
                                    </div>
                                    <p>We secure your passwords with high level encryption. Our team cannot read your password.</p>
                                    {validationMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {validationMessage}
                                        </div>
                                    )}
                                    
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleConnect}
                                        disabled={authFormLoading || (authResponse && Object.keys(authResponse).length > 0)}
                                    >
                                        {authFormLoading ? 'Loading...' : (authResponse && Object.keys(authResponse).length > 0 ? 'Submitted' : 'Connect')}
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// const ProxyForm = () => {
//   const defaultProxyData = {
//     serviceName: 'webshare.io',
//     host: 'p.webshare.io',
//     ip: '',
//     port: '',
//     username: 'xfhjesja',
//     password: 'b0q2y9s8p0sa',
//   }
//   const [formData, setFormData] = useState(defaultProxyData);
//   const { user } = useUserAuth();
//   const [alert, setAlert] = useState({message: '', type: ''})


//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const token = await user.getIdToken(true);
//       const cloudResponse = await cloudAPI.createProxy(token, formData);
//       const cloudMessage = cloudResponse.message || cloudResponse.data.message;
//       setAlert({message: cloudMessage, type: 'primary'});
//       setFormData(defaultProxyData);

//     } catch (error) {
//       console.error('Error getting user token:', error);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit} className="row row-cols-lg-auto g-3 align-items-center">
//         {alert.message && (
//             <div className={`alert alert-${alert.type}`} role="alert">
//                 {alert.message}
//             </div>
//         )}
//       <div className="col-12">
//         <label htmlFor="serviceName" className="form-label visually-hidden">Service Name</label>
//         <div className="input-group">
//           <div className="input-group-text">Service Name</div>
//           <input
//             type="text"
//             className="form-control"
//             id="serviceName"
//             name="serviceName"
//             value={formData.serviceName}
//             onChange={handleChange}
//             required
//           />
//         </div>
//       </div>
//       <div className="col-12">
//         <label htmlFor="host" className="form-label visually-hidden">Host</label>
//         <div className="input-group">
//           <div className="input-group-text">Host</div>
//           <input
//             type="text"
//             className="form-control"
//             id="host"
//             name="host"
//             value={formData.host}
//             onChange={handleChange}
//             required
//           />
//         </div>
//       </div>
//       <div className="col-12">
//         <label htmlFor="ip" className="form-label visually-hidden">IP Address</label>
//         <div className="input-group">
//           <div className="input-group-text">IP Address</div>
//           <input
//             type="text"
//             className="form-control"
//             id="ip"
//             name="ip"
//             value={formData.ip}
//             onChange={handleChange}
//             required
//           />
//         </div>
//       </div>
//       <div className="col-12">
//         <label htmlFor="port" className="form-label visually-hidden">Port</label>
//         <div className="input-group">
//           <div className="input-group-text">Port</div>
//           <input
//             type="number"
//             className="form-control"
//             id="port"
//             name="port"
//             value={formData.port}
//             onChange={handleChange}
//             required
//           />
//         </div>
//       </div>
//       <div className="col-12">
//         <label htmlFor="username" className="form-label visually-hidden">Username</label>
//         <div className="input-group">
//           <div className="input-group-text">Username</div>
//           <input
//             type="text"
//             className="form-control"
//             id="username"
//             name="username"
//             value={formData.username}
//             onChange={handleChange}
//             required
//           />
//         </div>
//       </div>
//       <div className="col-12">
//         <label htmlFor="password" className="form-label visually-hidden">Password</label>
//         <div className="input-group">
//           <div className="input-group-text">Password</div>
//           <input
//             type="text"
//             className="form-control"
//             id="password"
//             name="password"
//             value={formData.password}
//             onChange={handleChange}
//             required
//           />
//         </div>
//       </div>
//       <div className="col-12">
//         <button type="submit" className="btn btn-primary">Submit</button>
//       </div>
//     </form>
//   );
// };

const SettingsPoshmark = () => {
  const { user } = useUserAuth();
  const [authData, setAuthData] = useState(null);
  const [authDataLoading, setAuthDataLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logData, setLogData] = useState([]);

  const exampleProductData = {
    title: 'Test Title - Hawaiian Shirt',
    description: 'Test item. Do not buy.',
    pictures: [
        { imageUrl: 'https://i.ebayimg.com/images/g/GZgAAOSwkntm5f8q/s-l1600.jpg'}, 
        { imageUrl: 'https://i.ebayimg.com/images/g/4nUAAOSw1Xxm5f8p/s-l1600.jpg'} 
    ],
    originalPrice: 199,
    price: 99,
    sizeChart: 'Standard',
    size: 'S',
    category: {
        department: 'Men',
        category: 'Shirts'
    }
  }

  const handleCloudAction = async (actionName) => {
    try {
      const token = await user.getIdToken(true);
      let dataToUse = {};
      if(actionName === 'poshmarkListProduct'){
        dataToUse = exampleProductData;
      }
      const cloudResponse = await cloudAPI.createCloudAction(token, actionName, dataToUse);
      console.log(`${actionName} Cloud Action Response:`, cloudResponse);
      setLogData(prevLogData => [...prevLogData, `Cloud Action Response: ${JSON.stringify(cloudResponse)}`]);
    } catch (error) {
      console.error(`An error occurred on handle ${actionName} CloudAction:`, error);
      setLogData(prevLogData => [...prevLogData, `Cloud Action Error: ${error}`]);
    }
  };

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        setAuthDataLoading(true);
        const token = await user.getIdToken(true);
        const authResponse = await cloudAPI.readMarketplaceAuth(token, 'poshmark');
        setLogData(prevLogData => [...prevLogData, `Auth Response: ${JSON.stringify(authResponse)}`]);
        setAuthData(authResponse || {}); // Ensure authData is always an object
        setConnected(authResponse?.connected || false);
      } catch (error) {
        console.error('An error occurred while getting auth data:', error);
        setLogData(prevLogData => [...prevLogData, `Auth Error: ${error}`]);
        setAuthData({}); // Set to empty object on error
      } finally {
        setAuthDataLoading(false);
      }
    };

    if (user) {
      setLogData(prevLogData => [...prevLogData, 'User Found']);
      fetchAuthData();
    }
  }, [user]);


  const updateSettings = () => {
    console.log("Update settings function called");
  };

  return (
    <>
    <main>
      <div className="container-xl px-4 mt-4">
        <SettingsNav />

          <div className="row">
            <div className="col-lg-4">
                <div className="card mb-4">
                <div className="card-header">Poshmark Connection</div>
                <div className="card-body">
                    {authDataLoading ? (
                        <div>Checking Connection Status...</div>
                    ) : (
                        connected ? (
                            <>
                                <p>Connected!</p>
                            </>
                        ) : (
                            <>
                                <button className="btn btn-secondary" onClick={() => setIsModalOpen(true)}>
                                    Connect to Poshmark
                                </button>
                            </>
                        )
                    )}                    
                    
                </div>
                </div>
                <div className="card mb-2">
                    <div className="card-header">Cloud Action Testing</div>
                    <div className="card-body">
                        <button
                            type="button"
                            className="btn btn-primary mt-3"
                            onClick={() => handleCloudAction('testMediumAction')}
                        >
                            CloudAction Medium
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary mt-3"
                            style={{ marginLeft: '3px' }}
                            onClick={() => handleCloudAction('testMicroAction')}
                        >
                            CloudAction Micro
                        </button>
                    </div>
                </div>

            </div>
            <div className="col-lg-8">
                <SettingsCollapsableCard
                    cardTitle="Poshmark Automation Settings"
                    idFragment="PoshmarkAutomationSettings"
                    theme="poshmark"
                    updateSettings={updateSettings}
                    settingsDataSaved={false}
                    submitLoading={false}
                >
                    <div className="container">
                        <div 
                            style={{
                                maxHeight: '20vh',
                                overflowY: 'auto',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '10px',
                                marginBottom: '15px'
                            }}
                        >
                            {logData.map((log, index) => (
                                <div 
                                    key={index} 
                                    style={{
                                        borderBottom: index < logData.length - 1 ? '1px solid #eee' : 'none',
                                        padding: '5px 0',
                                        whiteSpace: 'normal', // Allow text to wrap
                                        overflow: 'visible',  // Ensure overflow is visible
                                        textOverflow: 'clip'  // Remove ellipsis
                                    }}
                                >
                                    {log}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="container">
                        <div className="row mb-3 align-items-center">
                            <div className="col-md-8">
                                <label htmlFor="dailySharing" className="form-label">
                                    <p>Daily Sharing (3-5x entire closet per day)</p>
                                </label>
                            </div>
                            <div className="col-md-4">
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="dailySharing"
                                        checked={authData?.shareDaily || false}
                                        onChange={(e) => {
                                            setAuthData(prev => ({...prev, shareDaily: e.target.checked}));
                                        }}
                                        disabled={authDataLoading || !connected}
                                    />
                                    <label className="form-check-label" htmlFor="dailySharing">
                                        {/* You can add text here if needed */}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3 align-items-center">
                            <div className="col-md-12">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleCloudAction('poshmarkSharing')}
                                    disabled={authDataLoading || !connected}
                                >
                                    Share Now
                                </button>
                            </div>
                        </div>
                        <div className="row mb-3 align-items-center">
                            <div className="col-md-12">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => handleCloudAction('poshmarkListProduct')}
                                    disabled={authDataLoading || !connected}
                                >
                                    List Example Product
                                </button>
                            </div>
                        </div>
                    </div>
                </SettingsCollapsableCard>


                {/* <SettingsCollapsableCard
                    cardTitle="Proxy Details"
                    idFragment="proxySettings"
                >
                    <ProxyForm />
                </SettingsCollapsableCard> */}
            </div>
          </div>
            <ConnectPoshmarkModal 
                show={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
            />

      </div>
    </main>
    <Footer />
    </>
  );
};

export default SettingsPoshmark;

