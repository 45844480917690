import React from 'react';
import { NavLink } from 'react-router-dom';

const SettingsNav = () => {
    const handleDisabledLinkClick = (e) => {
        e.preventDefault(); // Prevent navigation for disabled links
    };

    return (
        <>
            <nav className="nav nav-borders">
                <NavLink
                    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                    to="/settings/marketplaces"
                >
                    Marketplaces
                </NavLink>
                <NavLink
                    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                    to="/settings/billing"
                >
                    Billing
                </NavLink>
                <a href="#" className="nav-link disabled" onClick={handleDisabledLinkClick} title="Coming Soon">
                    Importing
                </a>
            </nav>
            <hr className="mt-0 mb-4" />
        </>
    );
}

export default SettingsNav;
