import React, { useState, useEffect, useRef } from 'react';
import ModalChangeOrder from '../ModalChangeOrder';

const PhotoPreview = React.memo(({ formDataPictures, marketplaceDataPictures, setMarketplaceData, marketplaceName, maxPhotos = 24 }) => {
  const photoChangedRef = useRef(false);
  const [showModalPhotos, setShowModalPhotos] = useState(false);

  useEffect(() => {
    if (!photoChangedRef.current) {
      setMarketplaceData((prevData) => ({
        ...prevData,
        pictures: formDataPictures
      }));
    }
  }, [formDataPictures]);

  // Function to toggle the modal
  const toggleMarketplacePhotos = () => {
    console.log('Trigger Here');
    setShowModalPhotos(!showModalPhotos);
  };

  return (
    <div className="card card-header-actions mb-2">
      <div className="card-header">
        Photos (Maximum: {maxPhotos})
        <button 
          className="btn btn-sm btn-secondary" 
          type="button" 
          data-bs-toggle="modal" 
          data-bs-target={`#imageOrder${marketplaceName}Modal`}
        >
          Change Image Order
        </button>

      </div>
      <div className="card-body">
        {marketplaceDataPictures.length === 0 ? (
          <p>Upload photos in General Listing.</p>
        ) : (
            <div className="scrolling-container">
                <div className="row">
                {marketplaceDataPictures.map((imageData, index) => (
                    <div key={index} className="col-md-3 mb-3">
                    <div className="card" style={{
                      filter: index >= maxPhotos ? 'grayscale(100%)' : 'none', 
                      opacity: index >= maxPhotos ? 0.5 : 1 
                    }}>
                        <img src={imageData.imageUrl} className="card-img-top" alt={`Image ${index + 1}`} />
                    </div>
                    </div>
                ))}
                </div>
            </div>
        )}
      </div>


      <ModalChangeOrder 
        imageData={marketplaceDataPictures || []} 
        setImageData={(newPictures) => setMarketplaceData(prevData => ({
          ...prevData,
          pictures: newPictures
        }))}
        modalId={`imageOrder${marketplaceName}Modal`}
      />

    </div>



  );
});

export default PhotoPreview;
