import React, { useState, useEffect } from 'react';
import { useUserAuth } from '../../context/UserAuthContext';
import { templateAPI } from '../../api';
import ItemSpecific from './ItemSpecific';
import ResetButton from './FormSimple/ResetButton';


const PoshmarkBrand = ({poshmarkBrand, setMarketplaceData}) => {
    const { user } = useUserAuth();
    const [poshmarkBrands, setPoshmarkBrands] = useState([]);

    function brandConvert(brand){

        const normalizedBrand = brand.toLowerCase().replace(/[^a-z0-9]+/g, '');
        for (const item of poshmarkBrands) {
            const normalizedItem = item.toLowerCase().replace(/[^a-z0-9]+/g, '');
            if (normalizedBrand === normalizedItem) {
              console.log('brand found! - ', item)
              return item;
            }
        }

        return brand;
    }

    useEffect(() => {
        if(poshmarkBrands.length > 0 && poshmarkBrand){
            const convertedBrand = brandConvert(poshmarkBrand);
            if(convertedBrand !== poshmarkBrand){
                setMarketplaceData(prevData => ({
                    ...prevData, 
                    brand: convertedBrand 
                }));
            }
        }
    }, [poshmarkBrands]);



    const fetchPoshmarkBrands = async () => {
        try {
            const token = await user.getIdToken(true);
            const data = await templateAPI.getPoshmarkBrands(token);
            setPoshmarkBrands(data);
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };

    useEffect(() => {
        fetchPoshmarkBrands();
    }, []);

    return(
        <div style={{ width: '100%' }}>
           <label htmlFor="poshmarkBrandKey" className="form-label mb-0" style={{ fontSize: '1.2em' }}>Brand</label>
            <ResetButton onReset={() => setMarketplaceData(prevData => {
                const updatedData = { ...prevData };
                delete updatedData.brand;  // Remove the brand property
                return updatedData;
            })} />

            <ItemSpecific 
                key="poshmarkBrandKey"
                specific={{
                    name: 'Brand',
                    rules: {isFreeText: true, isSingle: true, required: true},
                    values: poshmarkBrands
                }}
                type="required"
                initialValue={poshmarkBrand ? poshmarkBrand : null}
                setFormData={setMarketplaceData}
                suggestSpecifics={[]}
                title={false}
                fieldPath='brand'
            />
        </div>
    )
}

export default PoshmarkBrand;