import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthFooter from './AuthFooter';
import { useUserAuth } from '../../context/UserAuthContext';

//TODO: start using this? here?


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const { forgotPassword } = useUserAuth();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await forgotPassword(email);
      setMessage('Password reset email sent');
    } catch (err) {
      setError(err.message);
    }

  };

  return (
    <div id="layoutAuthentication" className="bg-primary">
      <div id="layoutAuthentication_content">
        <main>
          <div className="container-xl px-4">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                  <div className="card-header justify-content-center">
                    <h3 className="fw-light my-4">Password Recovery</h3>
                  </div>
                  <div className="card-body">
                    {message && <div className="alert alert-info">{message}</div>}
                    <div className="small mb-3 text-muted">Enter your email address and we will send you a link to reset your password.</div>
                    <form>
                        <div className="mb-3">
                            <label className="small mb-1" htmlFor="inputEmailAddress">Email</label>
                            <input className="form-control" id="inputEmailAddress" type="email" aria-describedby="emailHelp" placeholder="Enter email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                            <Link className="small" to="/login">Return to login</Link>
                            <button className="btn btn-primary" onClick={handleResetPassword}>Reset Password</button>
                        </div>
                    </form>
                  </div>
                  <div className="card-footer text-center">
                    <div className="small"><Link to="/signup">Need an account? Sign up!</Link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <AuthFooter />
    </div>
  );
};

export default ForgotPassword;
