import React from 'react';
import { useUserAuth } from '../../../context/UserAuthContext';
import Footer from '../../Helper/Footer';
import SettingsNav from './SettingsNav';



const SettingsSetup = () => {

  const { user } = useUserAuth();

  return (
    <>
    <main>
      <div className="container-xl px-4 mt-4">
        <SettingsNav />
        <div className="row">
          <div className="col-lg-4">

          </div>
          <div className="col-lg-8">
            <div className="card mb-4">
              <div className="card-header">Account Details</div>
              <div className="card-body">


              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
    </>
  );
};

export default SettingsSetup;

