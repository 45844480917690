import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { UserAuthContextProvider, useUserAuth } from './context/UserAuthContext';
import { userAPI } from './api'

//TODO: remove unused vars

// Components
import Onboard from './Components/Auth/Onboard';
import OnboardSetup from './Components/Auth/OnboardSetup';
import OnboardWaitlist from './Components/Auth/OnboardWaitlist';
import OnboardMain from './Components/Onboard/OnboardMain';
import Error from './Components/Auth/Error';
import Login from './Components/Auth/Login';
import ForgotPassword from './Components/Auth/ForgotPassword';
import Callback from './Components/Auth/Callback';
import CallbackDecline from './Components/Auth/CallbackDecline';
import LandingPage from './Components/Auth/LandingPage';
import Dashboard from './Components/Dashboard/Dashboard';
import FormSimple from './Components/Form/FormSimple/FormSimple';
import CrossListForm from './Components/Form/CrossListForm'
import BulkImageUpload from './Components/Dashboard/BulkImageUpload';
import Settings from './Components/Dashboard/Settings/Settings';
import Billing from './Components/Dashboard/Settings/Billing';
import NewMarketplaces from './Components/Dashboard/Settings/NewMarketplaces';
import SettingsMarketplaces from './Components/Dashboard/Settings/SettingsMarketplaces';
import SettingsImport from './Components/Dashboard/Settings/SettingsImport';
import SettingsSetup from './Components/Dashboard/Settings/SettingsSetup';
import SettingsPoshmarkAuth from './Components/Dashboard/Settings/SettingsPoshmarkAuth';
import SettingsPoshmark from './Components/Dashboard/Settings/SettingsPoshmark';
import SettingsMercari from './Components/Dashboard/Settings/SettingsMercari';
import Templates from './Components/Dashboard/Templates';
import Navbar from './Components/Dashboard/Navbar';
import SideNav from './Components/Dashboard/Sidenav.js';
import LoadingCenter from './Components/Helper/LoadingCenter';
import TermsOfService from './Components/Auth/Legal/TermsOfService';
import PrivacyPolicy from './Components/Auth/Legal/PrivacyPolicy';
import DataRequest from './Components/Auth/Legal/DataRequest';



const WithDashboardLayout = ({ children }) => {
  const { user, getValidToken } = useUserAuth();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); 

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    async function checkUserAccess() {
      if (!user) {
        timeoutId = setTimeout(() => {
          if (isMounted) {
            console.log('Timeout reached, no user found');
            navigate('/login');
          }
        }, 5000); // 5 seconds delay
        return;
      }

      clearTimeout(timeoutId);

      try {
        const token = await getValidToken();
        let userData; 

        if(token){
          userData = await userAPI.getUser(token);
          if (!userData.betaAccess) {
            window.location.href = 'https://allbutnew.com';
            return;
          }
          if (isMounted) {
            setIsLoading(false);
          }
        }
        
        console.log('userData - ', userData);
      } catch (error) {
        console.error('An error occurred while checking user data:', error);
        if (isMounted) {
          navigate('/login');
        }
      }
    }

    console.log('User - ', user);
    checkUserAccess();

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [user, navigate]);

  // if(!user){
  //   console.log('NO USER found !user flag');
  //   navigate('/login');
  //   return null;
  // }

  if (isLoading) {
    return <LoadingCenter />
  }

  return (
    <div className="nav-fixed">
      <Navbar />
      <div id="layoutSidenav">
        <div id="layoutSidenav_content">
          <main>{children}</main>
        </div>
      </div>
    </div>
  );
};


const DashboardRoutes = () => (
    <Routes>
      <Route path="/" element={<WithDashboardLayout><Dashboard /></WithDashboardLayout>} />
      <Route path="/dashboard" element={<Navigate to="/" replace />} />
      <Route path="/settings/*" element={<SettingsRoutes />} />
      <Route path="/listing/new" element={<WithDashboardLayout><CrossListForm isNew={true} /></WithDashboardLayout>} />
      <Route path="/listing/:id" element={<WithDashboardLayout><CrossListForm isNew={false} /></WithDashboardLayout>} />
      <Route path="/templates" element={<WithDashboardLayout><Templates /></WithDashboardLayout>} />
      <Route path="/bulk" element={<WithDashboardLayout><BulkImageUpload /></WithDashboardLayout>} />
      <Route path="*" element={<Error />} />
    </Routes>
);

const SettingsRoutes = () => {
  return (
    <Routes>
      <Route path="account" element={<WithDashboardLayout><Settings /></WithDashboardLayout>} />
      <Route path="billing" element={<WithDashboardLayout><Billing /></WithDashboardLayout>} />
      <Route path="marketplaces" element={<WithDashboardLayout><NewMarketplaces /></WithDashboardLayout>} />
      <Route path="import" element={<WithDashboardLayout><SettingsImport /></WithDashboardLayout>} />
      <Route path="defaults" element={<WithDashboardLayout><Settings /></WithDashboardLayout>} />
      <Route path="setup" element={<WithDashboardLayout><SettingsSetup /></WithDashboardLayout>} />
      <Route path="merc" element={<WithDashboardLayout><SettingsMercari /></WithDashboardLayout>} />
      <Route path="posh" element={<WithDashboardLayout><SettingsPoshmark /></WithDashboardLayout>} />
    </Routes>
  );
}




function App() {
  return (
    <UserAuthContextProvider>
      <Router>
        <Routes>
          <Route path="/signup" element={<Onboard />} />
          <Route path="/onboard/setup" element={<OnboardMain />} />
          <Route path="/onboard/waitlist" element={<OnboardWaitlist />} />
          <Route path="/onboard/main" element={<OnboardMain />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/e/callback" element={<Callback />} /> {/* ebay callback */}
          <Route path="/e/decline" element={<CallbackDecline />} /> {/* ebay decline */}
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<DashboardRoutes />} />
        </Routes>
      </Router>
    </UserAuthContextProvider>
  );
}

export default App;
