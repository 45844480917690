import React, { useState } from 'react';

const SingleSuggestButton = ({ value, selected, specific, setSelectedSpecifics }) => {
    const [isHovered, setIsHovered] = useState(false);
    const getButtonClass = () => {
        if (selected) {
          return isHovered ? 'btn-danger' : 'btn-primary';
        }
        return isHovered ? 'btn-primary' : 'btn-outline-primary';
    };
  
    const handleClick = () => {
        console.log(`Clicked on: ${value} at ${specific}`);
    
        setSelectedSpecifics(prevSelectedSpecifics => {
            const array = prevSelectedSpecifics[specific] || [];
            if (array.includes(value)) {   // Remove the value if it already exists
                return {
                    ...prevSelectedSpecifics,
                    [specific]: array.filter(item => item !== value)
                };
            } else {   // Add the value if it does not exist
                return {
                    ...prevSelectedSpecifics,
                    [specific]: [...array, value]
                };
            }
        });
    };
    

    const handleMouseOver = () => {
        setIsHovered(true);
      };

    const handleMouseOut = () => {
        setIsHovered(false);
    };
  
    return (
      <button 
        className={`btn ${getButtonClass()} btn-sm mb-1`} 
        style={{ marginRight: '2px' }}
        onMouseOver={handleMouseOver} 
        onMouseOut={handleMouseOut}
        onClick={handleClick}
    >
        {value}
      </button>
    );
}


export default SingleSuggestButton;