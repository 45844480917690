import React from 'react';

const ConnectButton = ({ marketplace, onClick, loading = false, location = 'settings' }) => (
    <div>
        <button 
            className={location === 'onboard' ? 'btn btn-primary' : 'btn btn-light'}
            onClick={onClick} 
            disabled={loading}
        >
            {loading ? (
                <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Redirecting...
                </>
            ) : (
                `Connect ${marketplace}`
            )}
        </button>
    </div>
);

export default React.memo(ConnectButton);