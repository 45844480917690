import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAPI } from '../../api';
import LoadingCenter from '../Helper/LoadingCenter';

const Callback = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const navigate = useNavigate(); 

  useEffect(() => {
    const sendCodeToBackend = async () => {
      try {
        console.log('sendCodeToBackend code - ', code);
        console.log('sendCodeToBackend state - ', state);
        const response = await userAPI.ebayCallback({ code, state });
        navigate('/onboard/setup', { state: { ebayUsername: response.ebayUsername, message: response.message } });
      } catch (error) {
        console.error('An error occurred while processing the eBay callback:', error);
        navigate('/error', { state: { errorMessage: `An error occurred while processing the eBay callback: ${error?.response?.data?.message}` } });
      }
    
    };

    sendCodeToBackend();
  }, [code, state]);

  // You can display a loading spinner or a specific message while processing
  return (
    <LoadingCenter />
  );
};

export default Callback;
