import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
                <div className="row">
                    <div className="col-md-6 small">Copyright © FlipperTools {currentYear}</div>
                    <div className="col-md-6 text-md-end small">
                        <a href="https://flippertools.com/privacy-policy">Privacy Policy</a>
                        &middot;
                        <a href="https://flippertools.com/disclaimers">Terms &amp; Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
