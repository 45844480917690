import React, { useState, useEffect } from "react";
import FormSize from './FormSize';
import FormColor from './FormColor';
import FormCondition from './FormCondition';
import FormBrand from './FormBrand';

const FormItemDetails = ({formData, setFormData, templates}) => {
    const [suggestedTitles, setSuggestedTitles] = useState([]);
    const [allTitles, setAllTitles] = useState(templates.titles || []); 
    const [suggestKeywords, setSuggestKeywords] = useState([]);
    const [allKeywords, setAllKeywords] = useState({});
    const maxCharCount = 80;


    //TODO: set brand if not already when templates has a value (from Basic Search)


    useEffect(() => {
        if (suggestedTitles.length > 0 && formData?.title?.length > 1) {
          setSuggestedTitles([]); // Clear suggestedTitles
        }
      }, [formData.title, suggestedTitles]);

    const getTopKeywordsNotInTitle = (title, keywords = allKeywords) => {
        if (Object.keys(allKeywords).length === 0) {
            return [];
        }

        const titleWords = title.toLowerCase().split(' ').map(word => word.trim());
        const keywordsNotInTitle = Object.keys(keywords)
            .filter(keyword => !titleWords.includes(keyword.toLowerCase()))
            .filter(keyword => keyword.trim() !== "")
            .sort((a, b) => templates.keywords[b] - templates.keywords[a]) 
            .slice(0, 10); 

        return keywordsNotInTitle;
    };

    useEffect(() => {
        if (templates && templates.titles) {
            const firstFiveTitles = templates.titles.slice(0, 5);
            setSuggestedTitles(firstFiveTitles);
            setAllTitles(templates.titles); 
        }
    
        if (templates && templates.keywords) {
            setAllKeywords(templates.keywords);
            const topKeywords = getTopKeywordsNotInTitle(formData.title || '', templates.keywords);
            setSuggestKeywords(topKeywords);    
        }

        if(templates && templates.itemSpecifics && templates.itemSpecifics.Brand){
            //Set Brand value if templates finds a majority option
            const threshold = templates.totalResults * 0.5;
            Object.entries(templates.itemSpecifics.Brand).forEach(([brand, value]) => {
                if (value > threshold) {
                    setFormData(prevData => ({
                        ...prevData,
                        brand: brand
                    }));
                }
            });
        }

    }, [templates]);
    
    
    const loadMoreTitles = () => {
        const currentLength = suggestedTitles.length;
        const additionalTitles = allTitles.slice(currentLength, currentLength + 5);
        setSuggestedTitles(prevTitles => [...prevTitles, ...additionalTitles]);
    };
    
    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        const topKeywords = getTopKeywordsNotInTitle(newTitle);
        setFormData(prevData => ({
            ...prevData,
            title: newTitle
        }));
        setSuggestKeywords(topKeywords);
    };

    const handleSuggestTitle = (title) => {
        setFormData(prevData => ({
            ...prevData,
            title: title
        }));
        const topKeywords = getTopKeywordsNotInTitle(title);
        setSuggestKeywords(topKeywords);
        setSuggestedTitles([]);
    }

    const addKeywordToTitle = (keyword) => {
        let newTitle = formData.title;
    
        if (!formData.title.toLowerCase().includes(keyword.toLowerCase())) {
            newTitle = formData.title.trim() + (formData.title ? ' ' : '') + keyword;
            setFormData(prevData => ({
                ...prevData,
                title: newTitle
            }));
        }
    
        const newTopKeywords = getTopKeywordsNotInTitle(newTitle);
        setSuggestKeywords(newTopKeywords);
    };

    const charCount = formData.title ? formData.title.length : 0;
    const charCountClass = (charCount > maxCharCount) ? 'text-danger' : 'text-muted';

    return (
        <div className="card mb-2">
            <div className="card-header">Item Details</div>
            <div className="card-body">
                <div className="mb-1">
                    <label htmlFor="itemTitle" className="form-label mb-0" style={{ fontSize: '1.2em' }}>Title</label>
                    <input
                        type="text"
                        className="form-control"
                        id="itemTitle"
                        value={formData.title}
                        onChange={handleTitleChange}
                        placeholder="Enter title"
                    />
                    <div style={{ fontSize: '0.8em' }} className={`mt-1 text-end ${charCountClass}`}>
                        {`${formData.title ? formData.title.length : 0}/${maxCharCount}`}
                    </div>

                    {
                        suggestedTitles.length > 0 && (
                            <div className="mt-2">
                                {suggestedTitles.map((suggestedTitle, index) => (
                                    <button
                                        key={index}
                                        className="btn btn-outline-primary me-2 mb-2"
                                        onClick={() => handleSuggestTitle(suggestedTitle)}
                                    >
                                        {suggestedTitle}
                                    </button>
                                ))}

                                {/* Load More Button */}
                                {suggestedTitles.length < allTitles.length && (
                                    <div className="text-center mt-1 mb-2">
                                        <button 
                                            className="btn btn-outline-primary"
                                            onClick={loadMoreTitles}
                                        >
                                            Load More
                                        </button>
                                    </div>
                                )}
                            </div>
                        )
                    }

                    
                    <div className="mt-1">
                        {suggestKeywords.map((keyword, index) => (
                            <button
                                key={index}
                                className="btn btn-sm btn-outline-secondary me-2 mb-1"
                                onClick={() => addKeywordToTitle(keyword)}
                            >
                                {keyword}
                            </button>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <FormSize 
                                formDataSize={formData.size}
                                setFormData={setFormData}
                            />
                        </div>
                        <div className="col-7">
                            <FormColor 
                                formDataColor={formData.color}
                                setFormData={setFormData}
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                            <FormBrand 
                                formDataBrand={formData.brand}
                                setFormData={setFormData}
                            />
                        <div className="col-7">
                            <FormCondition 
                                formConditionId={formData.conditionId}
                                setFormData={setFormData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormItemDetails;