// NewMarketplaces.js
import React, { useState, useEffect } from 'react';
import GeneralSettingsCard from './GeneralSettingsCard';
import EbaySettingsCard from './EbaySettingsCard'; 
import PoshmarkSettingsCard from './PoshmarkSettingsCard';
import SettingsNav from './SettingsNav';
import Footer from '../../Helper/Footer';
import MarketplaceConnections from './MarketplaceConnections';
import { useUserAuth } from '../../../context/UserAuthContext';
import { settingAPI } from '../../../api';


//TODO: post lunch: submit defaults.

const NewMarketplaces = () => {
    const { user } = useUserAuth();
    const [connectionData, setConnectionData] = useState({});
    const [loading, setLoading] = useState(true);
    const [settingsData, setSettingsData] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false);
    const [settingsDataSaved, setSettingsDataSaved] = useState(false);
    // create a settingsDataChanged ref that checks for changes to settingsData after it is saved

    const updateSettings = async () => {
        setSubmitLoading(true);
        try {
            const token = await user.getIdToken(true);
            let settingsResponse
            if(settingsData._id){
                settingsResponse = await settingAPI.updateDefaults(token, settingsData);
            }else{
                settingsResponse = await settingAPI.createDefaults(token, settingsData);
            }
            console.log('settingsResponse - ', settingsResponse);
            setSettingsDataSaved(true);
            setSettingsData(settingsResponse.defaults);
        } catch (error) {
            // Handle error (e.g., show an error message)
            console.error("Error updating settings:", error);
        } finally {
            setSubmitLoading(false);
        }
    };
    
    useEffect(() => {
        const fetchDefaults = async () => {
            try {
                const token = await user.getIdToken(true);
                const settingsResponse = await settingAPI.getDefaults(token);
                console.log('settingsResponse - ', settingsResponse);
                setSettingsData(settingsResponse);
            } catch (err) {
                console.log('err found - ', err);
            }
        };
        fetchDefaults();
    }, []);


    useEffect(() => {
        const fetchMarketplaceConnection = async () => {
            try {
                setLoading(true);
                const token = await user.getIdToken(true);
                const connectionResponse = await settingAPI.getMarketplaceConnections(token);
                console.log('connectionResponse - ', connectionResponse);
                setConnectionData(connectionResponse);
            } catch (err) {
                console.log('err found - ', err);
            } finally {
                setLoading(false);
            }
        };
        fetchMarketplaceConnection();
    }, []);


    const handleChange = (e, path) => {
        const { value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
    
        setSettingsData(prevData => {
            // Function to set value in nested object by path
            const setValue = (data, path, value) => {
                const keys = path.split('.');
                const lastKey = keys.pop();
                const lastObj = keys.reduce((obj, key) => obj[key] = obj[key] || {}, data);
                lastObj[lastKey] = value;
                return data;
            };
    
            // Return the updated state
            return setValue({...prevData}, path, newValue);
        });
    };
    

    return (
        <>
        <main>
            <div className="container-xl px-4 mt-4">
                <SettingsNav />
                <div className="row">
                    <div className="col-lg-4">
                        <MarketplaceConnections 
                            connectionData={connectionData}
                            loading={loading}
                        />
                    </div>
                    <div className="col-lg-8">
                        <GeneralSettingsCard
                            settingsDataGeneral={settingsData.general || {}}
                            handleChange={handleChange}
                            updateSettings={updateSettings}
                            settingsDataSaved={settingsDataSaved}
                            submitLoading={submitLoading}
                        />
                        <EbaySettingsCard 
                            ebayConnectionData={connectionData.ebay}
                            settingsDataEbay={settingsData.ebay || {}}
                            handleChange={handleChange}
                            updateSettings={updateSettings}
                            settingsDataSaved={settingsDataSaved}
                            submitLoading={submitLoading}
                        />
                        <PoshmarkSettingsCard 
                            settingsDataPoshmark={settingsData.poshmark || {}}
                            setSettingsData={setSettingsData}
                            handleChange={handleChange}
                            updateSettings={updateSettings}
                            settingsDataSaved={settingsDataSaved}
                            submitLoading={submitLoading}
                        />
                    </div>
                </div>
            </div>
        </main>
        <Footer />
        </>
    );
};

export default NewMarketplaces;
