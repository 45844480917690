import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { templateAPI  } from '../../../api';
import { useUserAuth } from '../../../context/UserAuthContext';
import { debounce } from 'lodash';
import ResetButton from './ResetButton';

//TODO: way to select Category from parent by click in Search All Tab.

const CategoryFilter = ({setFormData, selectedOption, setSelectedOption, setActiveTab}) => {
    const { user } = useUserAuth();
    const [categoriesAll, setCategoriesAll] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const token = await user.getIdToken(true);
                const fetchedCategories = await templateAPI.getCategories(token);
                setCategoriesAll(fetchedCategories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, [user]);

    const generateOptions = (categoryId, prefix = '') => {
        const category = categoriesAll.categoriesById[categoryId];
        // Initialize an empty array for options
        let options = [];
        // Check if the category is a terminal/leaf category (i.e., has no children)
        if (!category.children || category.children.length === 0) {
            // Add the category to the options
            options.push({
                value: category.id,
                label: prefix + category.name
            });
        } else {
            // If the category has children, recursively generate options for the children
            for (let childId of category.children) {
                options = options.concat(generateOptions(childId, prefix + category.name + ' > '));
            }
        }
        return options;
    };
    
    useEffect(() => {
        if (categoriesAll.categoriesById && categoriesAll.rootCategories) {
            let allOptions = [];
            for (let rootId of categoriesAll.rootCategories) {
                allOptions = allOptions.concat(generateOptions(rootId));
            }
            setOptions(allOptions);
        }
    }, [categoriesAll]);

    const handleCategoryChange = (selectedOption) => {
        console.log('selectedOption - ', selectedOption);
        setSelectedOption(selectedOption);
        setFormData(prevFormData => ({
            ...prevFormData,
            categoryId: selectedOption.value,
            categoryName: selectedOption.label
        }));
        setActiveTab('selected');
    };

    const loadOptions = debounce((inputValue, callback) => {
        let fetchedOptions;
    
        if (inputValue) {
            const inputWords = inputValue.toLowerCase().split(/\s+/);
    
            fetchedOptions = options.filter(category => {
                const categoryWords = category.label.toLowerCase().split(/\s+/);
                return inputWords.every(inputWord => 
                    categoryWords.some(categoryWord => categoryWord.includes(inputWord))
                );
            }).slice(0, 50);
        } else {
            fetchedOptions = []; // Or some default options
        }
    
        callback(fetchedOptions);
    }, 300);
    

    const defaultCategories = () => {
        const defaultCategoryIds = ["15687", "57990", "57991", "185101", "3009", "11514", "11524", "11554", "11555", "53159", "63861", "63862", "63863", "63864", "63865", "63866", "63867", "169001", "185098", "260011"];
        const optionsMap = new Map(options.map(option => [option.value, option]));
        return defaultCategoryIds.map(id => optionsMap.get(id)).filter(category => category !== undefined);
    };
    
    
    return (
        <>
            <AsyncSelect 
                loadOptions={loadOptions}
                defaultOptions={defaultCategories()}
                onChange={handleCategoryChange}
                value={selectedOption}
                isSearchable={true}
                placeholder="Search and select a category..."
            />
        </>
    );
};

const CategorySelector = ({formDataCategoryId, formDataCategoryName, formDataMode, setFormData, templates}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [quickSelectCategories, setQuickSelectCategories] = useState([]);
    const [suggestedCategories, setSuggestedCategories] = useState([]);
    const [activeTab, setActiveTab] = useState('quickSelect');

    useEffect(() => {
        if (formDataCategoryId) {
            setActiveTab('selected');
        }
    }, []);

    useEffect(() => {
        if (formDataMode === 'loaded' && formDataCategoryId) {
            setActiveTab('selected');
        }
    }, [formDataMode]);

    const categories = [
        {displayName: "Clothing > Men's > Casual Button-Downs", fullText: "Clothing, Shoes & Accessories > Men > Men's Clothing > Shirts > Casual Button-Down Shirts", id: "57990", color: 'rgba(173, 216, 230, 0.2)'},
        {displayName: "Clothing > Men's > Coats, Jackets & Vests", fullText: "Clothing, Shoes & Accessories > Men > Men's Clothing > Shirts > Coats, Jackets & Vests", id: "57988", color: 'rgba(173, 216, 230, 0.2)'},
        {displayName: "Clothing > Women's > Tops", fullText: "Clothing, Shoes & Accessories > Women > Women's Clothing > Tops", id: "53159", color: 'rgba(255, 182, 193, 0.2)'},
        {displayName: "Clothing > Men's > Dress Shirts", fullText: "Clothing, Shoes & Accessories > Men > Men's Clothing > Shirts > Dress Shirts", id: "57991", color: 'rgba(173, 216, 230, 0.2)'},
        {displayName: "Clothing > Men's > Polos", fullText: "Clothing, Shoes & Accessories > Men > Men's Clothing > Shirts > Polos", id: "185101", color: 'rgba(173, 216, 230, 0.2)'}
    ]

    useEffect(() => {
        setQuickSelectCategories(categories);
    }, []);

    useEffect(() => {
        if (templates && templates.categorySuggest) {

            const transformedCategories = templates.categorySuggest.map(category => ({
                displayName: category.displayName, 
                fullText: category.treeDisplayText,
                id: category.categoryId,
                color: `rgba(173, 216, 230, ${category.itemPercentage})` 
            }));

            if(!formDataCategoryId || activeTab === 'selected') { setActiveTab('suggested');}
            setSuggestedCategories(transformedCategories);
        }
    }, [templates]);
    

    const selectCategory = (category) => {
        console.log('selectCategory category - ', category);
        setFormData(prevFormData => ({
            ...prevFormData,
            categoryId: category.id,
            categoryName: category.fullText
        }));
        setSelectedOption(null);
        setActiveTab('selected')
    };

    const handleResetCategory = () => {
        setSelectedOption(null);
        setActiveTab('quickSelect');
    }

    const renderCategories = (categories) => {
        return categories.slice(0, 5).map((category, index) => {
            return (
                <button
                    key={index}
                    className="btn btn-outline-primary me-2"
                    onClick={() => selectCategory(category)}
                    style={{ width: '120px', minHeight: '120px', backgroundColor: category.color }}
                >
                    {category.displayName}
                </button>
            );
        });
    };

    return (
        <div className="card mb-2">
            <div className="card-header">Category</div>
            <div className="card-body">
                {/* Tabbed Navigation */}
                <ul className="nav nav-tabs mb-2" id="categoryTab" role="tablist">
                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 'selected' ? 'active' : ''}`}
                            onClick={() => setActiveTab('selected')}
                            disabled={!formDataCategoryId}
                            title={!formDataCategoryId ? "The category you select will be shown here." : ""}
                        >
                            Selected
                        </button>
                    </li>

                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 'quickSelect' ? 'active' : ''}`}
                            onClick={() => setActiveTab('quickSelect')}
                        >
                            Quick Select
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 'suggested' ? 'active' : ''}`}
                            onClick={() => setActiveTab('suggested')}
                            disabled={suggestedCategories.length === 0}
                            title={suggestedCategories.length === 0 ? "Enter a basic search above to see Suggested" : ""}
                        >
                            Suggested
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link ${activeTab === 'searchAll' ? 'active' : ''}`}
                            onClick={() => setActiveTab('searchAll')}
                        >
                            Search All
                        </button>
                    </li>
                    
                </ul>

                {activeTab === 'selected' && (
                    <>
                        {formDataCategoryName && (
                            <button
                                key="Selected"
                                className="btn btn-primary me-5"
                                style={{ width: '200px', minHeight: '120px' }}
                            >
                                {formDataCategoryName}
                            </button>
                        )}
                        <ResetButton onReset={handleResetCategory} />
                    </>
                )}

                {activeTab === 'quickSelect' && (
                    <>{renderCategories(quickSelectCategories)}</>
                )}

                {activeTab === 'suggested' && (
                    <>{renderCategories(suggestedCategories)}</>
                )}

                {activeTab === 'searchAll' && (
                    <div className="container">
                        <CategoryFilter
                            setFormData={setFormData}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            setActiveTab={setActiveTab}
                        />
                    </div>
                )}



            </div>
        </div>
    );
};

export default CategorySelector;