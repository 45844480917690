import axios from 'axios';

// Base URL for the image-related endpoints
const baseURL = `${process.env.REACT_APP_ENDPOINT}/images`;

//TODO: wrap in try, catch block?

export const bulkCreate = async (token, imageData) => {
  const response = await axios.post(`${baseURL}/bulk`, imageData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export const bulkUpdate = async (token, images) => {
  const response = await axios.put(`${baseURL}/bulk`, images, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export const bulkDelete = async (token, imageIds) => {
  const response = await axios.delete(`${baseURL}/bulk`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: { imageIds }
  });
  return response.data;
}

export const createImage = async (token, imageData) => {
  const response = await axios.post(baseURL, imageData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export const updateImage = async (token, imageId, imageData) => {
  const response = await axios.put(`${baseURL}/${imageId}`, imageData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export const deleteImage = async (token, imageId) => {
  const response = await axios.delete(`${baseURL}/${imageId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};


