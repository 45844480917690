import axios from 'axios';

// Base URL for the product-related endpoints
const baseURL = `${process.env.REACT_APP_ENDPOINT}`;


export const submitPoshmarkAuth = async (token, username, password) => {
    const response = await axios.post(`${baseURL}/sharer/auth`, 
        { username, password },  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
};

export const getPoshmarkAuth = async (token) => {
    const response = await axios.get(`${baseURL}/sharer/auth`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const startSharing = async (token) => {
    const response = await axios.get(`${baseURL}/sharer/share`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const getSharerLogs = async (token) => {
    const response = await axios.get(`${baseURL}/sharer/logs`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const getSharerLogsTotal = async (token) => {
    const response = await axios.get(`${baseURL}/sharer/logs/total`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export const startRelist = async (token, itemId) => {
    const response = await axios.post(`${baseURL}/sharer/relist/${itemId}`, {},  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
};

export const startCrosslist = async (token, ebayItemId) => {
    const response = await axios.post(`${baseURL}/sharer/crosslist/${ebayItemId}`, {},  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
};

export const startList = async (token, productData) => {
    const response = await axios.post(`${baseURL}/sharer/list`, 
        { productData },  
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
};

export const catalogItem = async (token, itemId) => {
    const response = await axios.post(`${baseURL}/sharer/catalog/${itemId}`, {},  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
};

export const refreshCloudAction = async (token, cloudActionId) => {
    const response = await axios.get(`${baseURL}/sharer/action/${cloudActionId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
}
