import React from "react";

const ShipPriceDetails = ({formData, setFormData}) => {

    const handleInputChange = (e, inputType = null) => {
        const { name, id, value } = e.target; 
        const key = name || id;

        if(inputType === 'packageDetails'){
            setFormData(prevData => ({
                ...prevData,
                packageDetails: {
                    ...prevData.packageDetails,
                    [key]: value
                }
            }));
        }else{
            setFormData(prevData => ({
                ...prevData,
                [key]: value, 
            }));
        }
        
    };
    

    return (
        <div className="card card-collapsable mb-2" id="listing-details">
            <a className="card-header" href="#collapseListingDetails" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseListingDetails">
                Listing Details
                <div className="card-collapsable-arrow">
                    <i className="fas fa-chevron-down"></i>
                </div>
            </a>
            <div className="collapse show" id="collapseListingDetails">
                <div className="card-body">
                    <form>
                        <div className="row mb-3">
                            <label htmlFor="sku" className="col-sm-2 col-form-label">SKU</label>
                            <div className="col-sm-4">
                                <input key="uniqueSkuKey" type="text" className="form-control" id="sku" value={formData.sku ? formData.sku : ''} onChange={handleInputChange} />
                            </div>
                            <label htmlFor="zipCode" className="col-sm-2 col-form-label">ZIP Code</label>
                            <div className="col-sm-4">
                                <input key="zipCodeKey" type="text" className="form-control" id="locationPostalCode" value={formData.locationPostalCode ? formData.locationPostalCode : ''} onChange={handleInputChange}  />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="col-sm-2 col-form-label">Weight</label>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" name="weightMajor" value={formData.packageDetails?.weightMajor ? formData.packageDetails?.weightMajor : ''} placeholder="lbs" onChange={(e) => handleInputChange(e, 'packageDetails')}/>
                            </div>
                            <div className="col-sm-2">
                                <input type="text" className="form-control" name="weightMinor" value={formData.packageDetails?.weightMinor ? formData.packageDetails?.weightMinor : ''} placeholder="ozs" onChange={(e) => handleInputChange(e, 'packageDetails')}/>
                            </div>

                            <label className="col-sm-2 col-form-label">Dimensions</label>
                            <div className="col-sm-1" style={{ padding: 0, marginLeft: '12px' }}>
                                <input 
                                    type="text" 
                                    name="packageDepth"
                                    className="form-control" 
                                    placeholder="in." 
                                    value={formData.packageDetails?.packageDepth ? formData.packageDetails?.packageDepth : ''}
                                    style={{ textAlign: 'center', minWidth: '46px' }} 
                                    onChange={(e) => handleInputChange(e, 'packageDetails')}
                                />
                            </div>
                            <div className="col-sm-1" style={{ padding: 0 }}>
                                <input 
                                    type="text" 
                                    name="packageLength"
                                    className="form-control" 
                                    placeholder="in."
                                    value={formData.packageDetails?.packageLength ? formData.packageDetails?.packageLength : ''}
                                    style={{ textAlign: 'center',  minWidth: '46px' }} 
                                    onChange={(e) => handleInputChange(e, 'packageDetails')}
                                />
                            </div>
                            <div className="col-sm-1" style={{ padding: 0 }}>
                                <input 
                                    type="text" 
                                    name="packageWidth"
                                    className="form-control" 
                                    placeholder="in."
                                    value={formData.packageDetails?.packageWidth ? formData.packageDetails?.packageWidth : ''}
                                    style={{ textAlign: 'center', minWidth: '46px' }} 
                                    onChange={(e) => handleInputChange(e, 'packageDetails')}
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="price" className="col-sm-2 col-form-label">Price</label>
                            <div className="col-sm-4">
                                <input key="priceKey" type="text" className="form-control" id="price" value={formData.price ? formData.price : ''} onChange={handleInputChange}  />
                            </div>

                            <label htmlFor="quantity" className="col-sm-2 col-form-label">Quantity</label>
                            <div className="col-sm-1">
                                <input key="quantityKey" type="text" className="form-control" id="quantity" value={formData.quantity ? formData.quantity : ''} onChange={handleInputChange} style={{ textAlign: 'center', minWidth: '80px' }} />
                            </div>
                            
                        </div>
                        

                    </form>
                </div>
            </div>
        </div>
    )

}

export default ShipPriceDetails;