import axios from 'axios';

// Base URL for the product-related endpoints
const baseURL = `${process.env.REACT_APP_ENDPOINT}/listings`;

// const makeRequestWithRetry = async (url, options, retries = 3) => {
//   try {
//     const response = await axios(url, options);
//     return response.data;
//   } catch (error) {
//     if (retries === 0) {
//       throw new Error(`Max retries reached for request: ${error.message}`);
//     }
//     console.log(`Retrying request, attempts left: ${retries}`);
//     return makeRequestWithRetry(url, options, retries - 1);
//   }
// };

export const readListings = async (token, options = {}) => {
  const response = await axios.get(`${baseURL}/general`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: options
  });
  return response.data;
};

export const createGeneralListing = async (token, listingData) => {
  const response = await axios.post(`${baseURL}/general`, 
  { listingData: listingData }, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const readGeneralListing = async (token, generalListingId) => {
  const response = await axios.get(`${baseURL}/general/${generalListingId}`, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const getNewGeneralListing = async (token) => {
  const response = await axios.get(`${baseURL}/general/new`, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export const updateGeneralListing = async (token, listingData, generalListingId) => {
  const response = await axios.put(`${baseURL}/general/${generalListingId}`, 
  { listingData: listingData }, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

// Uses generalListingId, marketplaceName & marketplaceListingId to tell API what to delete
// (either eBay with API or cloudAction for other)
export const deleteMarketplaceListing = async (token, generalListingId, marketplaceName) => {
  const response = await axios.post(`${baseURL}/general/${generalListingId}/${marketplaceName}`, 
  {},
  {
    headers: {
      Authorization: `Bearer ${token}`
    },
  });
  return response.data;
};

export const createMarketplaceListing = async (token, marketplaceData, formData) => {
  const response = await axios.post(`${baseURL}/marketplace/listing`, 
  { marketplaceData: marketplaceData,
    formData: formData }, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const updateMarketplaceListing = async (token, marketplaceData, formData) => {
  console.log('UPDATE route marketplaceData.id - ', marketplaceData.id);
  
  const response = await axios.put(`${baseURL}/marketplace/listing/${marketplaceData.id}`, 
  { marketplaceData: marketplaceData,
    formData: formData }, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const createEbaySubmit = async (token, ebayData, formData) => {
  const response = await axios.post(`${baseURL}/marketplace/ebay/submit`, 
  { ebayData: ebayData,
    formData: formData }, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const readImportEbayActive = async (token, options = {}) => {
  const response = await axios.get(`${baseURL}/import/ebay/active`, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: options
  });
  return response.data;
};

export const readImportListing = async (token, itemId) => {
  const response = await axios.get(`${baseURL}/import/ebay/check/${itemId}`, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export const createImportProduct = async (token, item) => {
  const response = await axios.post(`${baseURL}/import/ebay`, 
  { item: item }, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}



// OLD ROUTES - Updating in /listings refactoring

export const fetchProducts = (token, options = {}) => {
  return axios.get(`${baseURL}/dashboard`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: options
  })
  .then(response => response.data);
};

export const deleteProduct = (token, id) => {
  return axios.delete(`${baseURL}/delete/${id}`, {
    headers: { Authorization: `Bearer ${token}` }
  })
  .then(response => response.data);
};

export const searchProducts = (token, searchTerm) => {
  return axios.get(`${baseURL}/search`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      term: searchTerm 
    }
  })
  .then(response => response.data);
}

export const getDraft = (token, id) => {
  return axios.get(`${baseURL}/draft`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: { id } 
  })
  .then(response => response.data);
}

export const getImportTotal = (token) => {
  return axios.get(`${baseURL}/import/total`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
};

export const getImportedTotal = (token) => {
  return axios.get(`${baseURL}/imported/total`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
};

export const getImport = (token, options) => {
  return axios.get(`${baseURL}/import`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: options
  })
  .then(response => response.data);
};

export const getListingDefault = (token) => {
  return axios.get(`${baseURL}/defaults/ebay`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
};

export const postEbayDefault = (token, defaults) => {
  return axios.post(`${baseURL}/defaults/ebay`, defaults, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
};



export const saveOrUpdateGeneralDraft = (token, formData, isUpdate) => {
  return axios.post(`${baseURL}/general/draft`, 
  { generalDraft: formData, isUpdate: isUpdate }, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
};

export const postDraftBulk = (token, productData) => {
  return axios.post(`${baseURL}/draft/bulk`, productData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
}

export const postSubmitListing = (token, ebayData, formData, isUpdate) => {
  return axios.post(`${baseURL}/submit/ebay`, {ebayData, formData, isUpdate}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);

}

export const postDraftEbayListing = (token, ebayData, formData) => {
  return axios.post(`${baseURL}/ebay/draft`, {ebayData, formData}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then(response => response.data);
}

