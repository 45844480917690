import React, { useState, useEffect } from 'react';
import { useUserAuth } from '../../context/UserAuthContext';
import { templateAPI } from '../../api';
import CollapsableCard from '../Helper/CollapsableCard';
import FormColor from './FormSimple/FormColor';
import ItemSpecific from './ItemSpecific';
import ResetButton from './FormSimple/ResetButton';
import MarketplaceCondition from './MarketplaceCondition';
import MercariSize from './MercariSize';


const MercariBrand = ({mercariBrand, setMarketplaceData}) => {
    const { user } = useUserAuth();
    const [marketplaceBrands, setMarketplaceBrands] = useState([]);
    const [brandNotFound, setBrandNotFound] = useState(null);

    //BrandConvert: happens here since marketplace brands are loaded here
    function brandConvert(brand) {
        const normalizedBrand = brand.toLowerCase().replace(/[^a-z0-9]+/g, '');
        console.log('normalizedBrand - ', normalizedBrand);

        for (const item of marketplaceBrands) {
            const normalizedItem = item.toLowerCase().replace(/[^a-z0-9]+/g, '');
            if (normalizedBrand === normalizedItem) {
                console.log('brand found - ', normalizedItem);
                return { found: true, brand: item }; // Brand found
            }
        }
        return { found: false, brand: "No brand/Not sure" }; // Brand not found
    }

    
    useEffect(() => {
        if (mercariBrand && !brandNotFound && marketplaceBrands.length > 0) {
            const conversionResult = brandConvert(mercariBrand);
            if (!conversionResult.found) {
                console.log('BRAND not found');
                // Brand not found
                setBrandNotFound(mercariBrand);  // Set the not found brand
                setMarketplaceData(prevData => ({
                    ...prevData,
                    brand: conversionResult.brand  // Set to "No Brand/Not Sure"
                }));
            } else {
                console.log('BRAND found');
                // Brand found - reset the not found flag if previously set
                if (brandNotFound !== null) {
                    setBrandNotFound(null);
                }
                
                if(conversionResult.brand !== mercariBrand){
                    setMarketplaceData(prevData => ({
                        ...prevData,
                        brand: conversionResult.brand
                    }));
                }
            }
        }
    }, [marketplaceBrands, mercariBrand]);
    

    const fetchMarketplaceBrands = async () => {
        try {
            const token = await user.getIdToken(true);
            const data = await templateAPI.getMercariBrands(token);
            setMarketplaceBrands([...data, 'No brand/Not sure']);
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };

    useEffect(() => {
        if (Object.keys(marketplaceBrands).length === 0) {
            fetchMarketplaceBrands();
        }
    }, []);

    return(
        <div style={{ width: '100%' }}>
           <label htmlFor="mercariBrandKey" className="form-label mb-0" style={{ fontSize: '1.2em' }}>Mercari Brand</label>
            <ResetButton onReset={() => setMarketplaceData(prevData => {
                const updatedData = { ...prevData };
                delete updatedData.brand;  // Remove the brand property
                return updatedData;
            })} />

            <ItemSpecific 
                key="mercariBrandKey"
                specific={{
                    name: 'Brand',
                    rules: {isFreeText: false, isSingle: true, required: true},
                    values: marketplaceBrands
                }}
                type="required"
                initialValue={mercariBrand ? mercariBrand : null}
                setFormData={setMarketplaceData}
                suggestSpecifics={[]}
                title={false}
                fieldPath='brand'
            />

            <p style={{ marginTop: '10px' }}>
                Not in list? <a onClick={() => setMarketplaceData(prevData => ({
                    ...prevData,
                    brand: "No brand/Not sure"
                }))} style={{ color: 'blue', cursor: 'pointer' }}>No brand/Not sure</a>
            </p>

            {/* Displaying message if brandNotFound is not null */}
            {brandNotFound && (
                <p style={{ color: 'red' }}>
                    {brandNotFound} is not in the Mercari Brands list.
                </p>
            )}

        </div>
    )
}

const MercariItemDetails = ({ mercariSize, categorySizeCharts, mercariCategory, mercariCondition, mercariBrand, mercariColor, setMarketplaceData }) => {
    
    const mercariColorChart = [ {name: 'Blue', hex: '#6998AB'}, {name: 'Black', hex: '#333333'}, 
        {name: 'White', hex: '#F5F5F5'}, {name: 'Grey', hex: '#9E9E9E'}, {name: 'Red', hex: '#C75D5D'}, 
        {name: 'Green', hex: '#8CBF88'}, {name: 'Brown', hex: '#B08968'}, {name: 'Orange', hex: '#E6A57E'}, 
        {name: 'Beige', hex: '#F0E6D2'}, {name: 'Yellow', hex: '#F8E3A3'}, {name: 'Purple', hex: '#9B72AA'}, 
        {name: 'Pink', hex: '#F7BEC0'}, {name: 'Gold', hex: '#FFD700'}, {name: 'Silver', hex: '#C0C0C0'}
    ];

    const mercariConditionArray = [
        { name: 'New', id: '1000', value: 'New' },
        { name: 'Like new', id: '2000', value: 'Like new' },
        { name: 'Good', id: '3000', value: 'Good' },
        { name: 'Fair', id: '3500', value: 'Fair' },
        { name: 'Poor', id: '3800', value: 'Poor' },
    ];

    return (
        <CollapsableCard cardTitle="Mercari Item Details" idFragment="mercariItemDetails">
            <div className="row">
                <div className="col-5">
                    <MercariSize 
                        mercariSize={mercariSize}
                        categorySizeCharts={categorySizeCharts}
                        setMarketplaceData={setMarketplaceData}
                    />
                </div>
                <div className="col-7">
                    <FormColor 
                        formDataColor={mercariColor ? [mercariColor] : []}
                        colorChart={mercariColorChart}
                        setFormData={setMarketplaceData}
                        colorMax={1}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-5">
                    <MercariBrand
                        mercariBrand={mercariBrand}
                        setMarketplaceData={setMarketplaceData}
                    />

                </div>
                <div className="col-7">
                    <MarketplaceCondition 
                        marketplaceCondition={mercariCondition}
                        conditionArray={mercariConditionArray}
                        setMarketplaceData={setMarketplaceData}
                        marketplaceName={'Mercari'}
                    />
                </div>
            </div>
        </CollapsableCard>
    );
    
};

export default MercariItemDetails;
