import React, { useEffect, useState } from 'react';
import ResetButton from './FormSimple/ResetButton';
import SingleSuggestButton from '../Helper/SingleSuggestButton';

const MercariSize = ({ mercariSize, categorySizeCharts, setMarketplaceData }) => {
    const [sizeConverted, setSizeConverted] = useState(false);

    useEffect(() => {
        if (!sizeConverted && categorySizeCharts.sizeChartExists && mercariSize !== null) {
            console.log("Displaying categorySizeCharts: ", categorySizeCharts);
            //remove in. (for pants)

            let normalizedMercariSize = mercariSize;
            if(mercariSize){
                normalizedMercariSize = mercariSize.replace(/\([^()]*\)/g, '').trim().toLowerCase();
            }
             
            const foundSize = categorySizeCharts.values.find(size => {
                const normalizedSize = size.replace(/\([^()]*\)/g, '').replace('in.', '').trim().toLowerCase();
                return normalizedSize === normalizedMercariSize;
            });

            if (foundSize) {
                console.log("Match found for:", mercariSize, "as", foundSize);
                setMarketplaceData(prevData => ({
                    ...prevData,
                    size: foundSize
                }));
            }else{
                setMarketplaceData(prevData => ({
                    ...prevData,
                    size: ''
                }));
            }

            setSizeConverted(true);
            console.log('foundSize - ', foundSize);
        }
    }, [mercariSize, categorySizeCharts]);

    //on loaded mercariSize and categorySizeCharts,

    //look for a match, 
    //  'XS': 'XS (30-32)',
    //   'S': 'S (34-36)',
    //   'M': 'M (38-40)',
    //   'L': 'L (42-44)',
    //check for matches excluding ()


    return (
        <>
            <div className="mb-2">
                <label htmlFor={"MercariSizeLabel"} className="form-label mb-0" style={{ fontSize: '1.2em' }}>Size</label>
                <ResetButton onReset={() => setMarketplaceData(prevData => {
                    const updatedData = { ...prevData };
                    delete updatedData.size;
                    return updatedData;
                })} />
            </div>

            {
                mercariSize && mercariSize !== '' && 
                <>
                    <span style={{ marginRight: '3px' }}>Selected:</span>
                    <SingleSuggestButton 
                        value={mercariSize}
                        selected="true"
                        specific="size"
                        setSelectedSpecifics={() => {
                            setMarketplaceData(prevData => ({
                                ...prevData,
                                size: ''
                            }));
                        }}
                    />
                </>
                
            }
            
            <div className="row">
                <div className="col">
                    <div className="form-group">

                    {(!categorySizeCharts || Object.keys(categorySizeCharts).length === 0) ? (
                            <p>Select Category to see options.</p>
                        ) : categorySizeCharts.sizeChartExists === false ? (
                            <p>No Size for this Category.</p>
                        ) : (

                        <select className="form-control" id="mercariSizeSelect" value={mercariSize || ''}
                                onChange={(e) => setMarketplaceData(prevData => ({
                                    ...prevData,
                                    size: e.target.value
                                }))}>
                            <option value="">Select Size</option>
                            {categorySizeCharts && categorySizeCharts.values && categorySizeCharts.values.length > 0 ? (
                                categorySizeCharts.values.map(size => (
                                    <option key={size} value={size}>{size}</option>
                                ))
                            ) : (
                                <option value="">No Sizes Available.</option>
                            )}
                        </select>
                    )}

                    </div>
                </div>
            </div>

        </>
    );
};

export default MercariSize;