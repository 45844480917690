import React, { useState, useEffect, useRef } from 'react';
import { storage } from '../../firebase'; // Import storage from firebase.js
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { SignpostSplit, Trash } from 'react-bootstrap-icons';
import { useUserAuth } from '../../context/UserAuthContext';
import { listingAPI } from '../../api';

const ProductInfo = ({ product, setProducts, productIndex }) => {
    const handleTitleChange = (e, productIndex) => {
        const newTitle = e.target.value;
        setProducts(prevProducts => {
            const updatedProducts = [...prevProducts];
            updatedProducts[productIndex].title = newTitle;
            return updatedProducts;
        });
    };
    
    const handleSkuChange = (e, productIndex) => {
        const newSku = e.target.value;
        setProducts(prevProducts => {
            const updatedProducts = [...prevProducts];
            updatedProducts[productIndex].sku = newSku;
            return updatedProducts;
        });
    };
    
    return (
        <div className="product-info mb-3">
            <div className="row g-0 align-items-center">
                {/* Title Field */}
                <div className="col-sm-6 d-flex align-items-center">
                    <label htmlFor={`title-${productIndex}`} className="form-label me-2 mb-0">Seach Term:</label>
                    <input 
                        type="text" 
                        className="form-control flex-grow-1" 
                        id={`title-${productIndex}`}
                        placeholder="Basic Search Term" 
                        value={product.title} 
                        onChange={(e) => handleTitleChange(e, productIndex)}
                    />
                </div>
                
                {/* SKU Field */}
                <div className="col-sm-6 d-flex align-items-center">
                    <label htmlFor={`sku-${productIndex}`} className="form-label me-2 mb-0">SKU:</label>
                    <input 
                        type="text" 
                        className="form-control flex-grow-1" 
                        id={`sku-${productIndex}`}
                        placeholder="Enter SKU" 
                        value={product.sku} 
                        onChange={(e) => handleSkuChange(e, productIndex)}
                    />
                </div>
            </div>
        </div>
    );
    
};


const BulkImageUpload = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fileCount, setFileCount] = useState(0);
    const [loadedImageCount, setLoadedImageCount] = useState(0);
    const [draggedImage, setDraggedImage] = useState(null);
    const [startingSKU, setStartingSKU] = useState('');
    const [draftLoading, setDraftLoading] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '' });
    
    const { user } = useUserAuth();

    useEffect(() => {
        console.log('products - ', products);
    }, [products]);

    const handleFillInSKUs = () => {
        if (startingSKU) {
            setProducts(prevProducts => {
                let newProducts = [...prevProducts];
                // Extract the numeric part from the end of the SKU
                const match = /(\d+)\D*$/.exec(startingSKU);
                let currentNumber = match ? parseInt(match[1]) : 0;
    
                console.log("Extracted Number:", currentNumber); // Log the extracted number
    
                newProducts.forEach((product, index) => {
                    if (currentNumber) {
                        // Generate SKU by appending the incremented number to the base part of the starting SKU
                        product.sku = `${startingSKU.slice(0, -match[1].length)}${currentNumber}`;
                        currentNumber++;
                    }
                });
    
                return newProducts;
            });
        }
    }

    const handleImageChange = (e) => {
        const files = e.target.files;

        // Check if any files were selected
        if (!files || files.length === 0) {
            // No files selected, possibly reset loading state or handle as needed
            return;
        }

        setLoading(true);
        const newFiles = Array.from(e.target.files);
        setFileCount(newFiles.length);

        const imageDataArray = newFiles.map((file) => {
            const blobURL = URL.createObjectURL(file);
            file.blobURL = blobURL;
            return {
              blobURL,
              firebaseURL: null
            };
        });

        // Update state with the new imageDataArray
        setLoadedImageCount(prevCount => prevCount + imageDataArray.length);

        let updatedProducts;

        if (products.length === 0 || products[products.length - 1].images.length > 0) {
            updatedProducts = [...products, { title: "", sku: "", images: imageDataArray }];
        } else {
            updatedProducts = [...products];
            updatedProducts[updatedProducts.length - 1].images.push(...imageDataArray);
        }
        
        setProducts(updatedProducts);
        setLoading(false);

        handleUpload(newFiles, updatedProducts);        

    };

    //similar to ImageSimple logic; currently duplicated
    const handleUpload = async (images) => {
        console.log('handleUpload - ', images);
        const batchSize = 10;

        const allPromises = images.map(file => 
            () => uploadWithRetry(file)
        )

        const processBatch = async (batch) => {
            await Promise.all(batch.map(uploadFunc => uploadFunc()));
        };
        
        for (let i = 0; i < allPromises.length; i += batchSize) {
            const batch = allPromises.slice(i, i + batchSize);
            try {
                await processBatch(batch);
                console.log(`All images have uploaded for batch ${i}`);
            } catch (errors) {
                console.log('Some images in the batch failed to upload:', errors);
            }
        }

    }

    const uploadWithRetry = async (image, retries = 3) => {
        console.log('upload with retry image - ', image);
        try {
            const timestamp = Date.now();
            const newImageName = `${timestamp}-${image.name}`;
            const storageRef = ref(storage, `users/${user.uid}/batch-uploads/${newImageName}`);
            const uploadTask = uploadBytesResumable(storageRef, image);

            await new Promise((resolve, reject) => {
                let lastProgress = 0;
                let progressCheck;
            
                const restartUpload = () => {
                    console.log("Restarting upload due to no progress");
                    clearTimeout(progressCheck);
                    if (retries > 0) {
                        uploadWithRetry(image, retries - 1);
                    } else {
                        reject(new Error("Upload failed after multiple retries"));
                    }
                };
            
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
            
                        // Reset the progress check timer if progress is made
                        if (progress > lastProgress) {
                            clearTimeout(progressCheck);
                            progressCheck = setTimeout(restartUpload, 4000); // 10 seconds for no progress
                            lastProgress = progress;
                        }
                    },
                    (error) => {
                        console.log(error);
                        clearTimeout(progressCheck);
                        if (retries > 0) {
                            setTimeout(() => {
                                uploadWithRetry(image, retries - 1);
                            }, 2000); // Retry after 2 seconds
                        } else {
                            reject(error);
                        }
                    },
                    async () => {
                        clearTimeout(progressCheck);
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        //TODO: concern if images are moved while this is happening?

                        const updateImageFirebaseURL = (blobURL, firebaseURL) => {
                            console.log('updateImageFirebaseURL blobURL - ', blobURL);
                            setProducts(prevData => {
                                const newData = prevData.map(product => {
                                    const images = product.images.map(image => {
                                        if (image.blobURL === blobURL) {
                                            console.log('blob match firebaseURL - ', firebaseURL);
                                            return { ...image, firebaseURL: firebaseURL }; // Update firebaseURL
                                        }
                                        return image;
                                    });
                                    return { ...product, images }; // Update product with new images array
                                });
                                return newData;
                            });
                        };

                        console.log('updateImageFirebaseURL image - ', image);
                        console.log('updateImageFirebaseURL image.blobURL - ', image.blobURL);
                        updateImageFirebaseURL(image.blobURL, downloadURL);
                        
                        resolve(downloadURL);
                    }
                );
            
                // Initialize the progress check timer
                progressCheck = setTimeout(restartUpload, 4000); // 10 seconds for no progress
            });
            
        }catch (error) {
            console.log('Final upload attempt failed:', error);
            throw error;
        }
    }

    const handleImageSeparator = (productIndex, imageIndex) => {
        console.log(`Clicked on icon between product ${productIndex} and image ${imageIndex}`);
    
        setProducts(prevProducts => {
            const newProducts = [...prevProducts];
            const currentProduct = newProducts[productIndex];
            const firstPartImages = currentProduct.images.slice(0, imageIndex + 1);
            const secondPartImages = currentProduct.images.slice(imageIndex + 1);        
            // Update the current product's images with the first part
            currentProduct.images = firstPartImages;
            // Create a new product with the second part of the images
            const newProduct = {
                title: "", // Assuming new product should have an empty title and sku
                sku: "",
                images: secondPartImages
            };
            // Insert the new product into the products array
            newProducts.splice(productIndex + 1, 0, newProduct);
        
            return newProducts;
        });
        
    };

    const handleDragStart = (e, productIndex, imageIndex) => {
        // Create a new image element for the drag image
        const dragImage = new Image();
        dragImage.src = products[productIndex]['images'][imageIndex];
        dragImage.style.width = '100px'; // Set a fixed width for the drag image
        dragImage.style.height = 'auto'; // Maintain aspect ratio
        document.body.appendChild(dragImage); // Temporarily add to the document
    
        // Set the drag image with a slight delay to ensure it's rendered
        setTimeout(() => {
            e.dataTransfer.setDragImage(dragImage, 50, 50); // Set the drag image
            document.body.removeChild(dragImage); // Remove it from the document
        }, 0);
    
        setDraggedImage({ productIndex, imageIndex });
    };
    
    const handleDrop = (productIndex, imageIndex) => {
        if (!draggedImage) return;
    
        setProducts(prevProducts => {
            let newProducts = [...prevProducts];
            // Extract the dragged image
            const draggedImg = newProducts[draggedImage.productIndex].images[draggedImage.imageIndex];
            // Remove the dragged image from its original position
            newProducts[draggedImage.productIndex].images.splice(draggedImage.imageIndex, 1);
            // Adjust indices if the image is dragged to a new product
            if (draggedImage.productIndex !== productIndex && draggedImage.imageIndex <= imageIndex) {
                imageIndex++;
            }
            // Insert the dragged image into its new position
            newProducts[productIndex].images.splice(imageIndex, 0, draggedImg);
        
            return newProducts;
        });
        
    
        setDraggedImage(null); // Reset the dragged image state
    };

    const handleDelete = (productIndex, imageIndex) => {
        setProducts(prevProducts => {
            let newProducts = [...prevProducts];
            // Check if the product has only one image
            if (newProducts[productIndex].images.length === 1) {
                // Remove the entire product
                newProducts.splice(productIndex, 1);
            } else {
                // Remove only the specified image from the product
                newProducts[productIndex].images = newProducts[productIndex].images.filter((_, iIndex) => iIndex !== imageIndex);
            }
            return newProducts;
        });
    };

    const handleDeleteProduct = (index) => {
        setProducts(currentProducts => currentProducts.filter((_, i) => i !== index));
    };    

    const handleStartingSKUChange = (event) => {
        setStartingSKU(event.target.value);
    };

    const saveDrafts = async () => {
        setDraftLoading(true);

        //Validation Step before submit;
        for (let product of products) {
            if(product.images.length > 25){
                setAlert({type: "warning", message: "Max 25 images per product. Try again."});
                setDraftLoading(false);
                return; 
            }
            for (let image of product.images) {
                if (!image.firebaseURL) {
                    setAlert({type: "warning", message: "Wait for all images to be uploaded"});
                    setDraftLoading(false);
                    return; 
                }
            }
        }

        try {
            const token = await user.getIdToken(true);
            const draftData = await listingAPI.postDraftBulk(token, products);
            console.log('draftData - ', draftData);
            setAlert({ type: draftData.alertType, message: draftData.message });

        } catch (error) {
            // Handle errors
            setAlert({ type: 'danger', message: `There was an error saving the draft: ${error}` });
        } finally {
            setDraftLoading(false); 
        }

    }
    
    
    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-lg-3">
                    <div class="nav-sticky">
                        <div class="card mb-3" id="sku">
                            <div class="card-header">Starting SKU</div>
                            <div class="card-body">
                                <div>
                                    <input 
                                        type="text" 
                                        class="form-control mb-2" // Added mb-2 for spacing
                                        placeholder="Enter starting SKU" 
                                        aria-label="Starting SKU" 
                                        value={startingSKU}
                                        onChange={handleStartingSKUChange}
                                    />
                                </div>
                                <div>
                                    <button 
                                        type="button" 
                                        class="btn btn-secondary" // Removed ms-2 as it's no longer needed
                                        onClick={handleFillInSKUs}>
                                        Fill in SKUs
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


                <div className="col-lg-9">

                <div className="card mb-3">
                    <div className="card-header">Bulk Image Upload</div>
                    <div className="card-body">
                        <p>Upload a group of images from multiple products at once, split them & create drafts here.</p>
                        <input
                            type="file"
                            multiple
                            onChange={handleImageChange}
                            className="form-control my-3"
                        />
                    </div>
                </div>

                    {products.map((product, productIndex) => (
                        <div className="card card-header-actions mb-3">
                            <div className="card-header">Product {productIndex + 1} 
                                <button 
                                    className="btn" 
                                    onClick={() => handleDeleteProduct(productIndex)}>
                                    <Trash color="red" />
                                </button>
                            </div>
                            <div className="card-body">
                                <div key={productIndex} className="product-container">
                                    <ProductInfo
                                        product={product}
                                        setProducts={setProducts}
                                        productIndex={productIndex}
                                    />
                                    <div className="row g-0">
                                    {product.images.map((image, imageIndex) => (
                                        <div 
                                            key={imageIndex} 
                                            className="col-6 col-md-2 mb-3 position-relative"
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, productIndex, imageIndex)}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={() => handleDrop(productIndex, imageIndex)}
                                        >
                                            <img src={image.blobURL} alt={`product-${productIndex}-image-${imageIndex}`} className="img-fluid" style={{ paddingRight: '15px', aspectRatio: '1 / 1', objectFit: 'cover' }} />
                                            <button 
                                                className="delete-btn" 
                                                onClick={() => handleDelete(productIndex, imageIndex)}
                                                style={{ position: 'absolute', top: 0, right: '10px', border: 'none', background: 'none' }}
                                            >
                                                <Trash fill="red" style={{ filter: 'drop-shadow(0 0 2px black)' }} />
                                            </button>
                                            {imageIndex < product.images.length - 1 && ( // Separator only between images
                                                <div 
                                                    className="image-separator position-absolute end-0" 
                                                    style={{ 
                                                        width: '15px',
                                                        top: 0,
                                                        bottom: 0,
                                                        cursor: 'pointer',
                                                        backgroundColor: 'transparent'
                                                    }} 
                                                    onMouseOver={(e) => e.target.style.backgroundColor = 'gray'}
                                                    onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
                                                    onClick={() => handleImageSeparator(productIndex, imageIndex)}
                                                ></div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {loading && (
                        <div className="row g-0">
                            <div className="card mb-3">
                                <div className="card-header">Loading...</div>
                                <div className="card-body">
                                    {new Array(fileCount - loadedImageCount).fill(0).map((_, index) => (
                                        <div key={loadedImageCount + index} className="col-6 col-md-2 mb-3 position-relative">
                                            <div className="skeleton-square"></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}


                    {alert.message && (
                        <div 
                            className={`alert alert-${alert.type}`} 
                            role="alert"
                        >{alert.message}</div>
                    )}

                    <div className="d-flex justify-content-end mt-3">
                    {products.length > 0 ? (
                        <button 
                            type="button" 
                            className="btn btn-primary me-2" 
                            onClick={saveDrafts} 
                            disabled={draftLoading}
                        >
                            {draftLoading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                "Save Drafts"
                            )}
                        </button>
                    ) : (<></>) }
                    </div>


                </div>

            </div>
        </div>

    );
    
}

export default React.memo(BulkImageUpload);
