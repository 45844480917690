import React, { useState, useEffect, useRef } from "react";
import { useUserAuth } from '../../../context/UserAuthContext';
import { useLocation } from 'react-router-dom';
import { templateAPI, listingAPI } from '../../../api';

//TODO: currently showing 5 results - load more? 
//TODO: cutoff based on a searchRelevancyScore?
//TODO: determine better implementation/option of sell similar


const BasicSearch = ({setTemplates, searchTermOutside, size}) => {
    const { user } = useUserAuth();
    const [searchTerm, setSearchTerm] = useState('');
    const prevSearchTermOutsideRef = useRef(searchTermOutside);
    const [basicSearchLoading, setBasicSearchLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const previousSearchTerm = useRef(null);
    const [totalResults, setTotalResults] = useState(null);

    // Check for a ?term= value passed in
    const location = useLocation();

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const term = searchParams.get('term'); // Parse the query string
  
      if (term) {
        const decodedTerm = decodeURIComponent(term)
        setSearchTerm(decodedTerm);
        handleSearchTemplates(decodedTerm);
      }
    }, [location]);

    useEffect(() => {
        if (prevSearchTermOutsideRef.current !== searchTermOutside) {
            if (searchTermOutside.length > 5) {
                setSearchTerm(searchTermOutside)
                handleSearchTemplates(searchTermOutside); //trigger click when searchTermOutside is used
            }

            if (searchTerm === '' || prevSearchTermOutsideRef.current === searchTerm) {
                setSearchTerm(searchTermOutside);
            }
            prevSearchTermOutsideRef.current = searchTermOutside;
        }
    }, [searchTermOutside, searchTerm]);

    function useDebounce(value, delay) {
        const [debouncedValue, setDebouncedValue] = useState(value);
    
        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
    
            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);
    
        return debouncedValue;
    }
    

    const handleSearchTemplates = async (customTerm) => {
        //results added to FormData via useEffect on templates in EbayListing

        const termToUse = customTerm || searchTerm;
        const sizeToUse = size || null;
        previousSearchTerm.current = termToUse;
        
        try {
            setBasicSearchLoading(true); 
            const token = await user.getIdToken(true);
            const results = await templateAPI.searchTemplates(token, termToUse, sizeToUse);

            console.log('search templates results - ', results);

            const totalResults = results.totalResults;
            setTotalResults(totalResults);
            if(totalResults <= 5){
                setIsCollapsed(false);
            }

            const updatedItemSpecifics = {};
            const suggestItemSpecifics = {};

            //NOTE: issue where Ebay API returns count of items well beyond totalResults
            //count > results.totalResults * 0.5 does not assure that only 1 result due to eBay API

            //TODO: update handling of Brand specific due to AI guess & variety of results;
            let highestObjTotalCount = 0;

            if(results.itemSpecifics){
                for (let [key, valueObj] of Object.entries(results.itemSpecifics)) {
                    // ebay's results.totalResults is not accurate for large values, Ex: 'Peter Millar Summer Comfort'
                    // so instead we check the total value of objects within that value;
                    const valueObjTotalCount = Object.values(valueObj).reduce((acc, count) => acc + count, 0);
                    if (valueObjTotalCount > highestObjTotalCount) {
                        highestObjTotalCount = valueObjTotalCount;
                    }
                }
            }

            if(results.itemSpecifics){
                for (let [key, valueObj] of Object.entries(results.itemSpecifics)) {
                    for (let [value, count] of Object.entries(valueObj)) {
                        if (count > highestObjTotalCount * 0.5 && value !== 'undefined') {
                            if (!updatedItemSpecifics[key]) {
                                updatedItemSpecifics[key] = [];
                            }
                            updatedItemSpecifics[key][0] = value;
                        } else if (count > highestObjTotalCount * 0.1 && value !== 'undefined') {
                            if (!suggestItemSpecifics[key]) {
                                suggestItemSpecifics[key] = [];
                            }
                            suggestItemSpecifics[key].push(value);
                        }
                    }
                }
            }

            setTemplates(prevData => ({
                ...prevData,
                ...results,
                suggestItemSpecifics: suggestItemSpecifics,
                updatedItemSpecifics: updatedItemSpecifics
            }));

            setBasicSearchLoading(false); 
        } catch (error) {
            console.error('An error occurred while searching for templates:', error);
            setBasicSearchLoading(false); 
        }
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
      
    return (
        <div className="card card-header-actions mb-2">
            <div className="card-header">
            <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                    <span className="me-2" style={{minWidth:"100px"}}>Basic Search</span>
                    <div className="input-group">
                        <input
                            type="search"
                            className="form-control"
                            id="basic-search"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); 
                                    handleSearchTemplates();
                                }
                            }}
                            style={{
                                width: Math.max(200, Math.min(500, searchTerm.length * 10.2)) + 'px', // Adjust min and max width as needed
                                overflow: 'hidden', // Prevents content from spilling outside
                                whiteSpace: 'nowrap', // Keeps content in a single line
                                textOverflow: 'ellipsis' // Adds an ellipsis to indicate overflow
                            }}
                        />
                        <button 
                            type="submit" 
                            className={`btn ${previousSearchTerm.current === searchTerm ? "btn-success" : "btn-primary"}`} 
                            onClick={() => handleSearchTemplates()} 
                            disabled={basicSearchLoading || previousSearchTerm.current === searchTerm}
                        >
                            {basicSearchLoading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : previousSearchTerm.current === null ? (
                                "Go"
                            ) : previousSearchTerm.current === searchTerm ? (
                                "Loaded Below"
                            ) : (
                                "Update"
                            )}
                        </button>

                    </div>
                </div>
                <div className="flex-grow-1"></div>


                {totalResults !== null && totalResults <= 5 && (
                    <div>
                    <button className="btn btn-link" type="button" onClick={toggleCollapse}>
                        <i className={`fas ${isCollapsed ? 'fa-chevron-down' : 'fa-chevron-up'}`} />
                    </button>
                    </div>
                )}

            </div>

            </div>
            <div className={`collapse ${!isCollapsed ? 'show' : ''}`}>
                {totalResults !== null && totalResults <= 5 && (
                    <div className="card-body">
                        {totalResults === 0 ? (
                            <div className="alert alert-danger" role="alert">
                                No Results found. Adjust to be less specific or continue manually below.
                            </div>
                        ) : totalResults >= 1 && totalResults <= 5 ? (
                            <div className="alert alert-warning" role="alert">
                                Only {totalResults} result{totalResults > 1 ? 's' : ''} found. Adjust search term to be less specific or continue below.
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        </div>

    );
};

export default React.memo(BasicSearch);
