import React, { useState, useEffect, useRef, forwardRef } from 'react';

const InputField = forwardRef(({ selectedValues, suggestedValues, specificValuesLength, handleInputChange, setShowDropdown, inputValue }, ref) => {
    const handleKeyDown = (e) => {
        if ((e.key === 'Enter' || e.key === 'Tab') && ref.current) {
            const firstOption = ref.current.querySelector('.dropdown-item');
            if (firstOption) {
                firstOption.click();
                e.target.blur();
            }
        }
    };

    return (
        <input 
            ref={ref}
            type="text" 
            className="form-input" 
            value={inputValue}
            placeholder={selectedValues.length || suggestedValues.length === specificValuesLength ? '' : 'Type to search...'}
            autoComplete="off" 
            style={{
                border: 'none', 
                flexGrow: 1,
                width: selectedValues.length >= 1 || suggestedValues.length === specificValuesLength ? '50px' : 'auto'  // Conditional width setting
            }}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}  
            onFocus={() => setShowDropdown(true)}
            onBlur={() => setTimeout(() => setShowDropdown(false), 200)} 
        />
    )
});

const DropdownMenu = React.memo(({ showDropdown, setShowDropdown, currentFilteredOptions, handleDropdownSelect, dropdownRef, title }) => {

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false); // Close the dropdown when clicking outside
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowDropdown]);

    return (
        showDropdown && (
            <div 
                ref={dropdownRef}
                className="dropdown-menu" 
                style={{ 
                    display: 'block', 
                    width: 'calc(200px + 10px + 10px)', 
                    marginLeft: title ? '200px' : '0',
                    maxHeight: '300px', 
                    overflowY: 'auto' 
                }}
            >
                {currentFilteredOptions.map(option => {
                    const isHighlighted = option.highlighted;
                    const value = isHighlighted ? option.value : option;
                    return (
                        <a 
                            key={value} 
                            className={`dropdown-item ${isHighlighted ? 'highlighted' : ''}`} 
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault();
                                handleDropdownSelect(value);
                            }}
                        >
                            {value}
                        </a>
                    );
                })}
            </div>
        )
    );
    
});

const SelectedButtons = React.memo(({ selectedValues, handleRemoveValue, specific }) => {

    return selectedValues.map(value => {
        const isNonRemovable = !specific.rules.isFreeText && specific.rules.isSingle && specific.rules.required && specific.values.length === 1;

        return (
            <button 
                key={value}
                className={`selected-option btn btn-primary btn-sm mr-2 ${isNonRemovable ? '' : 'allow-remove'}`} 
                data-value={value}
                onClick={() => !isNonRemovable && handleRemoveValue(value)}
                onMouseOver={(e) => !isNonRemovable && e.currentTarget.classList.replace('btn-primary', 'btn-danger')}
                onMouseOut={(e) => !isNonRemovable && e.currentTarget.classList.replace('btn-danger', 'btn-primary')}
                style={{ marginRight: '2px' }}
            >
                {value}
            </button>
        );
    });
});

const SuggestedButtons = React.memo(({ suggestedValues, handleSuggestedSelect, specific, selectedValues }) => {

    let valuesToDisplay = suggestedValues.filter(value => !selectedValues.includes(value));

    return valuesToDisplay.map(value => (
        <button 
            key={value}
            className="btn btn-outline-primary btn-sm mr-2"
            onClick={() => handleSuggestedSelect(value)}
        >
            {value}
        </button>
    ));
});



const ItemSpecific = React.memo(function ItemSpecific({ 
    specific, 
    type, 
    initialValue, 
    setFormData, 
    suggestSpecifics, 
    title=true,
    fieldPath=null
}) {
    const [currentFilteredOptions, setCurrentFilteredOptions] = useState(specific.values);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedValues, setSelectedValues] = useState(initialValue ? [initialValue] : []);
    const [suggestedValues, setSuggestedValues] = useState(suggestSpecifics || []);
    const [inputValue, setInputValue] = useState('');
    const maxInitialDisplay = 30;
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        // SuggestSpecifics is combination of suggestions from Basic Search / AI or display all values if <= 4
        if (suggestSpecifics) {
            let additionalValues = [];

            if (specific.values.length <= 4) {
                additionalValues = specific.values;
            }

            // set to avoid duplication
            const updatedSuggestions = new Set([...suggestedValues, ...suggestSpecifics, ...additionalValues]);
            setSuggestedValues([...updatedSuggestions]);
            
        }
    }, [suggestSpecifics]);

    useEffect(() => {
        setCurrentFilteredOptions(specific.values.slice(0, maxInitialDisplay));
    }, [specific.values]);
    
    useEffect(() => {
        // Check if initialValue is an array. If it is, use it directly. Otherwise, wrap it in an array.
        const newValues = Array.isArray(initialValue) ? initialValue : (initialValue ? [initialValue] : []);
        
        if (JSON.stringify(newValues) !== JSON.stringify(selectedValues)) {
            setSelectedValues(newValues);
        }
    }, [initialValue, selectedValues]);
    
    
    const handleInputChange = (e) => {
        const inputValue = e.target.value.toLowerCase();
        const filteredOptions = specific.values.filter(value => value.toLowerCase().includes(inputValue));
    
        setInputValue(e.target.value);
        let slicedOptions = filteredOptions.slice(0, maxInitialDisplay);

        if (filteredOptions.length === 1) {
            slicedOptions = [{ value: filteredOptions[0], highlighted: true }];
        } else if (filteredOptions.length === 0) {
            slicedOptions = [];
            if (specific.rules && !specific.rules.isFreeText) {
                slicedOptions.push('No custom values allowed.')
            }
        } 

        if (specific.rules && specific.rules.isFreeText) {
            slicedOptions.push(`Add: ${e.target.value}`);
        }

        setCurrentFilteredOptions(slicedOptions);
    };
    
    const handleRemoveValue = (valueToRemove) => {
        const newSelectedValues = selectedValues.filter(value => value !== valueToRemove);
        setSelectedValues(newSelectedValues);
    
        updateFormData(newSelectedValues, fieldPath);
    };

    const updateFormData = (newSelectedValues, fieldPath) => {
        setFormData(prevData => {
            const updatedData = { ...prevData };
    
            if (fieldPath) {
                if (newSelectedValues.length === 0) {
                    delete updatedData[fieldPath];
                } else if(newSelectedValues.length === 1) {
                    updatedData[fieldPath] = newSelectedValues[0];
                } else {
                    updatedData[fieldPath] = newSelectedValues;
                }
            } else {
                const updatedSpecifics = { ...prevData.itemSpecifics };
    
                if (newSelectedValues.length === 0) {
                    delete updatedSpecifics[specific.name];
                } else {
                    updatedSpecifics[specific.name] = newSelectedValues;
                }
    
                updatedData.itemSpecifics = updatedSpecifics;
            }
            return updatedData;
        });
    };
    
    const handleDropdownSelect = (value) => {
        let newSelectedValues; 
    
        if (value.startsWith('Add: ')) {
            value = value.substring(5); 
        }

        if (specific.rules?.isSingle) {
            newSelectedValues = [value]; //overwrite any existing
            setSelectedValues(newSelectedValues); 
        } else if (!selectedValues.includes(value)) {
            // TODO: focus on input & show dropdown on multiple after selection
            // inputRef.current?.focus();
            // setShowDropdown(true);

            newSelectedValues = [...selectedValues, value]; //add if not included
            setSelectedValues(newSelectedValues);
        }
        
        setInputValue('');
        setShowDropdown(false);
        setCurrentFilteredOptions(specific.values);
        updateFormData(newSelectedValues, fieldPath);
    };
    
    const handleSuggestedSelect = (value) => {

        let newSelectedValues = [...selectedValues]; 

        if (!selectedValues.includes(value)) {
            if (specific.rules?.isSingle) {
                newSelectedValues = [value];
            }else{
                newSelectedValues.push(value); 
            }
            setSelectedValues(newSelectedValues);
        }
    
        if (specific.rules?.isSingle) {
            setSuggestedValues([]); 
        } else {
            setSuggestedValues(prevSuggested => prevSuggested.filter(s => s !== value));
        }

        updateFormData(newSelectedValues, fieldPath);
    };
    
    
    // Main component
    return (
        <div className="dropdown mb-3">
            <div className="d-flex align-items-center mb-2">

                {title && (
                    <p className="input-title mb-0 mr-2" style={{ minWidth: '200px' }}>
                        {type === "required" ? <strong>{specific.name}</strong> : specific.name}
                    </p>
                )}

                <div className="input-wrapper form-control d-flex align-items-center" style={{ flexWrap: 'wrap', padding: '10px' }}>
                    <SelectedButtons selectedValues={selectedValues} handleRemoveValue={handleRemoveValue} specific={specific}/>
                    <SuggestedButtons suggestedValues={suggestedValues} handleSuggestedSelect={handleSuggestedSelect} selectedValues={selectedValues} specific={specific} />
                    <InputField selectedValues={selectedValues} suggestedValues={suggestedValues} specificValuesLength={specific.values?.length || 0} handleInputChange={handleInputChange} setShowDropdown={setShowDropdown} inputValue={inputValue} ref={inputRef}/>
                </div>
            </div>
            <DropdownMenu showDropdown={showDropdown} setShowDropdown={setShowDropdown} currentFilteredOptions={currentFilteredOptions} handleDropdownSelect={handleDropdownSelect} dropdownRef={dropdownRef} title={title}/>
        </div>
    );
    
    
    
});

export default React.memo(ItemSpecific);