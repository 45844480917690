import React, { useState } from 'react';
import { cloudAPI } from '../../../api';
import { useUserAuth } from '../../../context/UserAuthContext';

const ConnectMarketplaceModal = ({ marketplaceName, show, onClose, modalLocation = 'settings' }) => {
    const { user } = useUserAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [authFormLoading, setAuthFormLoading] = useState(false);
    const [authResponse, setAuthResponse] = useState({});
    const [alert, setAlert] = useState({ message: '', type: ''});

    const marketplaceActions = {
        poshmark: [
          'Create New Listings',
          'Cross-List from other Marketplaces',
          'Re-List Listings',
          'More actions as we grow our features',
        ],
    };

    const handleConnect = async (event) => {
      event.preventDefault();

        if (username.length < 2) {
            setAlert({ message: 'Username is too short.', type: 'warning' });
            return;
        }
        if (password.length < 6) {
            setAlert({ message: 'Password is invalid. Password must be at least 6 characters long.', type: 'warning' });
            return;
        }

        setAlert({ message: '', type: ''});

      try {
          setAuthFormLoading(true);
          const token = await user.getIdToken(true);
          const cloudResponse = await cloudAPI.createMarketplaceAuth(token, username, password, marketplaceName.toLowerCase());

          let message = 'Login information submitted. Refresh in 1 minute to check connection.';
          if (modalLocation === 'onboard') {
            message = 'Login information submitted. Check status in Settings.';
          }
          setAlert({ message, type: 'success' });
          setAuthResponse(cloudResponse);
      } catch (error) {
          console.error('An error occurred while getting total imported:', error);
      } finally {
        setAuthFormLoading(false);
      }

    };

    return (
        <>
            {show && <div className="modal-backdrop show"></div>}
            <div className={`modal ${show ? 'show d-block' : ''}`} tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Connect to {marketplaceName}</h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body" style={{ color: 'black' }}>
                            {!acceptTerms ? (
                                <div>
                                    <p>
                                        We connect to {marketplaceName} on your behalf to complete the following tasks:
                                    </p>
                                    <ul>
                                        {marketplaceActions[marketplaceName.toLowerCase()].map((action, index) => (
                                            <li key={index}>{action}</li>
                                        ))}
                                    </ul>
                                    <p>By connecting, you agree to allow us to complete these actions. All actions are started by you and done on your behalf.</p>
                                    <button type="button" className="btn btn-primary" onClick={() => setAcceptTerms(true)}>
                                        Accept
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label">Username</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            placeholder={`Enter your ${marketplaceName} username`}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder={`Enter your ${marketplaceName} password`}
                                            autoComplete="new-password"
                                        />
                                    </div>
                                    <p>We secure your passwords with high level encryption. Our team cannot read your password.</p>
                                    {alert.message && (
                                        <div className={`alert alert-${alert.type}`} role="alert">
                                            {alert.message}
                                        </div>
                                    )}
                                    
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleConnect}
                                        disabled={authFormLoading || (authResponse && Object.keys(authResponse).length > 0)}
                                    >
                                        {authFormLoading ? 'Loading...' : (authResponse && Object.keys(authResponse).length > 0 ? 'Submitted' : 'Connect')}
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(ConnectMarketplaceModal);;