import React, { useEffect } from 'react';
import CollapsableCard from '../Helper/CollapsableCard';


const MercariPricingDetails = ({ mercariPrice, mercariPackageDetails, mercariPricingDetails, setMarketplaceData }) => {
    //mercariPricingDetails is mercariData.pricingDetails
    // {
    //     smartPricing: Boolean,
    //     smartOffers: Boolean,
    //     smartPricingMinimumPrice: Number,
    //     smartOffersMinimumPrice: Number
    // }

    useEffect(() => {
        if (Object.keys(mercariPricingDetails).length === 0) {
            setMarketplaceData(prevData => ({
              ...prevData,
              pricingDetails: {
                smartPricing: false,
                smartOffers: false
              }
            }));
          }
    }, [])
    
    const handleInputChange = (e, inputType = null) => {
        const { name, id, value } = e.target;
        const key = name || id;

        if(inputType === 'packageDetails'){
            setMarketplaceData(prevData => ({
                ...prevData,
                packageDetails: {
                    ...prevData.packageDetails,
                    [key]: value
                }
            }));
        }else{
            setMarketplaceData(prevData => ({
                ...prevData,
                [key]: value
            }));
        }
    };

    const handleToggle = (toggleValue, booleanFieldName) => () => {
        console.log('handle Toggle toggleField - ', booleanFieldName);

        setMarketplaceData(prevData => ({
            ...prevData,
            pricingDetails: {
                ...prevData.pricingDetails || {}, // Safeguard to initialize if undefined
                [booleanFieldName]: !toggleValue
            }
        }));
    };

    const toggleDisabled = () => {
        return (
            <div className="col-sm-1">
                <button
                    className="btn btn-outline-primary"
                    disabled={true}  // Button is always disabled
                >
                    Off
                </button>
            </div>
        );
    }
    
    

    const toggleButton = (toggleValue, booleanFieldName) => {
        return (
            <div className="col-sm-1">
                <button
                    onClick={handleToggle(toggleValue, booleanFieldName)}
                    className={`btn ${toggleValue ? 'btn-primary' : 'btn-outline-primary'}`}
                    onMouseOver={e => {
                        if (toggleValue) {
                            e.currentTarget.classList.remove('btn-primary');
                            e.currentTarget.classList.add('btn-danger');
                        } else {
                            e.currentTarget.classList.remove('btn-outline-primary');
                            e.currentTarget.classList.add('btn-primary');
                        }
                    }}
                    onMouseOut={e => {
                        if (toggleValue) {
                            e.currentTarget.classList.remove('btn-danger');
                            e.currentTarget.classList.add('btn-primary');
                        } else {
                            e.currentTarget.classList.remove('btn-primary');
                            e.currentTarget.classList.add('btn-outline-primary');
                        }
                    }}
                >
                    {toggleValue ? 'On' : 'Off'}
                </button>
            </div>
        );
    }
    

    const SmartSection = (smartBoolean, fieldName, minimumPrice, title) => {
        return (
            <>
            <div className="row mb-3">
                <label htmlFor="smartOffers" className="col-sm-2 col-form-label">{title}</label>
                { toggleDisabled(smartBoolean || false, fieldName) }
                {smartBoolean && (
                    <>
                        <label htmlFor="minimumPrice" className="col-sm-2 col-form-label">Minimum Price</label>
                        <div className="col-sm-4">
                            <input 
                                type="text" 
                                className="form-control" 
                                id={fieldName + "MinimumPrice"}
                                value={minimumPrice || ''}
                                onChange={e => setMarketplaceData(prevData => ({
                                    ...prevData,
                                    pricingDetails: {
                                        ...prevData.pricingDetails || {}, 
                                        [fieldName + "MinimumPrice"]: e.target.value
                                    }
                                }))
                                }
                            />
                        </div>
                    </>
                        
                )}
            </div>
            </>
        )
    }

    //Smart Pricing (Minimum Price)
    //Smart Offers (Minimum Price)

    //Shipping: Standard Shipping
        //Who pays? (I'll pay, Buyer Pays)
        //Weight, Lbs, Method

    //Ship on your own (you pay shipping)

    //Shipping Zip Code by address

    return (
        <CollapsableCard cardTitle="Mercari Pricing Details" idFragment="mercariPricingDetails">
            <p>More options coming soon. For now, smart offers & pricing are always off. For shipping, we'll select charging buyer with the cheapest option.</p>
            <div className="row mb-3">
                <label htmlFor="price" className="col-sm-2 col-form-label">Listing Price</label>
                <div className="col-sm-4">
                    <input key="mercariPrice" type="text" className="form-control" id="price" value={mercariPrice ? mercariPrice : ''} onChange={handleInputChange}  />
                </div>
            </div>
            
            { SmartSection(mercariPricingDetails.smartPricing, 'smartPricing', mercariPricingDetails.smartPricingMinimumPrice, 'Smart Pricing') }

            { SmartSection(mercariPricingDetails.smartOffers, 'smartOffers', mercariPricingDetails.smartOffersMinimumPrice, 'Smart Offers') }

            <div className="row mb-3">
                <label htmlFor="price" className="col-sm-2 col-form-label">Shipping</label>
                <div className="col-sm-4">
                    {/* Bootstrap button with tooltip */}
                    <button 
                        className="btn btn-primary" 
                        type="button"
                        disabled 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top" 
                        title="More Options Coming Soon">Lowest Price
                    </button>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Weight</label>
                <div className="col-sm-2">
                    <input type="text" className="form-control" name="weightMajor" value={mercariPackageDetails.weightMajor ? mercariPackageDetails.weightMajor : ''} placeholder="lbs" onChange={(e) => handleInputChange(e, 'packageDetails')}/>
                </div>
                <div className="col-sm-2">
                    <input type="text" className="form-control" name="weightMinor" value={mercariPackageDetails.weightMinor ? mercariPackageDetails.weightMinor : ''} placeholder="ozs" onChange={(e) => handleInputChange(e, 'packageDetails')}/>
                </div>

                <label className="col-sm-2 col-form-label">Dimensions</label>
                <div className="col-sm-1" style={{ padding: 0 }}>
                    <input 
                        type="text" 
                        name="packageDepth"
                        className="form-control" 
                        placeholder="in." 
                        value={mercariPackageDetails.packageDepth ? mercariPackageDetails.packageDepth : ''}
                        style={{ textAlign: 'center', minWidth: '46px' }} 
                        onChange={(e) => handleInputChange(e, 'packageDetails')}
                    />
                </div>
                <div className="col-sm-1" style={{ padding: 0 }}>
                    <input 
                        type="text" 
                        name="packageLength"
                        className="form-control" 
                        placeholder="in."
                        value={mercariPackageDetails.packageLength ? mercariPackageDetails.packageLength : ''}
                        style={{ textAlign: 'center', minWidth: '46px' }} 
                        onChange={(e) => handleInputChange(e, 'packageDetails')}
                    />
                </div>
                <div className="col-sm-1" style={{ padding: 0 }}>
                    <input 
                        type="text" 
                        name="packageWidth"
                        className="form-control" 
                        placeholder="in."
                        value={mercariPackageDetails.packageWidth ? mercariPackageDetails.packageWidth : ''}
                        style={{ textAlign: 'center', minWidth: '46px' }} 
                        onChange={(e) => handleInputChange(e, 'packageDetails')}
                    />
                </div>
            </div>





        </CollapsableCard>
    );
    
};

export default MercariPricingDetails;
