import React from 'react';

const MarketplaceTitle = ({ marketplaceDataTitle, setMarketplaceData, marketplaceName, maxCharCount = 80 }) => {
  const charCount = marketplaceDataTitle?.length ?? 0;
  const charCountClass = (charCount > maxCharCount) ? 'text-danger' : 'text-muted';
  
  const handleTitleChange = (e) => {
    const newTitle = e.target.value;

    setMarketplaceData((prevData) => ({
        ...prevData,
        title: newTitle
    }));
  };


  return (
    <div className="card mb-2" id={`${marketplaceName}-ebay`}>
      <div className="card-header">Title</div>
        <div className="card-body">
            <input
                type="text"
                className="form-control"
                id={`title${marketplaceName}`}
                name={`title${marketplaceName}`}
                value={marketplaceDataTitle}
                onChange={handleTitleChange}
                placeholder="Enter title"
            />
            <div style={{ fontSize: '0.8em' }} className={`mt-1 text-end ${charCountClass}`}>
                {`${charCount}/${maxCharCount}`}
            </div>
        </div>

    </div>
  );
};

export default MarketplaceTitle;
