import React, { useEffect } from 'react';

const PricingDetails = ({ storeData, formData, setFormData }) => {

    const handleInputChange = (e, inputType = null) => {
        const { name, id, type, value, options } = e.target;
    
        const key = name || id;
        let inputValue;
    
        if (type === 'checkbox') {
            inputValue = e.target.checked;
        } else if (type === 'select-one') {
            const selectedOption = options[options.selectedIndex];
            const selectedName = selectedOption.text;
            inputValue = value ? { name: selectedName, id: value } : null;
        } else {
            inputValue = value;
        }

        if(inputType === 'packageDetails'){
            setFormData(prevData => ({
                ...prevData,
                packageDetails: {
                    ...prevData.packageDetails,
                    [key]: inputValue
                }
            }));
        }else if(inputType === 'sellerProfiles'){
            setFormData(prevData => ({
                ...prevData,
                sellerProfiles: {
                    ...prevData.sellerProfiles,
                    [key]: inputValue
                }
            }));
        }else{
            setFormData(prevData => ({
                ...prevData,
                [key]: inputValue
            }));
        }
    

    };
 
    
    

    const handleListingTypeChange = (type) => {
        setFormData(prevData => ({
            ...prevData,
            listingType: prevData.listingType === type ? '' : type
        }));
    };

    useEffect(() => {
        // If formData.listingType is not set, default it to 'buyItNow'
        if (!formData.listingType) {
            setFormData(prevData => ({
                ...prevData,
                listingType: 'buyItNow'
            }));
        }
    }, [formData, setFormData]);

    return (
        <div className="card mb-2" id="listing-details">
            <div className="card-header">Pricing Details</div>
            <div className="card-body">
                <p>We auto-filled in your Store Defaults.</p>
                <form>
                    <div className="row mb-3">
                        <label htmlFor="format" className="col-sm-3 col-form-label">Format</label>
                        <div className="col-sm-4">
                            <button 
                                type="button" 
                                className={`btn ${formData.listingType === 'buyItNow' ? 'btn-primary' : 'btn-outline-primary'}`} 
                                onClick={() => handleListingTypeChange('buyItNow')}
                            >
                                Buy it Now
                            </button>
                            <button 
                                type="button" 
                                className={`btn ${formData.listingType === 'auction' ? 'btn-secondary' : 'btn-outline-secondary'}`} 
                                onClick={() => handleListingTypeChange('auction')}
                                disabled
                            >
                                Auction
                            </button>
                        </div>
                    </div>

                    {formData.listingType === 'buyItNow' && (
                        <div className="row mb-3">
                            <label htmlFor="price" className="col-sm-3 col-form-label">Buy it Now Price</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" placeholder="0" value={formData.price ? formData.price : ''} name="price" onChange={handleInputChange} />
                            </div>
                            <div className="col-sm-2">Allow Offers</div>
                            <div className="col-sm-4 form-check form-switch">
                                <input type="checkbox" className="form-check-input" name="allowOffers" checked={formData.allowOffers} onChange={handleInputChange} />
                                <label className="form-check-label" htmlFor="allowOffers"></label>
                            </div>
                        </div>
                    )}

                    {formData.listingType === 'auction' && (
                        <div className="row mb-3">
                            <label htmlFor="startingBid" className="col-sm-3 col-form-label">Starting Bid</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" placeholder="0" name="price" onChange={handleInputChange}/>
                            </div>
                        </div>
                    )}

                    <div className="row mb-3">
                        <label htmlFor="fulfillmentPolicies" className="col-sm-3 col-form-label">Fulfillment Policy</label>
                        <div className="col-sm-4">
                            {storeData.fulfillmentPolicies && storeData.fulfillmentPolicies.length > 0 ? (
                                <select className="form-select" id="fulfillmentPolicy" value={formData.sellerProfiles?.fulfillmentPolicy ? formData.sellerProfiles?.fulfillmentPolicy.id : ''} onChange={e => handleInputChange(e, 'sellerProfiles')}>
                                    <option value="">Select Fulfillment Policy</option>
                                    {storeData.fulfillmentPolicies.map(policy => (
                                        <option key={policy.id} value={policy.id}>{policy.name}</option>
                                    ))}
                                </select>
                            ) : (
                                "Loading or not found. Add in settings."
                            )}
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-2 col-form-label">Weight</label>
                        <div className="col-sm-2">
                            <input type="text" className="form-control" name="weightMajor" value={formData.packageDetails?.weightMajor ? formData.packageDetails?.weightMajor : ''} placeholder="lbs" onChange={(e) => handleInputChange(e, 'packageDetails')}/>
                        </div>
                        <div className="col-sm-2">
                            <input type="text" className="form-control" name="weightMinor" value={formData.packageDetails?.weightMinor ? formData.packageDetails?.weightMinor : ''} placeholder="ozs" onChange={(e) => handleInputChange(e, 'packageDetails')}/>
                        </div>

                        <label className="col-sm-2 col-form-label">Dimensions</label>
                        <div className="col-sm-1" style={{ padding: 0 }}>
                            <input 
                                type="text" 
                                name="packageDepth"
                                className="form-control" 
                                placeholder="in." 
                                value={formData.packageDetails?.packageDepth ? formData.packageDetails?.packageDepth : ''}
                                style={{ textAlign: 'center', minWidth: '46px' }} 
                                onChange={(e) => handleInputChange(e, 'packageDetails')}
                            />
                        </div>
                        <div className="col-sm-1" style={{ padding: 0 }}>
                            <input 
                                type="text" 
                                name="packageLength"
                                className="form-control" 
                                placeholder="in."
                                value={formData.packageDetails?.packageLength ? formData.packageDetails?.packageLength : ''}
                                style={{ textAlign: 'center', minWidth: '46px' }} 
                                onChange={(e) => handleInputChange(e, 'packageDetails')}
                            />
                        </div>
                        <div className="col-sm-1" style={{ padding: 0 }}>
                            <input 
                                type="text" 
                                name="packageWidth"
                                className="form-control" 
                                placeholder="in."
                                value={formData.packageDetails?.packageWidth ? formData.packageDetails?.packageWidth : ''}
                                style={{ textAlign: 'center', minWidth: '46px' }} 
                                onChange={(e) => handleInputChange(e, 'packageDetails')}
                            />
                        </div>
                    </div>


                </form>
            </div>
        </div>
    )
}

export default React.memo(PricingDetails);