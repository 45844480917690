import React, { useState, useEffect } from "react";
import { listingAPI } from "../../api";
import { useUserAuth } from "../../context/UserAuthContext";
import Pagination from "./Pagination";
import ProductRowSkeleton from "./ProductRowSkeleton";

//TODO: import all option

const ComingSoonCard = ({ marketplaceName, color }) => {
    const cardStyle = {
        backgroundColor: color,
    };

    return (
        <div className="card text-white" style={cardStyle}>
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="me-3">
                        <div className="text-lg fw-bold">{marketplaceName}</div>
                    </div>
                </div>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between small">
                <div className="text-white stretched-link" >Coming Soon</div>
            </div>
        </div>
    );
}

const ProductCard = ({ initialItem }) => {
    const [item, setItem] = useState(initialItem);
    const defaultImage = "https://firebasestorage.googleapis.com/v0/b/thriftallday-37c6b.appspot.com/o/site%2Fplaceholder2.png?alt=media";
    const [isImported, setIsImported] = useState(false);
    const [checkImportLoading, setCheckImportLoading] = useState(false);
    const [importCardLoading, setImportCardLoading] = useState(false);
    const { user } = useUserAuth();

    const checkIfImported = async (itemId) => {
        try {
            setCheckImportLoading(true);
            const token = await user.getIdToken(true);
            const importCheckData = await listingAPI.readImportListing(token, itemId);
            console.log('importCheckData - ', importCheckData);

            if(!!importCheckData){
                setItem(prevData => ({
                    ...importCheckData,
                    ...prevData
                }))
                setIsImported(true);
            }

        } catch(error){
            console.log('error: ', error);
        } finally {
            setCheckImportLoading(false);
        }
        
    };

    useEffect(() => {
        checkIfImported(item.itemId);
    }, [item.itemId]);

    const handleImport = async () => {
        //TODO: error messaging
        
        setImportCardLoading(true);
        console.log(item.itemId);
        try {
            const token = await user.getIdToken(true);
            const importCreateData = await listingAPI.createImportProduct(token, item);
            console.log('importCreateData - ', importCreateData);
            setIsImported(true);
        } catch(error){
            console.log('error: ', error);
        } finally {
            setImportCardLoading(false);
        }
    };

    return (
      <div className="card mt-2">
        <div className="row no-gutters">
          <div className="col-md-2"> 
            <img className="img-fluid" 
                src={item.pictures && item.pictures.length > 0 ? item.pictures[0] : defaultImage}
                alt={item.title} 
            />
          </div>

          <div className="col-md-8"> 
            <div className="card-body">
              <h5 className="card-title">{item.title}</h5>
              <p className="card-text">
                <div className="row">
                    <div className="col-sm-4">
                        <span>{item.sku && `SKU: ${item.sku} `}</span><br />
                        {item.discountPrice ? (
                            <>
                                <span>Price: </span>
                                <span style={{ textDecoration: 'line-through' }}>{`$${parseFloat(item.price).toFixed(2)}`}</span>
                                <span style={{ color: 'red', marginLeft: '5px' }}>{`$${parseFloat(item.discountPrice).toFixed(2)}`}</span>
                            </>
                        ) : (
                            item.price && `Price: $${parseFloat(item.price).toFixed(2)}`
                        )}

                    </div>
                    <div className="col-sm-8">
                    <span>{item.itemId && `Item ID: ${item.itemId}`}</span><br />
                    <span>{item.status && `Item Status: ${item.status}`}</span>
                    </div>
                </div>
              </p>
            </div>
          </div>
          
          <div className="col-md-2">
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                {checkImportLoading ? (
                    <button className="btn btn-info" disabled>Checking...</button>
                ) : isImported ? (
                    <button className="btn btn-secondary" disabled>Imported</button>
                ) : importCardLoading ? ( // Check if import is loading
                    <button className="btn btn-primary" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                ) :(
                    <button className="btn btn-primary" onClick={handleImport}>Import</button>
                )}
            </div>
          </div>

        
        </div>
      </div>
    );
}

const ImportModal = () => {
    const [initialLoad, setInitialLoad] = useState(false);
    const [importLoading, setImportLoading] = useState(false);
    const [importTotals, setImportTotals] = useState([]);
    const [importMessage, setImportMessage] = useState({ showMessage: false, messages:[]});
    const [nextPage, setNextPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsToImport, setItemsToImport] = useState([]);
    const [importItemsLoading, setImportItemsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const { user } = useUserAuth();

    const toggleModal = () => {
        setModalShow(!modalShow);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        console.log('change to importLoading - ', importLoading);
    }, [importLoading])

    useEffect(() => {
        const fetchImport = async () => {
            try {
                setImportLoading(true);
                const token = await user.getIdToken(true);
                const importData = await listingAPI.getImportTotal(token);
                console.log('importData - ', importData);
                setImportTotals(importData.result);
            } catch (error) {
                setImportLoading(false);
                console.error("Error Importing", error);
                // setAlert({message: `Error Importing: ${error.message || error}`, type: 'danger'});
            } finally {
                setImportLoading(false);
            }
        }

        if(modalShow && importTotals.length === 0){
            fetchImport();
        }
        
    }, [modalShow]);

    const handleStartImport = async (pageToUse = nextPage) => {
        console.log("Import process started."); 
        setImportItemsLoading(true);
        setInitialLoad(true);

        console.log('pageToUse - ', pageToUse);
        console.log('nextPage - ', nextPage);

        if (isNaN(pageToUse) || pageToUse < 1) {
            console.log('Invalid page provided, defaulting to page 1');
            pageToUse = 1;
        }

        try {

            const token = await user.getIdToken(true); 
            const importData = await listingAPI.readImportEbayActive(token, { page: pageToUse });
            console.log('importData - ', importData);
            if(!importData.error){
                setNextPage(importData.result.nextPage);
                setItemsToImport(importData.result.items);
            }

        } catch (error) {
            console.log('error - ', error);
        } finally {
            setImportItemsLoading(false);
        }
    };

    useEffect(() => {
        if(initialLoad){
            handleStartImport(currentPage)
        }
    }, [currentPage]);


    const marketplaceColors = [
        {name: 'eBay', color: '#2937f0'},
        {name: 'Mercari', color: '#5356ee', comingSoon: true},
        {name: 'Poshmark', color: '#7f0353', comingSoon: true}
    ]

    const addImportMessage = (importData) => {
        const remaining = ((importTotals.active || 0) - importData.itemsReturnedSoFar);
        const newMessage = `${importData.itemsImported} items Imported! ${importData.upsertedCount} new items, ${importData.modifiedCount} items modified, ${importData.notModifiedOrUpserted} were already imported. ${remaining} remaining.`;
        console.log('remaining - ', remaining);

        setImportMessage(prevState => ({
            ...prevState,
            showMessage: true,
            messages: [...prevState.messages, newMessage]
        }));
    }

    return (
      <>
        {/* Button to trigger the modal */}
        <button onClick={toggleModal} className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#importModal">
          Import
        </button>
  
        {/* Modal structure */}
        <div className="modal fade" id="importModal" tabIndex="-1" role="dialog" aria-labelledby="importModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="importModalLabel">Import Listings</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body" style={{minHeight: '80vh'}}>

                

                <div className="row">


                    <div className="col-3">
                        <div className="card bg-primary text-white h-100">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="me-3">
                                        <div className="text-lg fw-bold">eBay</div>
                                        <div className="text-white-75 small">
                                            {importTotals.active !== null && importTotals.active !== undefined && !importLoading ? (
                                                `${importTotals.active} Active Listings`
                                            ) : "Loading"}
                                        </div>

                                        <div className="text-white-75 small">
                                            {importTotals.sold !== null && importTotals.sold !== undefined && !importLoading ? (
                                                `${importTotals.sold} Sold Listings (Last 60 Days)`
                                            ) : "Loading" }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                
                                {!importLoading && (
                                    <button 
                                        className="btn btn-link text-white stretched-link" 
                                        onClick={handleStartImport}
                                        style={{  background: 'none', border: 'none', padding: 0 }}
                                    >
                                        Start Importing
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>


                    {marketplaceColors.map((marketplace, index) => (
                        marketplace.comingSoon ? (
                            <div className="col-3">
                                <ComingSoonCard
                                    key={index} // Add a unique key prop when mapping
                                    marketplaceName={marketplace.name}
                                    color={marketplace.color}
                                />
                            </div>
                        ) : null // Use null instead of <></> to render nothing
                    ))}
                </div>


                {importMessage.showMessage && (
                    <div className="info-section mt-3">
                        <div className="row">
                            <div class="col-sm-12">
                                <div className="alert alert-success" role="alert">
                                <h4 className="alert-heading">Imported!</h4>
                                
                                {importMessage.messages.map((message, index) => (
                                    <div key={index}>
                                    {index !== 0 && <hr />}
                                    <p>{message}</p>
                                    </div>
                                ))}

                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {!importItemsLoading && itemsToImport && itemsToImport.length > 0 && (
                    <>
                    {itemsToImport.map((item, index) => (
                        <ProductCard 
                            key={index} 
                            initialItem={item} 
                        />
                    ))}
                    
                    {itemsToImport.length > 9 && importTotals.active > 10 && (
                        <Pagination 
                        handlePageChange={handlePageChange}
                        currentPage={currentPage}
                        nextPage={nextPage}
                        totalResults={importTotals.active || 0}
                        />
                    )}
                    </>
                )}

                {importItemsLoading && (
                    Array.from({ length: 6 }).map((_, index) => (
                        <ProductRowSkeleton key={index} />
                    ))
                )}


              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default ImportModal;