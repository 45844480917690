import axios from 'axios';

const baseURL = `${process.env.REACT_APP_ENDPOINT}/billing`;


export const createPaymentIntent = async (token, amount) => {
    const response = await axios.post(`${baseURL}/payment-intent`, 
        { amount},  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
};

export const readSubscription = async (token) => {
    const response = await axios.get(`${baseURL}/subscription`, 
        { amount},  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    return response.data;
}
